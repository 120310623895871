<template>
    <div v-if="products.length" class="col-12">
        <h5 class="text-uppercase bg-light p-2">
            Product List
        </h5>

        <div class="d-flex flex-wrap mt-4">
            <div class="form-group col-6">
                <b-form-select
                    v-model="selectedProduct"
                    :options="productOptions"
                />
            </div>

            <div class="form-group col-6 d-flex align-items-stretch">
                <button
                    type="button"
                    class="mx-2 btn btn-sm btn-success waves-effect waves-light add-button"
                    @click="addItem"
                >
                    <i class="mdi mdi-plus" />
                    Add Product
                </button>
            </div>
        </div>

        <div class="table-responsive p-1">
            <table
                id="faq-datatable"
                class="table table-bordered table-striped"
            >
                <thead>
                    <tr class="text-center">
                        <th>#</th>
                        <th>Product</th>
                        <th v-if="fields.text">
                            Text
                        </th>
                        <th v-if="fields.buttonText">
                            Button Text
                        </th>
                        <th v-if="fields.icon">
                            Icon
                        </th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <draggable
                    v-model="listProducts"
                    tag="tbody"
                    ghost-class="ghost"
                    @end="onMoved"
                >
                    <tr
                        v-for="(listProduct, index) in listProducts"
                        :key="index"
                        style="cursor: move"
                    >
                        <td class="text-center align-middle">
                            {{ index + 1 }}
                        </td>
                        <td class="text-center align-middle">
                            {{ listProduct.product.name }}
                        </td>
                        <td v-if="fields.text">
                            <div class="form-group">
                                <textarea
                                    v-model="listProduct.text"
                                    class="form-control"
                                    rows="2"
                                />
                            </div>
                        </td>
                        <td v-if="fields.buttonText">
                            <div class="form-group">
                                <textarea
                                    v-model="listProduct.button_text"
                                    class="form-control"
                                    rows="2"
                                />
                            </div>
                        </td>
                        <td v-if="fields.icon">
                            <div class="form-group">
                                <media-chooser
                                    v-model="listProduct.icon"
                                    @input="onMediaInput(index)"
                                />
                            </div>
                        </td>
                        <td class="text-center align-middle">
                            <button
                                v-if="showSave"
                                class="btn btn-sm btn-success"
                                @click="updateItem(index)"
                            >
                                <i class="fas fa-save mr-1" />
                                Save
                            </button>
                            <button
                                class="btn btn-sm btn-danger ml-1"
                                @click="deleteItem(listProduct.id)"
                            >
                                <i class="far fa-trash-alt" />
                            </button>
                        </td>
                    </tr>
                </draggable>
            </table>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Draggable from 'vuedraggable';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        Draggable,
        MediaChooser
    },

    props: {
        listName: {
            type: String,
            required: true
        },
        fields: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    data() {
        return {
            listProducts: [],
            products: [],
            selectedProduct: {}
        };
    },

    computed: {
        productOptions() {
            return this.products.map(product => {
                return {
                    text: product.name,
                    value: product
                };
            });
        },

        showSave() {
            return this.fields.text || this.fields.buttonText;
        }
    },

    async mounted() {
        await this.fetchListProducts();
        await this.fetchProducts();
    },

    methods: {
        ...mapActions({
            getListProducts: 'listProducts/index',
            createListProduct: 'listProducts/store',
            updateListProduct: 'listProducts/update',
            deleteListProduct: 'listProducts/delete',
            changeListProductsOrder: 'listProducts/changeOrder',
            getProducts: 'products/index'
        }),

        async fetchListProducts() {
            try {
                this.listProducts = await this.getListProducts(this.listName);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async fetchProducts() {
            try {
                const { rows } = await this.getProducts();

                this.products = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async addItem() {
            if (!this.selectedProduct.id) {
                return;
            }

            try {
                const createdListProduct = await this.createListProduct({
                    productId: this.selectedProduct.id,
                    listName: this.listName
                });

                this.selectedProduct = {};

                this.listProducts = [...this.listProducts, createdListProduct];

                this.$toaster('Product has been added!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async updateItem(index) {
            try {
                await this.updateListProduct(this.listProducts[index]);

                this.$toaster('Product has been updated!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async deleteItem(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this product?',
                {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteListProduct(id);

                this.listProducts = this.listProducts.filter(
                    item => item.id !== id
                );

                this.$toaster('Product has been removed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onMoved() {
            const orderedArray = [...this.listProducts].map((item, index) => {
                return { ...item, order: index };
            });

            await this.changeListProductsOrder(orderedArray);

            this.$toaster('Order has been changed!');
        },

        async onMediaInput(index) {
            try {
                await this.updateListProduct(this.listProducts[index]);

                this.$toaster('Icon has been updated!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        }
    }
};
</script>
