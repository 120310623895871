<template>
    <b-modal
        id="add-version-modal"
        title="Add new version"
        centered
        no-close-on-backdrop
    >
        <template slot="modal-title">
            Add new version
        </template>

        <div class="row">
            <div class="form-group col-12">
                <label for="name">
                    Name
                </label>
                <input
                    id="name"
                    v-model="config.name"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.config.name.$error }"
                />
                <span class="invalid-feedback">
                    {{ !$v.config.name.required ? 'Field is required!' : '' }}
                    {{
                        !$v.config.name.isInUse
                            ? 'This name is already in use!'
                            : ''
                    }}
                </span>
            </div>
            <div class="form-group col-12">
                <label for="id">
                    Version id
                </label>
                <input
                    id="id"
                    v-model="config.id"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.config.id.$error }"
                />
                <span class="invalid-feedback">
                    {{ !$v.config.id.required ? 'Field is required!' : '' }}
                    {{
                        !$v.config.id.isInUse
                            ? 'This ID is already in use!'
                            : ''
                    }}
                    {{
                        !$v.config.id.startsWith
                            ? "ID has to start with 'v'!"
                            : ''
                    }}
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <button
                    class="btn"
                    :class="
                        `${
                            !config.copyExisting
                                ? 'btn-primary'
                                : 'btn-secondary'
                        }`
                    "
                    @click="config.copyExisting = false"
                >
                    Create new version
                </button>
                <button
                    class="btn"
                    :class="
                        `${
                            config.copyExisting
                                ? 'btn-primary'
                                : 'btn-secondary'
                        }`
                    "
                    @click="config.copyExisting = true"
                >
                    Copy existing version
                </button>
            </div>
        </div>

        <div v-if="config.copyExisting" class="row mt-2">
            <div class="col-12">
                <v-select
                    v-model="config.existingIndex"
                    :options="versionsCopy"
                    :reduce="option => option.index"
                />
            </div>
        </div>

        <div v-else class="row mt-2">
            <div class="col-12">
                <b-form-checkbox
                    v-model="config.homepageV2"
                    name="enabled"
                    switch
                >
                    Homepage v2
                </b-form-checkbox>
            </div>
            <div v-if="!config.homepageV2" class="col-12">
                <div>
                    <b-form-checkbox
                        v-model="config.twoButtons"
                        name="enabled"
                        switch
                    >
                        Include two buttons
                    </b-form-checkbox>
                </div>
                <div v-if="!config.twoButtons">
                    <b-form-checkbox v-model="config.quiz" name="enabled" switch>
                        Include quiz link
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="config.introducingVideo"
                        name="enabled"
                        switch
                    >
                        Include introducing video
                    </b-form-checkbox>
                </div>
                <div v-else>
                    <b-form-checkbox v-model="config.hero" name="enabled" switch>
                        Include hero information
                    </b-form-checkbox>
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="close"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="save"
                >
                    Save
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import cloneDeep from 'lodash/cloneDeep';

export default {
    validations: {
        config: {
            name: {
                required,
                isInUse(v) {
                    return !this.versions.find(
                        version =>
                            version.config.name.toLowerCase() ===
                            v.toLowerCase()
                    );
                }
            },
            id: {
                required,
                isInUse(v) {
                    return !this.versions.find(
                        version =>
                            version.config.id.toLowerCase() === v.toLowerCase()
                    );
                },
                startsWith: v => !v || v.startsWith('v')
            }
        }
    },

    props: {
        versions: {
            type: Array,
            required: true,
            default: () => []
        }
    },

    data() {
        const defaultConfig = {
            name: '',
            id: 'v',
            twoButtons: false,
            hero: false,
            quiz: false,
            copyExisting: false,
            existingIndex: 0
        };

        return {
            config: { ...defaultConfig },
            defaultConfig,
            versionsCopy: []
        };
    },

    watch: {
        versions: {
            handler(v) {
                this.versionsCopy = v.map((version, index) => ({
                    index,
                    label: version.config.name
                }));
            },
            immediate: true
        }
    },

    methods: {
        close() {
            this.$bvModal.hide('add-version-modal');
            this.config = { ...this.defaultConfig };
            this.$v.$reset();
        },

        save() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            const { copyExisting, existingIndex, ...config } = this.config;
            let version = {};

            if (!copyExisting) {
                version = {
                    config,
                    topImage: {},
                    topImageMobile: {}
                };

                if (config.homepageV2) {
                    version.homepageV2 = {
                        products: []
                    };
                    version.homepageV2Mobile = {
                        products: []
                    };
                }

                if (config.twoButtons) {
                    const side = {
                        backgroundImage: {},
                        mobileImage: {},
                        text: ''
                    };

                    version.leftSide = { ...side };
                    version.rightSide = { ...side };

                    if (config.hero) {
                        const hero = {
                            enabled: false,
                            description: '',
                            name: ''
                        };

                        version.leftSide.hero = { ...hero };
                        version.rightSide.hero = { ...hero };
                    }
                } else {
                    version.textSection = '';
                    version.title = '';

                    if (config.quiz) {
                        version.quizText = '';
                    }

                    if (config.introducingVideo) {
                        version.introducingVideo = {
                            enabled: false,
                            link: '',
                            duration: ''
                        };
                    }
                }
            } else {
                version = cloneDeep(this.versions[existingIndex]);
                version.config.name = config.name;
                version.config.id = config.id;
                version.config.default = false;
            }

            this.$emit('save', version);
            this.close();
        }
    }
};
</script>
