<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                General
            </h5>
            <media-chooser
                v-model="content.value.image"
                label="Image"
                class="col-12"
            />
            <div class="form-group col-12">
                <label for="contentTitle">Title</label>
                <textarea
                    id="contentTitle"
                    v-model="content.value.title"
                    :class="{ 'is-invalid': $v.content.value.title.$error }"
                    class="form-control"
                    rows="3"
                />
            </div>
            <div class="form-group col-12">
                <label>Description</label>
                <wysiwyg-editor v-model="content.value.description" />
            </div>
            <div class="form-group col-12 mt-2">
                <b-form-checkbox
                    v-model="content.value.isQuantityOptionEnabled"
                    switch
                >
                    Enable Gift Card Quantity Options
                </b-form-checkbox>
            </div>
            <div
                v-if="content.value.isQuantityOptionEnabled"
                class="form-group col-12"
            >
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Quantity Options
                </h5>
                <div v-if="discountBlocks.length" class="card">
                    <div class="card-body">
                        <div
                            v-for="(block, index) in discountBlocks"
                            :key="index"
                            class="row mt-1"
                        >
                            <div class="col">
                                <label for="quantity">Quantity</label>
                                <input
                                    id="quantity"
                                    v-model="block.quantity"
                                    class="form-control col-12"
                                    placeholder="Product quantity"
                                    type="number"
                                />
                            </div>
                            <div class="col">
                                <label for="price">Price</label>
                                <input
                                    id="price"
                                    v-model="block.price"
                                    class="form-control col-12"
                                    placeholder="Price"
                                    type="number"
                                />
                            </div>
                            <div class="col">
                                <label for="sale_price">Sale price</label>
                                <input
                                    id="sale_price"
                                    v-model="block.salePrice"
                                    class="form-control col-12"
                                    placeholder="Sale price"
                                    type="number"
                                />
                            </div>
                            <div class="col">
                                <label for="sale_price">Delete</label>
                                <div>
                                    <a
                                        class="btn btn-danger"
                                        style="color: white;"
                                        @click="removeBlock(index)"
                                    >
                                        -
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="btn btn-info" style="color: white;" @click="addBlock">
                    +
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ContentMixin from './content.mixin.js';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        WysiwygEditor,
        MediaChooser
    },

    mixins: [ContentMixin],

    validations: {
        content: {
            value: {
                title: {
                    required
                },
                description: {
                    required
                }
            }
        }
    },

    data() {
        return {
            discountBlocks: []
        };
    },

    watch: {
        discountBlocks(val) {
            this.content.value.discountBlocks = [...val];
        },

        content: {
            handler() {
                this.discountBlocks = [...this.content.value.discountBlocks];
            }
        }
    },

    created() {
        this.contentKey = 'gift-card';
        this.contentName = 'Gift Card';
    },

    methods: {
        addBlock() {
            this.discountBlocks.push({
                quantity: null,
                price: null,
                salePrice: null
            });
        },

        removeBlock(index) {
            this.discountBlocks.splice(index, 1);
        }
    }
};
</script>
