<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Login Access Type
            </h5>
            <div class="col-12 ml-1 mt-1 mb-2">
                <div
                    v-for="(accessType, index) in accessTypeOptions"
                    :key="index"
                    class="radio radio-primary"
                >
                    <input
                        :id="accessType"
                        v-model="content.value.accessType"
                        type="radio"
                        name="customRadio"
                        :value="accessType"
                    />
                    <label :for="accessType">
                        {{ accessType | startCase }}
                    </label>
                </div>
            </div>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Phone
            </h5>
            <div class="form-group col-12">
                <label for="contentPhoneTitle">Title</label>
                <textarea
                    id="contentPhoneTitle"
                    v-model="content.value.phone.title"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="contentPhoneSubitle">Subitle</label>
                <textarea
                    id="contentPhoneSubitle"
                    v-model="content.value.phone.subtitle"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="col-12">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Title' and 'Subtitle' you can use variables. There are predefined variables for first name -
                    <b>{Name.firstName}</b> and last name - <b>{Name.lastName}</b>.
                </div>
            </div>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Email
            </h5>
            <div class="form-group col-12">
                <label for="contentEmailTitle">Title</label>
                <textarea
                    id="contentEmailTitle"
                    v-model="content.value.email.title"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="contentEmailSubitle">Subitle</label>
                <textarea
                    id="contentEmailSubitle"
                    v-model="content.value.email.subtitle"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="col-12">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Title' and 'Subtitle' you can use variables. There are predefined variables for first name -
                    <b>{Name.firstName}</b> and last name - <b>{Name.lastName}</b>.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContentMixin from '../content.mixin.js';

export default {
    mixins: [ContentMixin],

    data() {
        return {
            accessTypeOptions: [
                'phone',
                'email-magic-link',
                'email-otp'
            ]
        }
    },

    created() {
        this.contentKey = 'mobile-3d-login';
        this.contentName = 'Mobile 3D Login';
    }
};
</script>
