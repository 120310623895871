<template>
    <div class="col-12">
        <were-changes-alert
            :were-changes="wereChanges"
            @save="onCategoryUpdate"
        />
        <div class="d-flex flex-wrap">
            <div class="form-group col-12 px-0">
                <label>
                    Name *
                </label>
                <input
                    v-model="category.name"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.category.name.$error }"
                />
            </div>

            <h5 class="col-12 text-uppercase bg-light p-2 mb-0">
                Subcategories
            </h5>

            <div class="form-group col-6 pl-0">
                <label class="mt-3 ml-0">
                    Name *
                </label>
                <input
                    v-model="subcategory.name"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.subcategory.name.$error }"
                    @blur="onNameChange"
                />
            </div>
            <div class="form-group col-6 pl-0">
                <label class="mt-3 ml-0">
                    Identifier *
                </label>
                <input
                    v-model="identifier"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.identifier.$error }"
                />
            </div>
            <div class="form-group col-12 pl-0">
                <label class="mt-0 ml-0">
                    Description *
                </label>
                <textarea
                    v-model="subcategory.description"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.subcategory.description.$error }"
                />
            </div>
            <div class="form-group col-12 pl-0">
                <b-form-checkbox v-model="subcategory.is_default" switch>
                    Is Default
                </b-form-checkbox>
            </div>
            <div class="form-group col-6 d-flex align-items-stretch pl-0">
                <button
                    type="button"
                    class="mx-0 btn btn-sm btn-success waves-effect waves-light"
                    @click="onSubcategoryAdd"
                >
                    <i class="mdi mdi-plus" />
                    Add Subcategory
                </button>
            </div>
        </div>

        <draggable-list
            v-if="category.subcategories"
            :key="category.subcategories.length"
            :items="category.subcategories"
            is-editable
            @edit-element="openSubcategory"
            @element-deleted="onSubcategoryDelete"
            @element-moved="onSubcategoryMove"
        >
            <span slot-scope="row">
                <span>
                    {{ row.element.name }}
                </span>
            </span>
            <template #additionalActions="row">
                <a class="action-icon">
                    <i
                        class="fas fa-thumbtack"
                        :class="{ 'text-success': row.element.is_default }"
                        @click.prevent="toggleDefault(row.element)"
                    />
                </a>
            </template>
        </draggable-list>

        <div
            class="form-group mt-2 d-flex align-items-stretch pl-0 float-right"
        >
            <button
                type="button"
                class="mx-0 btn btn-sm btn-danger waves-effect waves-light"
                @click="back"
            >
                Back to categories
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import DraggableList from '@components/elements/DraggableList';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    components: {
        DraggableList,
        WereChangesAlert
    },

    props: {
        categoryId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            category: {},
            subcategory: {
                is_default: false
            },
            identifier: '',
            initialCategoryName: ''
        };
    },

    computed: {
        wereChanges() {
            return this.category.name !== this.initialCategoryName;
        }
    },

    validations: {
        identifier: {
            required
        },
        subcategory: {
            name: {
                required
            },
            description: {
                required
            }
        },
        category: {
            name: {
                required
            }
        }
    },

    mounted() {
        this.fetchCategory();
    },

    methods: {
        ...mapActions({
            getCategory: 'exerciseCategories/show',
            updateCategory: 'exerciseCategories/update',
            createSubcategory: 'exerciseSubcategories/store',
            updateSubcategory: 'exerciseSubcategories/update',
            deleteSubcategory: 'exerciseSubcategories/delete',
            changeSubcategoriesOrder: 'exerciseSubcategories/changeOrder'
        }),

        async fetchCategory() {
            try {
                this.category = await this.getCategory(this.categoryId);

                this.initialCategoryName = this.category.name;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onSubcategoryAdd() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                const createdCategory = await this.createSubcategory({
                    categoryId: this.categoryId,
                    identifier: this.identifier,
                    ...this.subcategory
                });

                this.subcategory = {};
                this.identifier = '';

                this.$v.$reset();

                this.category.subcategories = [
                    ...this.category.subcategories,
                    createdCategory
                ];

                this.$toaster('Subcategory has been added!');
            } catch (err) {
                console.error(err);

                let message = null;

                if (err.response.status === 422) {
                    message = err.response.data.error;
                }

                this.$toasterError(message);
            }
        },

        async onSubcategoryDelete(id) {
            try {
                await this.deleteSubcategory(id);

                this.category.subcategories = this.category.subcategories.filter(
                    item => item.id !== id
                );

                this.$toaster('Subcategory has been removed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onSubcategoryMove({ orderedList }) {
            try {
                const orderedArray = orderedList.map((item, index) => {
                    return { ...item, order: index };
                });

                await this.changeSubcategoriesOrder(orderedArray);

                this.$toaster('Order has been changed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onCategoryUpdate() {
            try {
                this.$v.category.$touch();

                if (this.$v.category.name.$invalid) {
                    return;
                }

                const updatedCategory = await this.updateCategory({
                    id: this.categoryId,
                    ...this.category
                });

                this.initialCategoryName = updatedCategory.name;

                this.$toaster('Category name has been changed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async toggleDefault(subcategory) {
            const message = subcategory.is_default
                ? 'Are you sure you want to remove this element from defaults?'
                : 'Are you sure you want to set this element as default?';

            const confirmed = await this.$confirmationModal(message);

            if (!confirmed) {
                return;
            }

            await this.updateSubcategory({
                ...subcategory,
                is_default: !subcategory.is_default
            });

            const toasterText = subcategory.is_default
                ? 'Subcategory has been removed from defaults!'
                : 'Subcategory has been added to defaults!';

            this.$toaster(toasterText);
        },

        onNameChange() {
            this.identifier = this.subcategory.name
                .toLowerCase()
                .split(' ')
                .join('_');
        },

        openSubcategory(id) {
            this.$router.push({
                path: this.$route.path,
                query: { categoryId: this.categoryId, subcategoryId: id }
            });
        },

        back() {
            this.$router.push({
                path: this.$route.path,
                query: { categories: true }
            });
        }
    }
};
</script>
