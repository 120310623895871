<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Shipper Address
            </h5>
            <div class="form-group col-12">
                <label>
                    Street Lines
                </label>
                <input
                    v-model="content.value.shipperAddress.streetLines[0]"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.content.value.shipperAddress.streetLines.$each[0].$error }"
                />
                <input
                    v-model="content.value.shipperAddress.streetLines[1]"
                    type="text"
                    class="form-control mt-2"
                    :class="{ 'is-invalid': $v.content.value.shipperAddress.streetLines.$each[1].$error }"
                />
                <input
                    v-model="content.value.shipperAddress.streetLines[2]"
                    type="text"
                    class="form-control mt-2"
                    :class="{ 'is-invalid': $v.content.value.shipperAddress.streetLines.$each[2].$error }"
                />
                <span v-if="$v.content.value.shipperAddress.streetLines.$each.$error" class="text-danger">
                    Maximum length is 35
                </span>
            </div>
            <div class="form-group col-12">
                <label>
                    City
                </label>
                <input
                    v-model="content.value.shipperAddress.city"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.content.value.shipperAddress.city.$error }"
                />
                <span v-if="$v.content.value.shipperAddress.city.$error" class="text-danger">
                    Maximum length is 35
                </span>
            </div>
            <div class="form-group col-12">
                <label>
                    Postal Code
                </label>
                <input
                    v-model="content.value.shipperAddress.postalCode"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.content.value.shipperAddress.postalCode.$error }"
                />
                <span v-if="$v.content.value.shipperAddress.postalCode.$error" class="text-danger">
                    Maximum length is 10
                </span>
            </div>
            <div class="form-group col-12">
                <label>
                    Country Code
                </label>
                <input
                    v-model="content.value.shipperAddress.countryCode"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.content.value.shipperAddress.countryCode.$error }"
                />
                <span v-if="$v.content.value.shipperAddress.countryCode.$error" class="text-danger">
                    Maximum length is 2
                </span>
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Shipper Contact
            </h5>
            <div class="form-group col-12">
                <label>
                    Company Name
                </label>
                <input
                    v-model="content.value.shipperContact.companyName"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Phone Number
                </label>
                <input
                    v-model="content.value.shipperContact.phoneNumber"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Email Address
                </label>
                <input
                    v-model="content.value.shipperContact.emailAddress"
                    type="text"
                    class="form-control"
                />
            </div>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                General
            </h5>
            <div class="form-group col-12">
                <label for="contentTitle">Special Instructions</label>
                <textarea
                    id="contentTitle"
                    v-model="content.value.specialInstructions"
                    class="form-control"
                    :class="{ 'is-invalid': $v.content.value.specialInstructions.$error }"
                    rows="3"
                />
                <span v-if="$v.content.value.specialInstructions.$error" class="text-danger">
                    Maximum length is 300
                </span>
            </div>
            <div class="form-group col-12">
                <label for="contentTitle">Declaration Statement</label>
                <textarea
                    id="contentTitle"
                    v-model="content.value.declarationStatement"
                    class="form-control"
                    rows="3"
                />
            </div>
            <div class="form-group col-12">
                <label for="contentTitle">Declaration Statement (Creams)</label>
                <textarea
                    id="contentTitle"
                    v-model="content.value.declarationStatementCreams"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Orthotics Name
                </label>
                <input
                    v-model="content.value.orthoticsName"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Orthotics Harmonized Code
                </label>
                <input
                    v-model="content.value.orthoticsHarmonizedCode"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Creams Harmonized Code
                </label>
                <input
                    v-model="content.value.creamsHarmonizedCode"
                    type="text"
                    class="form-control"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators';
import ContentMixin from '../content.mixin.js';

export default {
    mixins: [ContentMixin],

    validations: {
        content: {
            value: {
                shipperAddress: {
                    streetLines: {
                        $each: {
                            maxLength: maxLength(35)
                        }
                    },
                    city: {
                        maxLength: maxLength(35)
                    },
                    postalCode: {
                        maxLength: maxLength(10)
                    },
                    countryCode: {
                        maxLength: maxLength(2)
                    }
                },
                specialInstructions: {
                    maxLength: maxLength(300)
                }
            }
        }
    },

    created() {
        this.contentKey = 'fedex';
        this.contentName = 'Fedex';
    }
};
</script>
