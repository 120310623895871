<template>
    <div class="row">
        <ul class="nav nav-pills navtab-bg nav-justified col-12 mt-2">
            <li v-for="(tab, index) in tabs" :key="index" class="nav-item mb-2">
                <a
                    href="#"
                    data-toggle="tab"
                    aria-expanded="false"
                    class="nav-link"
                    :class="{
                        active: activeTab === tab.value
                    }"
                    @click.prevent="activeTab = tab.value"
                >
                    {{ tab.text }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { url as appUrl } from '@/config';

export default {
    props: {
        value: {
            type: String,
            required: true
        },
        tabs: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            activeTab: ''
        };
    },

    computed: {
        url() {
            let url = `${appUrl}/${this.$route.path.slice(1)}?`;

            if (this.activeTab) {
                url += `&tab=${this.activeTab}`;
            }

            return encodeURI(url);
        }
    },

    watch: {
        url: {
            handler() {
                history.replaceState({}, '', this.url);

                this.$emit('input', this.activeTab);
            },
            immediate: true
        }
    },

    created() {
        const { tab } = this.$route.query;

        if (tab) {
            this.activeTab = tab;
        } else {
            this.activeTab = this.value;
        }
    }
};
</script>
