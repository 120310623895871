<template>
    <b-alert
        :key="dialog"
        v-model="dialog"
        class="position-fixed fixed-top m-0 rounded-0 d-flex justify-content-between align-items-center"
        style="z-index: 2; top:70px"
        variant="warning"
        dismissible
    >
        There are unsaved changes!
        <button
            type="button"
            class="btn btn-warning waves-effect waves-light"
            @click="$emit('save')"
        >
            <i class="fas fa-save" />
            Save
        </button>
    </b-alert>
</template>

<script>
export default {
    props: {
        wereChanges: {
            type: Boolean,
            required: true
        }
    },

    data: () => ({
        dialog: false
    }),

    watch: {
        wereChanges: function() {
            this.dialog = this.wereChanges;
        }
    }
};
</script>
