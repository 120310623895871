<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="d-flex flex-wrap">
            <div class="form-group col-12">
                <label for="text">Text</label>
                <textarea
                    id="text"
                    v-model="content.value.text"
                    class="form-control"
                    rows="3"
                />
            </div>
        </div>
    </form>
</template>

<script>
import ContentMixin from './content.mixin.js';

export default {
    mixins: [ContentMixin],

    created() {
        this.contentKey = 'bottom-home-page-text';
        this.contentName = 'Bottom Scroll Text';
    }
};
</script>
