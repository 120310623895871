<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div
            v-if="activeTab === 'default'"
            :key="activeTab"
            class="row"
        >
            <media-chooser
                v-model="content.value.backgroundImage"
                label="Background image"
                class="col-12"
            />
            <div class="form-group col-12">
                <wysiwyg-editor
                    v-model="content.value.title"
                    label="Title"
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Steps
            </h5>
            <div class="form-group col-12">
                <how-it-works-steps
                    v-model="content.value.steps"
                    :steps="content.value.steps"
                    is-title
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Watch Video Button
            </h5>
            <div class="form-group col-12 col-md-6">
                <label for="watchVideoText">Button Text</label>
                <input
                    id="watchVideoText"
                    v-model="content.value.watchVideo.text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="watchVideoLink">Button Link</label>
                <input
                    id="watchVideoLink"
                    v-model="content.value.watchVideo.link"
                    class="form-control"
                />
            </div>
        </div>

        <div
            v-if="activeTab === 'homepage-v2'"
            :key="activeTab"
            class="row"
        >
            <div class="form-group col-12">
                <label>Background Color</label>
                <color-picker
                    v-model="content.value.backgroundColor"
                    :color="content.value.backgroundColor"
                />
            </div>
            <div class="form-group col-12">
                <wysiwyg-editor
                    v-model="content.value.homepageV2Title"
                    label="Title"
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Steps
            </h5>
            <div class="form-group col-12">
                <how-it-works-steps
                    v-model="content.value.homepageV2Steps"
                    :steps="content.value.homepageV2Steps"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ContentTabs from '@components/contents/ContentTabs';
import MediaChooser from '@components/media/MediaChooser';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import ColorPicker from '@components/elements/ColorPicker';
import ContentMixin from './content.mixin.js';
import howItWorksSteps from './how-it-works/HowItWorksSteps';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    components: {
        ContentTabs,
        MediaChooser,
        WysiwygEditor,
        ColorPicker,
        howItWorksSteps,
        WereChangesAlert
    },

    mixins: [ContentMixin],

    data() {
        return {
            tabs: [
                {
                    value: 'default',
                    text: 'Homepage V1'
                },
                {
                    value: 'homepage-v2',
                    text: 'Homepage V2'
                }
            ],
            activeTab: 'default'
        };
    },

    created() {
        this.contentKey = 'homepage-how-it-works';
        this.contentName = 'How It Works';
    }
};
</script>
