<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <nested-draggable
                :key="dragKey"
                :items="content.value.items"
                sub-items-property="subItems"
                :index="0"
                :level="1"
                :max-level="1"
                @add="onAdd('items')"
                @remove="onRemove"
                @edit="onEdit"
            />
        </div>
        <edit-modal
            v-if="itemToEdit"
            :value="itemToEdit"
            @save-menu-item="onEditMenuItem"
        />
    </form>
</template>

<script>
import ContentMixin from '@views/contents/content.mixin.js';
import MenusMixin from './menus.mixin';
import NestedDraggable from '@components/elements/NestedDraggable';
import EditModal from './EditModal';

export default {
    components: {
        NestedDraggable,
        EditModal
    },

    mixins: [ContentMixin, MenusMixin],

    data() {
        return {
            item: {
                title: '',
                link: '',
                submenuEnabled: false,
                submenu: {
                    columns: []
                }
            }
        };
    },

    created() {
        this.contentKey = 'menu-top';
        this.contentName = 'Menu Top';
    }
};
</script>
