<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div class="form-group col-6">
                <label for="title">Title</label>
                <input
                    id="title"
                    v-model="content.value.title"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-6">
                <label for="postPurchaseTitle">Post purchase title</label>
                <input
                    id="postPurchaseTitle"
                    v-model="content.value.postPurchaseTitle"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-6">
                <label for="buttonText">Button text</label>
                <input
                    id="buttonText"
                    v-model="content.value.buttonText"
                    class="form-control"
                    type="text"
                />
            </div>
        </div>
        <div v-for="option in options" :key="option" class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                {{ getTitle(option) }}
            </h5>
            <media-chooser
                v-model="content.value[`${option}Image`]"
                add-buff
                label="Icon"
                class="col-12"
            />
        </div>
    </form>
</template>

<script>
import startCase from 'lodash/startCase';
import ContentMixin from './content.mixin.js';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        MediaChooser
    },

    mixins: [ContentMixin],

    data() {
        return {
            options: ['forMe', 'men', 'women', 'new']
        };
    },

    created() {
        this.contentKey = 'accounts-page';
        this.contentName = 'Accounts page';
    },

    methods: {
        getTitle(option) {
            return startCase(option);
        }
    }
};
</script>
