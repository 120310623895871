<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>
        <div class="row">
            <div class="form-group col-12">
                <b-form-checkbox v-model="content.value.enabled" switch>
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <label for="section1-text">Title</label>
                <textarea
                    id="section1-text"
                    v-model="content.value.title"
                    class="form-control"
                    rows="3"
                />
            </div>
        </div>
        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Images
            </h5>
            <media-chooser
                v-model="content.value.leftSideImage"
                add-buff
                class="col-12"
                label="Left side image"
            />
            <media-chooser
                v-model="content.value.rightSideImage"
                add-buff
                class="col-12"
                label="Right side image"
            />
        </div>
    </div>
</template>

<script>
import ContentMixin from './content.mixin.js';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        MediaChooser
    },
    mixins: [ContentMixin],

    created() {
        this.contentKey = 'process-upstep';
        this.contentName = 'Process Upstep';
    }
};
</script>
