<template>
    <div :class="{ 'single-level': level <= maxLevel }" class="row">
        <div v-if="level <= maxLevel" class="header">
            <div>
                <button
                    type="button"
                    class="btn btn-success waves-effect waves-light mr-1"
                    @click="addItem"
                >
                    <i class="mdi mdi-plus-circle" />
                </button>
            </div>
        </div>

        <draggable
            tag="div"
            :list="items"
            :group="{ name: 'g1' }"
            handle=".handle"
        >
            <div v-for="(item, i) in items" :key="i" class="list">
                <div class="item-info">
                    <div class="info">
                        {{ item.text }}
                    </div>

                    <div class="buttons">
                        <button
                            type="button"
                            class="btn btn-primary waves-effect waves-light mr-2"
                            @click="editItem(i)"
                        >
                            <i class="fas fa-edit" />
                        </button>
                        <button
                            type="button"
                            class="handle btn btn-info waves-effect waves-light mr-2"
                            style="cursor: move"
                        >
                            <i class="fas fa-arrows-alt" />
                        </button>
                        <button
                            type="button"
                            class="handle btn btn-danger waves-effect waves-light mr-1"
                            @click="removeItem(i)"
                        >
                            <i class="far fa-trash-alt" />
                        </button>
                    </div>
                </div>

                <nested-draggable
                    :items="item[subItemsProperty]"
                    :sub-items-property="subItemsProperty"
                    :index="i"
                    :level="level + 1"
                    :max-level="maxLevel"
                    @add="onAdd"
                    @remove="onRemove"
                    @edit="onEdit"
                />
            </div>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
    name: 'NestedDraggable',

    components: {
        draggable
    },

    props: {
        items: {
            required: false,
            type: Array,
            default: () => []
        },
        subItemsProperty: {
            type: String,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        level: {
            type: Number,
            required: true
        },
        maxLevel: {
            type: Number,
            required: false,
            default: null
        }
    },

    methods: {
        addItem() {
            this.$emit('add', [this.index]);
        },

        onAdd(indexes) {
            this.$emit('add', [this.index, ...indexes]);
        },

        removeItem(index) {
            this.$emit('remove', [this.index, index]);
        },

        onRemove(indexes) {
            this.$emit('remove', [this.index, ...indexes]);
        },

        editItem(index) {
            this.$emit('edit', [this.index, index]);
        },

        onEdit(indexes) {
            this.$emit('edit', [this.index, ...indexes]);
        }
    }
};
</script>

<style lang="scss">
.single-level {
    border-left: 1px dashed lightgray;
    margin-top: 10px;
    padding: 10px;

    .header {
        margin-top: 15px;
        margin-left: 20px;
    }

    .list {
        margin-left: 20px;
        margin-top: 10px;
        padding: 5px;

        .item-info {
            background-color: #f1f5f7;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px;
            width: 570px;
            border-radius: 20px;

            .info {
                margin-left: 10px;
                font-size: 15px;
            }

            .buttons {
                width: 170px;
            }
        }
    }

    &:first-child {
        border-left: none;
    }
}
</style>
