<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div class="form-group col-12">
                <label for="contentTitle">Title</label>
                <textarea
                    id="contentTitle"
                    v-model="content.value.title"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label>Text</label>
                <wysiwyg-editor v-model="content.value.text" />
            </div>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Info Block
            </h5>
            <media-chooser
                v-model="content.value.infoIcon"
                label="Image"
                class="col-12"
            />
            <div class="form-group col-12">
                <label for="contentTitle">Title</label>
                <wysiwyg-editor v-model="content.value.infoTitle" />
            </div>
            <div class="form-group col-12">
                <label>Text</label>
                <wysiwyg-editor v-model="content.value.infoText" />
            </div>
        </div>
    </div>
</template>

<script>
import ContentMixin from './content.mixin.js';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        WysiwygEditor,
        MediaChooser
    },

    mixins: [ContentMixin],

    created() {
        this.contentKey = 'guarantee';
        this.contentName = 'Guarantee';
    }
};
</script>
