<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div class="form-group col-12">
                <label for="contentTitle">Title</label>
                <textarea
                    id="contentTitle"
                    v-model="content.value.title"
                    :class="{ 'is-invalid': $v.content.value.title.$error }"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="contentSubtitle">Subtitle</label>
                <textarea
                    id="contentSubtitle"
                    v-model="content.value.subtitle"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="col-12">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Title' and 'Subtitle' you can use variables. There are predefined variables for first name -
                    <b>{Name.firstName}</b> and last name - <b>{Name.lastName}</b>.
                </div>
            </div>
            <media-chooser
                v-model="content.value.image"
                label="Image"
                class="col-12"
            />
        </div>

        <div class="row">
            <div class="form-group col-6">
                <label>
                    Button Text
                </label>
                <input
                    v-model="content.value.button.text"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-6">
                <label>
                    Button Link
                </label>
                <input
                    v-model="content.value.button.link"
                    type="text"
                    class="form-control"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ContentMixin from '../content.mixin.js';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        MediaChooser
    },

    mixins: [ContentMixin],

    validations: {
        content: {
            value: {
                title: {
                    required
                }
            }
        }
    },

    created() {
        this.contentKey = 'mobile-3d-success';
        this.contentName = 'Mobile 3D Success';
    }
};
</script>
