export default {
    data() {
        return {
            itemToEdit: null,
            indexToEdit: null,
            dragKey: 0
        };
    },

    methods: {
        onAdd(property, value) {
            this.content.value[property] = [...this.content.value[property], value || this.item];

            this.indexToEdit = this.content.value[property].length - 1;

            this.itemToEdit = this.content.value[property][this.indexToEdit];

            this.showEditModal();
        },

        onRemove(indexes, property = 'items') {
            const indexToRemove = indexes.pop();

            this.content.value[property].splice(indexToRemove, 1);
        },

        onEdit(indexes, property = 'items') {
            this.indexToEdit = indexes.pop();

            this.itemToEdit = this.content.value[property][this.indexToEdit];

            this.showEditModal();
        },

        onEditMenuItem(item, property = 'items') {
            this.content.value[property][this.indexToEdit] = item;

            this.indexToEdit = null;
            this.itemToEdit = null;
            this.dragKey += 1;
        },

        async showEditModal() {
            await this.$nextTick();

            this.$bvModal.show('editMenuModal');
        }
    }
};
