<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
            <a class="btn btn-info ml-2" :href="url" target="_blank">
                <i class="far fa-eye mr-1" />
                Preview
            </a>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div v-show="activeTab === 'default'">
            <div class="row">
                <div class="form-group col-12 mt-2">
                    <b-form-checkbox v-model="content.value.enabled" switch>
                        Enabled
                    </b-form-checkbox>
                </div>
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Style
                </h5>
                <div class="form-group col-4">
                    <div style="display:flex; justify-content: space-between">
                        <label>
                            Background Color
                        </label>
                        <b-form-checkbox v-model="content.value.backgroundColorGradientEnabled" switch>
                            Gradient
                        </b-form-checkbox>
                    </div>
                    <color-picker
                        v-if="!content.value.backgroundColorGradientEnabled"
                        v-model="content.value.backgroundColor"
                        :color="content.value.backgroundColor"
                    />
                    <gradient-picker
                        v-else
                        v-model="content.value.backgroundColorGradient"
                    />
                </div>
                <div class="form-group col-4">
                    <label>Number Background Color</label>
                    <color-picker
                        v-model="content.value.numberBackgroundColor"
                        :color="content.value.numberBackgroundColor"
                    />
                </div>
                <div class="form-group col-4">
                    <label>Text Color</label>
                    <color-picker
                        v-model="content.value.textColor"
                        :color="content.value.textColor"
                    />
                </div>
            </div>

            <div class="row">
                <div class="form-group col-4">
                    <label>
                        Font
                    </label>
                    <input
                        v-model="content.value.font"
                        type="text"
                        class="form-control"
                    />
                </div>
            </div>

            <div class="row">
                <div class="form-group col-4">
                    <label>
                        Timer Font Size
                    </label>
                    <input
                        v-model="content.value.timerFontSize"
                        type="text"
                        class="form-control"
                    />
                </div>
                <div class="form-group col-4">
                    <label>
                        Timer Font Size (Mobile)
                    </label>
                    <input
                        v-model="content.value.mobileTimerFontSize"
                        type="text"
                        class="form-control"
                    />
                </div>
            </div>

            <div class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Desktop Text
                </h5>
                <div class="col-12 mb-3">
                    <wysiwyg-editor
                        id="text"
                        v-model="content.value.text"
                    />
                </div>
            </div>

            <div class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Mobile Text
                </h5>
                <div class="col-12 mb-3">
                    <wysiwyg-editor
                        id="text"
                        v-model="content.value.mobileText"
                    />
                </div>
            </div>

            <div class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Time
                </h5>
                <div class="form-group col-4">
                    <label>
                        End Time
                    </label>
                    <date-picker
                        v-model="date"
                        type="datetime"
                        value-type="format"
                        format="YYYY-MM-DD HH:mm"
                        :lang="{
                            formatLocale: {
                                firstDayOfWeek: 1
                            }
                        }"
                        placeholder="Select date"
                        class="form-control"
                        @change="onDateChange"
                    />
                </div>
                <div class="form-group col-4">
                    <label>
                        Timezone
                    </label>
                    <b-form-select
                        v-model="content.value.timezone"
                        :options="options.timezone"
                        @change="onTimezoneChange"
                    />
                </div>
            </div>

            <b>Time Units</b>
            <div class="form-group col-12 mt-2">
                <b-form-checkbox v-model="content.value.timeUnits.days" switch>
                    Days
                </b-form-checkbox>
            </div>
            <div class="form-group col-12 mt-2">
                <b-form-checkbox v-model="content.value.timeUnits.hours" switch>
                    Hours
                </b-form-checkbox>
            </div>
            <div class="form-group col-12 mt-2">
                <b-form-checkbox v-model="content.value.timeUnits.minutes" switch>
                    Minutes
                </b-form-checkbox>
            </div>
            <div class="form-group col-12 mt-2">
                <b-form-checkbox v-model="content.value.timeUnits.seconds" switch>
                    Seconds
                </b-form-checkbox>
            </div>

            <div class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Loop
                </h5>
                <div class="form-group col-12 mt-2">
                    <b-form-checkbox v-model="content.value.loop" switch>
                        Enabled
                    </b-form-checkbox>
                </div>

                <div class="form-group col-4">
                    <label>
                        Loop Time (Hours)
                    </label>
                    <input
                        v-model.number="content.value.loopHours"
                        type="text"
                        class="form-control"
                    />
                </div>
            </div>

            <pages-selector v-model="content.value.pages" :pages-keys="pagesKeys" />
        </div>

        <div v-show="activeTab === 'homepage-v2'">
            <div class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Mobile Banner Text
                </h5>
                <div class="col-12 mb-3">
                    <wysiwyg-editor
                        id="text"
                        v-model="content.value.homepageV2TextMobile"
                    />
                </div>
            </div>
            <div class="row">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Style
                </h5>
                <div class="form-group col-4">
                    <label>Text Background Color</label>
                    <color-picker
                        v-model="content.value.homepageV2TextBackgroundColor"
                        :color="content.value.homepageV2TextBackgroundColor"
                    />
                </div>
                <div class="form-group col-4">
                    <label>Text Color</label>
                    <color-picker
                        v-model="content.value.homepageV2TextColor"
                        :color="content.value.homepageV2TextColor"
                    />
                </div>
            </div>
            <div class="row">
                <div class="form-group col-4">
                    <label>Text Background Color (Mobile)</label>
                    <color-picker
                        v-model="content.value.homepageV2TextBackgroundColorMobile"
                        :color="content.value.homepageV2TextBackgroundColorMobile"
                    />
                </div>
                <div class="form-group col-4">
                    <label>Text Color (Mobile)</label>
                    <color-picker
                        v-model="content.value.homepageV2TextColorMobile"
                        :color="content.value.homepageV2TextColorMobile"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import config from '@config';
import options from '@components/settings/options.js';
import ContentTabs from '@components/contents/ContentTabs';
import ColorPicker from '@components/elements/ColorPicker';
import GradientPicker from '@components/elements/GradientPicker';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import PagesSelector from '@components/contents/PagesSelector';
import ContentMixin from './content.mixin.js';

export default {
    components: {
        DatePicker,
        ContentTabs,
        ColorPicker,
        GradientPicker,
        WysiwygEditor,
        PagesSelector
    },

    mixins: [ContentMixin],

    data() {
        return {
            tabs: [
                {
                    value: 'default',
                    text: 'Default'
                },
                {
                    value: 'homepage-v2',
                    text: 'Homepage V2'
                }
            ],
            activeTab: 'default',
            date: null,
            url: config.frontendUrl,
            options,
            pagesKeys: [
                'homePage',
                'products',
                'shop',
                'login',
                'orderHistory'
            ]
        };
    },

    created() {
        this.contentKey = 'timer';
        this.contentName = 'Timer';
    },

    methods: {
        afterDataFetch() {
            const date = moment
                .tz(this.content.value.endDate, this.content.value.timezone)
                .format('YYYY-MM-DD HH:mm');

            this.date = date;
        },

        changeEndDate(dateStr = null) {
            const date = moment.tz(dateStr || this.date, this.content.value.timezone).toISOString();

            this.content.value.endDate = date;
        },

        onTimezoneChange() {
            this.changeEndDate();
        },

        onDateChange(dateStr) {
            this.changeEndDate(dateStr);
        }
    }
};
</script>
