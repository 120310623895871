<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div class="form-group col-12 mt-1">
                <b-form-checkbox
                    v-model="content.value.enabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <label for="welcomeMessage">
                    Welcome Message
                </label>
                <input
                    id="welcomeMessage"
                    v-model="content.value.welcomeMessage"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label for="scanPreapringViewDescription">
                    Scan Preapring View Description
                </label>
                <input
                    id="scanPreapringViewDescription"
                    v-model="content.value.scanPreapringViewDescription"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label for="countdownBegins">Countdown Begins</label>
                <input
                    id="countdownBegins"
                    v-model="content.value.countdownBegins"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label for="scanningTitle">Scanning Title</label>
                <input
                    id="scanningTitle"
                    v-model="content.value.scanningTitle"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label for="scanningFinished">Scanning Finished</label>
                <input
                    id="scanningFinished"
                    v-model="content.value.scanningFinished"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label for="scanVoiceHowerPlaceLeftFootTitle">Scan Place Left Foot Title</label>
                <input
                    id="scanVoiceHowerPlaceLeftFootTitle"
                    v-model="content.value.scanVoiceHowerPlaceLeftFootTitle"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label for="scanVoiceHowerPlaceRightFootTitle">Scan Place Right Foot Title</label>
                <input
                    id="scanVoiceHowerPlaceRightFootTitle"
                    v-model="content.value.scanVoiceHowerPlaceRightFootTitle"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label for="scanDetectFootVoiceTitle">Scan Detect Foot Title</label>
                <input
                    id="scanDetectFootVoiceTitle"
                    v-model="content.value.scanDetectFootVoiceTitle"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label for="manualScanDescription">Manual Scan Description</label>
                <input
                    id="manualScanDescription"
                    v-model="content.value.manualScanDescription"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Voice Speed (multiplier)
                </label>
                <input
                    v-model.number="content.value.voiceSpeed"
                    type="number"
                    class="form-control"
                    step="0.1"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Delay Before Each Line (seconds)
                </label>
                <input
                    v-model.number="content.value.delayBeforeEachLine"
                    type="number"
                    class="form-control"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ContentMixin from '../content.mixin.js';

export default {
    mixins: [ContentMixin],

    created() {
        this.contentKey = 'mobile-3d-voice-over';
        this.contentName = 'Mobile 3D Voice Over';
    }
};
</script>
