<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="d-flex flex-wrap">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Add Orthotics Quantity
            </h5>
            <div class="col-6 mb-3">
                <wysiwyg-editor
                    v-model="content.value.orthoticsQuantity.text"
                    label="Description"
                />
            </div>
            <div class="col-6 mb-3">
                <wysiwyg-editor
                    v-model="content.value.orthoticsQuantity.textMobile"
                    label="Description (Mobile)"
                />
            </div>
            <div class="col-12 my-2">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Description' you can use variables. There are predefined
                    variables for <b>{Price}</b>, <b>{OldPrice}</b> and
                    <b>{Qty}</b>.
                </div>
            </div>

            <h5 class="col-12 text-uppercase bg-light p-2">
                New orthotics
            </h5>
            <media-chooser
                v-model="content.value.newOrthotics.image"
                label="Image"
                class="col-12"
            />
            <div class="col-6 mb-3">
                <wysiwyg-editor
                    v-model="content.value.newOrthotics.text"
                    label="Description"
                />
            </div>
            <div class="col-6 mb-3">
                <wysiwyg-editor
                    v-model="content.value.newOrthotics.textMobile"
                    label="Description (Mobile)"
                />
            </div>
            <div class="col-12 my-2">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Description' you can use variables. There are predefined
                    variables for <b>{Price}</b>, <b>{OldPrice}</b> and
                    <b>{Qty}</b>.
                </div>
            </div>

            <div class="col-12">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Creams Upsell
                </h5>

                <div class="d-flex flex-wrap mt-3">
                    <div class="form-group col-6">
                        <b-form-select
                            v-model="selectedSecondaryProduct"
                            :options="secondaryProductOptions"
                        />
                    </div>

                    <div class="form-group col-6 d-flex align-items-stretch pl-0">
                        <button
                            type="button"
                            class="mx-2 btn btn-sm btn-success waves-effect waves-light"
                            @click="onAddUpsellProduct"
                        >
                            <i class="mdi mdi-plus" />
                            Add Product
                        </button>
                    </div>
                </div>

                <draggable-list
                    :key="upsellProducts.length"
                    :items="upsellProducts"
                    @element-deleted="onDeleteUpsellProduct"
                    @element-moved="onMovedUpsellProduct"
                >
                    <span slot-scope="row">
                        <span>
                            {{ row.element.secondaryProduct.name }}
                        </span>
                    </span>
                </draggable-list>
            </div>
        </div>
    </form>
</template>

<script>
import { mapActions } from 'vuex';
import DraggableList from '@components/elements/DraggableList';
import MediaChooser from '@components/media/MediaChooser';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import ContentMixin from './content.mixin.js';

export default {
    components: {
        DraggableList,
        MediaChooser,
        WysiwygEditor
    },

    mixins: [ContentMixin],

    data() {
        return {
            upsellProducts: [],
            secondaryProducts: [],
            selectedSecondaryProduct: {}
        };
    },

    computed: {
        secondaryProductOptions() {
            return this.secondaryProducts.map(product => {
                return {
                    text: product.name,
                    value: product
                }
            })
        }
    },

    created() {
        this.contentKey = 'upsell';
        this.contentName = 'Upsell';
    },

    mounted() {
        this.fetchSecondaryProducts();
        this.fetchUpsellProducts();
    },

    methods: {
        ...mapActions({
            getSecondaryProducts: 'secondaryProducts/index',
            getUpsellProducts: 'upsellProducts/index',
            createUpsellProduct: 'upsellProducts/store',
            deleteUpsellProduct: 'upsellProducts/delete',
            changeUpsellProductsOrder: 'upsellProducts/changeOrder'
        }),

        async fetchSecondaryProducts() {
            try {
                const { rows } = await this.getSecondaryProducts();

                this.secondaryProducts = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async fetchUpsellProducts() {
            try {
                this.upsellProducts = await this.getUpsellProducts();
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onAddUpsellProduct() {
            if (!this.selectedSecondaryProduct.id) {
                return;
            }

            try {
                const createdUpsellProduct = await this.createUpsellProduct({
                    productId: this.selectedSecondaryProduct.id,
                    order: this.upsellProducts.length
                });

                this.upsellProducts = [
                    ...this.upsellProducts,
                    createdUpsellProduct
                ];

                this.$toaster('Product has been added!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async onDeleteUpsellProduct(id) {
            try {
                await this.deleteUpsellProduct(id);

                this.upsellProducts = this.upsellProducts.filter(
                    item => item.id !== id
                );

                this.$toaster('Product has been removed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onMovedUpsellProduct({ orderedList }) {
            try {
                const orderedArray = orderedList.map((item, index) => {
                    return { ...item, order: index };
                });

                await this.changeUpsellProductsOrder(orderedArray);

                this.$toaster('Order has been changed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        }
    }
};
</script>
