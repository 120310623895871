<template>
    <div class="col-12">
        <label class="mt-3 ml-0">
            Name *
        </label>
        <div class="d-flex flex-wrap">
            <div class="form-group col-6 pl-0">
                <input
                    v-model="name"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.name.$error }"
                />
            </div>
            <div class="form-group col-6 d-flex align-items-stretch pl-0">
                <button
                    type="button"
                    class="mx-0 btn btn-sm btn-success waves-effect waves-light"
                    @click="onCategoryAdd"
                >
                    <i class="mdi mdi-plus" />
                    Add Category
                </button>
            </div>
        </div>

        <draggable-list
            :key="categories.length"
            :items="categories"
            is-editable
            @edit-element="openCategory"
            @element-deleted="onCategoryDelete"
            @element-moved="onCategoryMove"
        >
            <span slot-scope="row">
                <span>
                    {{ row.element.name }}
                </span>
            </span>
        </draggable-list>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import DraggableList from '@components/elements/DraggableList';

export default {
    components: {
        DraggableList
    },

    data() {
        return {
            categories: [],
            name: ''
        };
    },

    validations: {
        name: {
            required
        }
    },

    mounted() {
        this.fetchCategories();
    },

    methods: {
        ...mapActions({
            getCategories: 'exerciseCategories/index',
            createCategory: 'exerciseCategories/store',
            deleteCategory: 'exerciseCategories/delete',
            changeCategoriesOrder: 'exerciseCategories/changeOrder'
        }),

        async fetchCategories() {
            try {
                this.categories = await this.getCategories();
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onCategoryAdd() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                const createdCategory = await this.createCategory({
                    name: this.name
                });

                this.name = '';

                this.$v.$reset();

                this.categories = [...this.categories, createdCategory];

                this.$toaster('Category has been added!');
            } catch (err) {
                console.error(err);

                let message = null;

                if (err.response.status === 422) {
                    message = err.response.data.error;
                }

                this.$toasterError(message);
            }
        },

        async onCategoryDelete(id) {
            try {
                await this.deleteCategory(id);

                this.categories = this.categories.filter(
                    item => item.id !== id
                );

                this.$toaster('Category has been removed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onCategoryMove({ orderedList }) {
            try {
                const orderedArray = orderedList.map((item, index) => {
                    return { ...item, order: index };
                });

                await this.changeCategoriesOrder(orderedArray);

                this.$toaster('Order has been changed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();                
            }
        },

        openCategory(id) {
            this.$router.push({
                path: this.$route.path,
                query: { categoryId: id }
            });
        }
    }
};
</script>
