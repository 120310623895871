<template>
    <div class="table-responsive p-1">
        <table id="steps-datatable" class="table table-bordered">
            <thead>
                <tr class="text-center">
                    <th>#</th>
                    <th>Step</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody :key="items.length">
                <tr v-for="(step, index) in items" :key="index">
                    <td class="text-center align-middle">
                        {{ index + 1 }}
                    </td>
                    <td>
                        <div class="row m-1">
                            <div v-if="isTitle" class="col-12 col-sm-2">
                                <div class="form-group">
                                    <label for="title">Show title</label>
                                    <b-form-checkbox
                                        v-model="step.titleVisible"
                                        switch
                                    />
                                </div>
                            </div>
                            <div v-if="isTitle" class="col-12 col-sm-6">
                                <div class="form-group">
                                    <label for="title">Title</label>
                                    <input
                                        v-model="step.title"
                                        type="text"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div v-if="isTitle" class="col-12 col-sm-4">
                                <div class="form-group">
                                    <label for="title">Title align</label>
                                    <b-form-select
                                        v-model="step.alignment"
                                        :options="['left', 'center', 'right']"
                                    />
                                </div>
                            </div>
                            <div class="col-12">
                                <label>Text</label>
                                <wysiwyg-editor v-model="step.text" />
                            </div>
                            <media-chooser
                                v-model="step.image"
                                add-buff
                                label="Desktop Image"
                                class="col-12 col-md-6"
                            />
                            <media-chooser
                                v-model="step.imageMobile"
                                add-buff
                                label="Mobile Image"
                                class="col-12 col-md-6"
                            />
                        </div>
                    </td>
                    <td class="text-center align-middle">
                        <a style="cursor:pointer" @click="deleteItem(index)">
                            <i class="far fa-trash-alt mr-1" />
                        </a>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <button
                            class="btn btn-info waves-effect waves-light"
                            @click="addItem"
                        >
                            <i class="fas fa-plus" />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import MediaChooser from '@components/media/MediaChooser';
import WysiwygEditor from '@components/elements/WysiwygEditor';

export default {
    components: {
        MediaChooser,
        WysiwygEditor
    },
    props: {
        steps: {
            type: Array,
            required: true,
            default: () => []
        },
        isTitle: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            items: []
        };
    },

    watch: {
        items() {
            this.$emit('input', this.items);
        }
    },

    created() {
        this.items = [...this.steps];
    },

    methods: {
        addItem() {
            if (this.items.length) {
                const lastItem = this.items[this.items.length - 1];

                if (
                    !lastItem.image ||
                    !lastItem.text ||
                    (this.title && !lastItem.title)
                ) {
                    this.$toasterWarning('Please fill the previous step before adding a new one!');

                    return;
                }
            }

            const newItem = {
                image: '',
                title: '',
                text: ''
            };

            this.items = [...this.items, newItem];
        },

        deleteItem(index) {
            this.items.splice(index, 1);
        }
    }
};
</script>
