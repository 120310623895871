<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div class="col-12 ml-1 mt-1">
                <div class="radio radio-primary">
                    <input
                        id="showVersionAlert"
                        v-model="content.value.enabled"
                        type="radio"
                        name="customRadio"
                        :value="true"
                    />
                    <label for="showVersionAlert">
                        Show Version Alert
                    </label>
                </div>
                <div class="radio radio-primary mt-1">
                    <input
                        id="refreshAutomatically"
                        v-model="content.value.enabled"
                        type="radio"
                        name="customRadio"
                        :value="false"
                    />
                    <label for="refreshAutomatically">
                        Refresh Page Automatically
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContentMixin from './content.mixin.js';

export default {
    mixins: [ContentMixin],

    created() {
        this.contentKey = 'version-alert';
        this.contentName = 'Version Alert';
    },
};
</script>
