<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div class="form-group col-12">
                <label for="contentTitle">Title</label>
                <textarea
                    id="contentTitle"
                    v-model="content.value.title"
                    class="form-control"
                />
            </div>
            <div class="form-group col-6">
                <label for="buttonText">Button Text</label>
                <input
                    id="buttonText"
                    v-model="content.value.button.text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-6">
                <label for="buttonLink">Button Link</label>
                <input
                    id="buttonLink"
                    v-model="content.value.button.link"
                    class="form-control"
                />
            </div>

            <list-products
                list-name="homepage-carousel"
                :fields="{
                    icon: true
                }"
            />
        </div>
    </div>
</template>

<script>
import ContentMixin from './content.mixin.js';
import ListProducts from '@components/contents/ListProducts';

export default {
    components: {
        ListProducts
    },

    mixins: [ContentMixin],

    created() {
        this.contentKey = 'product-suggestion';
        this.contentName = 'Product Suggestion';
    }
};
</script>
