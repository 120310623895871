<template>
    <div>
        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Top Banner
            </h5>

            <media-chooser
                v-model="version.topImage"
                add-buff
                label="Promotion Image"
                class="col-6"
            />
            <media-chooser
                v-model="version.topImageMobile"
                add-buff
                label="Promotion Image (Mobile)"
                class="col-6"
            />

            <div
                v-if="version.config.twoButtons"
                class="p-3 col-12"
            >
                <b-form-checkbox
                    v-model="version.config.enabledLeft"
                    class="col-4"
                    switch
                >
                    Left align content
                </b-form-checkbox>
            </div>
        </div>

        <div v-if="!version.config.twoButtons" class="row my-2">
            <media-chooser
                v-model="version.image"
                label="Main Image"
                recommended-size="2880x1000"
                class="col-6"
            />
            <media-chooser
                v-model="version.imageMobile"
                label="Main Image (Mobile)"
                recommended-size="640x975"
                class="col-6 mb-2"
            />
            <div class="form-gorup col-12 mb-2">
                <label for="title">
                    Title
                </label>
                <input
                    id="title"
                    v-model="version.title"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <wysiwyg-editor v-model="version.textSection" label="Text" />
            </div>
            <div class="form-group col-4">
                <label>Title Color</label>
                <color-picker
                    v-model="version.titleColor"
                    :color="version.titleColor"
                />
            </div>
            <div class="form-group col-4">
                <label>Text Color</label>
                <color-picker
                    v-model="version.textColor"
                    :color="version.textColor"
                />
            </div>
            <div class="form-group col-4">
                <label>Background Color</label>
                <color-picker
                    v-model="version.backgroundColor"
                    :color="version.backgroundColor"
                />
            </div>
            <div class="form-group col-4">
                <label>Title Color (Mobile)</label>
                <color-picker
                    v-model="version.titleColorMobile"
                    :color="version.titleColorMobile"
                />
            </div>
            <div class="form-group col-4 mr-2">
                <label>Text Color (Mobile)</label>
                <color-picker
                    v-model="version.textColorMobile"
                    :color="version.textColorMobile"
                />
            </div>

            <div v-if="version.config.quiz" class="form-group col-4">
                <label for="quizText">Quiz Text</label>
                <input
                    id="quizText"
                    v-model="version.quizText"
                    class="form-control"
                />
            </div>
            <div v-if="version.config.quiz" class="form-group col-4">
                <label for="quizLink">Quiz Link</label>
                <input
                    id="quizLink"
                    v-model="version.quizLink"
                    class="form-control"
                />
            </div>

            <div
                v-if="version.config.introducingVideo"
                class="form-group col-12 d-flex align-items-center"
                style="min-height: 83px"
            >
                <b-form-checkbox
                    v-model="version.introducingVideo.enabled"
                    class="col-4"
                    name="enabled"
                    switch
                >
                    Introducing video Enabled
                </b-form-checkbox>
                <template v-if="version.introducingVideo.enabled">
                    <div class="form-group col-4">
                        <label for="link">
                            Link
                        </label>
                        <input
                            id="link"
                            v-model="version.introducingVideo.link"
                            type="text"
                            class="form-control"
                        />
                    </div>
                    <div class="form-group col-4">
                        <label for="duration">
                            Duration
                        </label>
                        <input
                            id="duration"
                            v-model="version.introducingVideo.duration"
                            type="text"
                            class="form-control"
                        />
                    </div>
                </template>
            </div>
        </div>

        <div v-else class="row my-2">
            <div
                v-for="side in ['left', 'right']"
                :key="side"
                class="col-6"
            >
                <h5 class="col-12 text-uppercase bg-light p-2">
                    {{ side }} side
                </h5>
                <media-chooser
                    v-model="version[`${side}Side`].backgroundImage"
                    add-buff
                    label="Background image"
                    class="col-12"
                    recommended-size="biggest!"
                />
                <media-chooser
                    v-model="version[`${side}Side`].mobileImage"
                    add-buff
                    label="Mobile image"
                    class="col-12"
                    recommended-size="420x250"
                />
                <div class="form-group col-12">
                    <label :for="`${side}SideTitle`">
                        Title
                    </label>
                    <input
                        v-model="version[`${side}Side`].title"
                        type="text"
                        class="form-control"
                        :label="`${side}SideTitle`"
                    />
                </div>
                <div class="form-group col-12">
                    <wysiwyg-editor
                        v-model="version[`${side}Side`].text"
                        label="Description"
                    />
                </div>
                <div
                    v-if="version.config.hero"
                    class="form-group col-12 d-flex align-items-center"
                    style="min-height: 83px"
                >
                    <b-form-checkbox
                        v-model="version[`${side}Side`].hero.enabled"
                        class="col-4"
                        name="enabled"
                        switch
                    >
                        Hero Enabled
                    </b-form-checkbox>
                    <template v-if="version[`${side}Side`].hero.enabled">
                        <div class="form-group col-4">
                            <label :for="`${side}SideheroName`">
                                Name
                            </label>
                            <input
                                v-model="version[`${side}Side`].hero.name"
                                type="text"
                                class="form-control"
                                :label="`${side}SideheroName`"
                            />
                        </div>
                        <div class="form-group col-4">
                            <label :for="`${side}Sidehero.Description`">
                                Description
                            </label>
                            <input
                                v-model="
                                    version[`${side}Side`].hero.description
                                "
                                type="text"
                                class="form-control"
                                :label="`${side}SideheroDescription`"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WysiwygEditor from '@components/elements/WysiwygEditor';
import MediaChooser from '@components/media/MediaChooser';
import ColorPicker from '@components/elements/ColorPicker';

export default {
    components: {
        MediaChooser,
        WysiwygEditor,
        ColorPicker
    },

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            version: {}
        };
    },

    watch: {
        version: {
            handler() {
                this.$emit('input', this.version);
            },
            deep: true
        }
    },

    created() {
        this.version = { ...this.value};
    }
};
</script>
