<template>
    <div v-if="walkingWithASmileContent.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="d-flex flex-wrap">
            <h5 class="col-12 text-uppercase bg-light p-2">
                General
            </h5>
            <div class="form-group col-12">
                <wysiwyg-editor
                    v-model="walkingWithASmileContent.value.title"
                    label="Title"
                />
            </div>
            <div class="form-group col-12">
                <label for="subtitle">Subtitle</label>
                <textarea
                    id="subtitle"
                    v-model="walkingWithASmileContent.value.subtitle"
                    class="form-control"
                    rows="4"
                />
            </div>
            <div class="form-group col-12 d-flex justify-content-end">
                <button
                    type="button"
                    class="btn btn-success waves-effect waves-light"
                    @click="save"
                >
                    Save title
                </button>
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Elements
            </h5>
            <gallery
                v-model="elements"
                :elements="elements"
                is-homepage
                @save="save"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import WereChangesAlert from '@components/alerts/WereChangesAlert';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import Gallery from '@components/gallery/Gallery';

export default {
    components: {
        WereChangesAlert,
        WysiwygEditor,
        Gallery
    },

    data() {
        return {
            initValueStringify: {},
            walkingWithASmileContent: {},
            elements: []
        };
    },

    computed: {
        wereChanges() {
            return (
                JSON.stringify(this.walkingWithASmileContent.value) !==
                this.initValueStringify
            );
        }
    },

    async mounted() {
        const response = await this.getContent({
            key: 'walking-with-a-smile'
        });

        this.walkingWithASmileContent = { ...response };

        this.initValueStringify = JSON.stringify(
            this.walkingWithASmileContent.value
        );

        if (this.walkingWithASmileContent.value.items) {
            this.elements = [...this.walkingWithASmileContent.value.items];
        }
    },

    methods: {
        ...mapActions({
            getContent: 'contents/show',
            updateContent: 'contents/update'
        }),

        async save() {
            try {
                this.walkingWithASmileContent.value.items = [...this.elements];

                await this.updateContent({
                    key: this.walkingWithASmileContent.key,
                    contentData: this.walkingWithASmileContent
                });

                this.initValueStringify = JSON.stringify(
                    this.walkingWithASmileContent.value
                );

                this.$toaster('Walking With a Smile has been updated!');
            } catch (err) {
                console.error(err);

                let message = null;

                if (err.response.data.errors) {
                    message = err.response.data.errors.join('. ');
                }

                this.$toasterError(message);
            }
        }
    }
};
</script>
