<template>
    <div v-if="howItWorksContent.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div v-if="activeTab === 'general'" class="row">
            <media-chooser
                v-model="howItWorksContent.value.backgroundImage"
                label="Background image"
                class="col-12"
            />
            <div class="form-group col-12">
                <wysiwyg-editor
                    v-model="howItWorksContent.value.title"
                    label="Title"
                />
                <div v-if="titleErrors.length" class="invalid-feedback">
                    {{ titleErrors[0] }}
                </div>
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Steps
            </h5>
            <div class="form-group col-12">
                <how-it-works-steps
                    v-model="howItWorksContent.value.steps"
                    :steps="howItWorksContent.value.steps"
                    is-title
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Button
            </h5>
            <div class="form-group col-12 col-md-6">
                <label for="buttonText">Button Text</label>
                <input
                    id="buttonText"
                    v-model="howItWorksContent.value.button.text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="buttonLink">Button Link</label>
                <input
                    id="buttonLink"
                    v-model="howItWorksContent.value.button.link"
                    class="form-control"
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Watch Video Button
            </h5>
            <div class="form-group col-12 col-md-6">
                <label for="watchVideoText">Button Text</label>
                <input
                    id="watchVideoText"
                    v-model="howItWorksContent.value.watchVideo.text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="watchVideoLink">Button Link</label>
                <input
                    id="watchVideoLink"
                    v-model="howItWorksContent.value.watchVideo.link"
                    class="form-control"
                />
            </div>
        </div>

        <div v-if="activeTab === 'gallery'" class="row">
            <gallery
                v-model="elements"
                class="mt-3"
                :elements="elements"
                @save="save"
            />
            <h5 class="col-12 text-uppercase bg-light p-2">
                Load More Button
            </h5>
            <div class="form-group col-12 col-md-6">
                <label for="watchVideoText">Button Text</label>
                <input
                    id="watchVideoText"
                    v-model="howItWorksContent.value.loadMoreButton.text"
                    class="form-control"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { maxLength } from 'vuelidate/lib/validators';
import ContentTabs from '@components/contents/ContentTabs';
import MediaChooser from '@components/media/MediaChooser';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import howItWorksSteps from './HowItWorksSteps';
import WereChangesAlert from '@components/alerts/WereChangesAlert';
import Gallery from '@components/gallery/Gallery';

export default {
    validations: {
        howItWorksContent: {
            value: {
                title: {
                    maxLength: maxLength(300)
                }
            }
        }
    },

    components: {
        ContentTabs,
        MediaChooser,
        WysiwygEditor,
        howItWorksSteps,
        WereChangesAlert,
        Gallery
    },

    data() {
        return {
            tabs: [
                {
                    value: 'general',
                    text: 'General'
                },
                {
                    value: 'gallery',
                    text: 'Gallery'
                }
            ],
            activeTab: 'general',
            initValueStringify: {},
            initStepsStringify: [],
            howItWorksContent: {},
            elements: []
        };
    },

    computed: {
        titleErrors() {
            const errors = [];

            if (!this.$v.howItWorksContent.value.title.$dirty) return errors;

            !this.$v.howItWorksContent.value.title.maxLength &&
                errors.push('Title cannot contain more than 300 characters!');

            return errors;
        },

        wereChanges() {
            return (
                JSON.stringify(this.howItWorksContent.value) !== this.initValueStringify ||
                JSON.stringify(this.howItWorksContent.value.steps) !== this.initStepsStringify
            );
        }
    },

    async mounted() {
        try {
            const response = await this.getContent({
                key: 'how-it-works'
            });

            this.howItWorksContent = { ...response };

            this.initValueStringify = JSON.stringify(
                this.howItWorksContent.value
            );
            this.initStepsStringify = JSON.stringify(
                this.howItWorksContent.value.steps
            );

            if (this.howItWorksContent.value.gallery) {
                this.elements = [...this.howItWorksContent.value.gallery];
            }
        } catch (err) {
            console.error(err);

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            getContent: 'contents/show',
            updateContent: 'contents/update'
        }),

        async save() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                this.howItWorksContent.value.gallery = [...this.elements];

                if (!this.howItWorksContent.value.backgroundImage) {
                    this.howItWorksContent.value.backgroundImage = null;
                }

                await this.updateContent({
                    key: this.howItWorksContent.key,
                    contentData: this.howItWorksContent
                });

                this.initValueStringify = JSON.stringify(
                    this.howItWorksContent.value
                );
                this.initStepsStringify = JSON.stringify(
                    this.howItWorksContent.value.steps
                );

                this.$toaster('How It Works has been updated!');
            } catch (err) {
                console.error(err);

                let message = null;

                if (err.response.data.errors) {
                    message = err.response.data.errors.join('. ');
                }

                this.$toasterError(message);
            }
        }
    }
};
</script>
