<template>
    <b-modal
        id="createCategoryModal"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @hidden="hideModal"
    >
        <template slot="modal-title">
            Add new category
        </template>
        <div class="custom-modal-text text-left">
            <div>
                <div class="form-group">
                    <label for="name">Name</label>
                    <input
                        id="name"
                        v-model="data.name"
                        :class="{ 'is-invalid': $v.data.name.$error }"
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        placeholder="Category Name"
                        @blur="onNameBlur"
                    />
                </div>
                <div class="form-group">
                    <label for="slug">Slug</label>
                    <input
                        id="slug"
                        v-model="data.slug"
                        :class="{ 'is-invalid': $v.data.slug.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Category Slug"
                        @blur="onSlugBlur"
                    />
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="onSubmit"
                >
                    Save
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import slug from 'slug';
import { required } from 'vuelidate/lib/validators';

export default {
    data() {
        return {
            data: {
                name: '',
                slug: ''
            }
        };
    },

    validations: {
        data: {
            name: { required },
            slug: { required }
        }
    },

    methods: {
        hideModal() {
            this.$bvModal.hide('createCategoryModal');

            this.data = {};
        },

        onSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.$emit('submit', this.data);

            this.$v.$reset();
            this.hideModal();
        },

        async onNameBlur() {
            if (this.data.name) {
                this.data.slug = slug(this.data.name.toLowerCase());

                await this.$forceUpdate();
            }
        },

        async onSlugBlur() {
            if (this.data.slug) {
                this.data.slug = slug(this.data.slug.toLowerCase());

                await this.$forceUpdate();
            }
        }
    }
};
</script>
