<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <one-time-offer-navigation />

        <div class="row">
            <div class="form-group col-12 mt-2">
                <b-form-checkbox v-model="content.value.enabled" switch>
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <b-form-checkbox v-model="content.value.afterOtoEnabled" switch>
                    Show after regular oto was confirmed
                </b-form-checkbox>
            </div>
            <div class="col-12 my-2">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Title', 'Support Text', 'Description', 'Timer' and
                    'Tooltip' you can use variables from questionnaire e.g.
                    <b>{Name}</b> and predefined <b>{Timer}</b>, <b>{Price}</b>,
                    <b>{TaxedPrice}</b>, <b>{QtyPrice}</b> and
                    <b>{ProductName}</b>.
                </div>
            </div>
            <div class="form-group col-12">
                <label for="title">Title</label>
                <textarea
                    id="title"
                    v-model="content.value.title"
                    class="form-control"
                    rows="2"
                />
            </div>
            <media-chooser
                v-model="content.value.supportAvatar"
                label="Support Avatar"
                class="col-12"
                recommended-size="80x80"
            />
            <div class="form-group col-6 my-2">
                <label for="support-name">Support Name</label>
                <textarea
                    id="support-name"
                    v-model="content.value.supportName"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-6 my-2">
                <label for="support-text">Support Text</label>
                <textarea
                    id="support-text"
                    v-model="content.value.supportText"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="subtitle">Description</label>
                <textarea
                    id="subtitle"
                    v-model="content.value.subtitle"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="timer">Timer</label>
                <textarea
                    id="timer"
                    v-model="content.value.timer"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="time">Time (in minutes)</label>
                <input
                    id="time"
                    v-model="content.value.time"
                    class="form-control"
                    type="number"
                    :class="{ 'is-invalid': $v.content.value.time.$error }"
                />
                <span class="invalid-feedback">
                    Time can not empty and greater than 30.
                </span>
            </div>
            <div class="form-group col-6">
                <label for="confirmButton">Confirm button text</label>
                <input
                    id="confirmButton"
                    v-model="content.value.confirmButton"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-6">
                <label for="skipButton">Skip button text</label>
                <input
                    id="skipButton"
                    v-model="content.value.skipButton"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-12">
                <label for="tooltip">Tooltip</label>
                <textarea
                    id="tooltip"
                    v-model="content.value.tooltip"
                    class="form-control"
                    rows="2"
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Product
            </h5>
            <div class="form-group col-12">
                <label>Product</label>
                <div class="d-flex flex-wrap">
                    <b-form-select
                        v-model="content.value.secondaryProduct.slug"
                        :options="secondaryProductOptions"
                    />
                </div>
            </div>
            <media-chooser
                v-model="content.value.secondaryProduct.image"
                label="Image"
                class="col-12"
                recommended-size="80x80"
            />
            <div class="col-6 form-group mb-3">
                <label for="price">
                    Price
                </label>
                <currency-input
                    id="price"
                    v-model.number="content.value.price"
                    type="text"
                    class="form-control"
                    placeholder="Enter amount"
                />
            </div>
            <div class="col-6 form-group mb-3">
                <label for="quantity">
                    Quantity
                </label>
                <input
                    id="quantity"
                    v-model.number="content.value.quantity"
                    class="form-control"
                    min="1"
                    placeholder="Enter quantity"
                    type="number"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import OneTimeOfferNavigation from './one-time-offer/OneTimeOfferNavigation';
import MediaChooser from '@components/media/MediaChooser';
import CurrencyInput from '@components/elements/CurrencyInput';
import ContentMixin from './content.mixin.js';

export default {
    validations: {
        content: {
            value: {
                time: {
                    lessThan: v => {
                        return v && parseFloat(v) >= 5 && parseFloat(v) <= 30;
                    }
                }
            }
        }
    },

    components: {
        OneTimeOfferNavigation,
        MediaChooser,
        CurrencyInput
    },

    mixins: [ContentMixin],

    data() {
        return {
            secondaryProducts: []
        };
    },

    computed: {
        secondaryProductOptions() {
            return this.secondaryProducts.map(product => ({
                text: `${product.name} (${product.sku})`,
                value: product.slug
            }));
        }
    },

    created() {
        this.contentKey = 'one-time-offer-secondary';
        this.contentName = 'One Time Offer Creams';
    },

    async mounted() {
        await this.fetchSecondaryProducts();
    },

    methods: {
        ...mapActions({
            getSecondaryProducts: 'secondaryProducts/index'
        }),

        async fetchSecondaryProducts() {
            try {
                const { rows } = await this.getSecondaryProducts();

                this.secondaryProducts = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        afterDataFetch() {
            this.content.value.price /= 100;
        },

        beforeSave() {
            this.content.value.price = parseInt(this.content.value.price);
            this.content.value.price *= 100;
        },

        afterSave() {
            this.content.value.price /= 100;
        }
    }
};
</script>
