var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.followUsContent && _vm.followUsContent.value)?_c('div',{staticClass:"tab-pane",attrs:{"id":"settings"}},[_c('were-changes-alert',{attrs:{"were-changes":_vm.wereChanges},on:{"save":_vm.save}}),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-success waves-effect waves-light",attrs:{"type":"button"},on:{"click":_vm.save}},[_c('i',{staticClass:"fas fa-save mr-1"}),_vm._v("\n            Save updates\n        ")])]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitSocialsForm($event)}}},[_vm._m(0),_c('div',{staticClass:"row"},[(_vm.followUsContent.value.facebook)?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"social-fa"}},[_vm._v("Facebook")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"custom-icon fab fa-facebook",class:{
                                        'fb_icon-color':
                                            _vm.followUsContent.value.facebook
                                                .active
                                    }})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.followUsContent.value.facebook.url),expression:"followUsContent.value.facebook.url"}],staticClass:"form-control",attrs:{"id":"social-fa","type":"text","placeholder":"Url"},domProps:{"value":(_vm.followUsContent.value.facebook.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.followUsContent.value.facebook, "url", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('b-form-checkbox',{model:{value:(
                                        _vm.followUsContent.value.facebook
                                            .active
                                    ),callback:function ($$v) {_vm.$set(_vm.followUsContent.value.facebook
                                            , "active", $$v)},expression:"\n                                        followUsContent.value.facebook\n                                            .active\n                                    "}},[_vm._v("\n                                    Active\n                                ")])],1)])])])]):_vm._e(),(_vm.followUsContent.value.twitter)?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"social-twitter"}},[_vm._v("Twitter")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"custom-icon fab fa-twitter",class:{
                                        'twitter_icon-color':
                                            _vm.followUsContent.value.twitter
                                                .active
                                    }})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.followUsContent.value.twitter.url),expression:"followUsContent.value.twitter.url"}],staticClass:"form-control",attrs:{"id":"social-twitter","type":"text","placeholder":"Url"},domProps:{"value":(_vm.followUsContent.value.twitter.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.followUsContent.value.twitter, "url", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('b-form-checkbox',{model:{value:(
                                        _vm.followUsContent.value.twitter.active
                                    ),callback:function ($$v) {_vm.$set(_vm.followUsContent.value.twitter, "active", $$v)},expression:"\n                                        followUsContent.value.twitter.active\n                                    "}},[_vm._v("Active\n                                ")])],1)])])])]):_vm._e()]),_c('div',{staticClass:"row"},[(_vm.followUsContent.value.instagram)?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"social-insta"}},[_vm._v("Instagram")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"custom-icon fab fa-instagram",class:{
                                        'insta_icon-color':
                                            _vm.followUsContent.value.instagram
                                                .active
                                    }})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.followUsContent.value.instagram.url),expression:"followUsContent.value.instagram.url"}],staticClass:"form-control",attrs:{"id":"social-insta","type":"text","placeholder":"Url"},domProps:{"value":(_vm.followUsContent.value.instagram.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.followUsContent.value.instagram, "url", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('b-form-checkbox',{model:{value:(
                                        _vm.followUsContent.value.instagram
                                            .active
                                    ),callback:function ($$v) {_vm.$set(_vm.followUsContent.value.instagram
                                            , "active", $$v)},expression:"\n                                        followUsContent.value.instagram\n                                            .active\n                                    "}},[_vm._v("Active\n                                ")])],1)])])])]):_vm._e(),(_vm.followUsContent.value.youtube)?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"social-yt"}},[_vm._v("Youtube")]),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"custom-icon fab fa-youtube",class:{
                                        'yt_icon-color':
                                            _vm.followUsContent.value.youtube
                                                .active
                                    }})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.followUsContent.value.youtube.url),expression:"followUsContent.value.youtube.url"}],staticClass:"form-control",attrs:{"id":"social-yt","type":"text","placeholder":"Url"},domProps:{"value":(_vm.followUsContent.value.youtube.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.followUsContent.value.youtube, "url", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('b-form-checkbox',{model:{value:(
                                        _vm.followUsContent.value.youtube.active
                                    ),callback:function ($$v) {_vm.$set(_vm.followUsContent.value.youtube, "active", $$v)},expression:"\n                                        followUsContent.value.youtube.active\n                                    "}},[_vm._v("Active\n                                ")])],1)])])])]):_vm._e()])])],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticClass:"col-12 text-uppercase bg-light p-2"},[_c('i',{staticClass:"mdi mdi-earth mr-1"}),_vm._v("Social\n        ")])}]

export { render, staticRenderFns }