<template>
    <div>
        <div
            v-for="(section, index) in sections"
            :key="index"
            class=" row d-flex flex-wrap"
        >
            <h5 class="col-12 text-uppercase bg-light p-2">
                {{ section.text }}
            </h5>
            <div
                v-if="section.enabling"
                class="form-group col-12 mt-2"
            >
                <b-form-checkbox
                    v-model="buttons[section.property].enabled"
                    name="enabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12 col-sm-6">
                <label>Text</label>
                <input
                    v-model="buttons[section.property].text"
                    class="form-control"
                />
            </div>
            <div v-if="section.link" class="form-group col-12 col-sm-6">
                <label>Link</label>
                <input
                    v-model="buttons[section.property].link"
                    class="form-control"
                />
            </div>
            <div v-else class="offset-md-6" />
            <template v-if="section.colors">
                <hr />
                <div class="form-group col-6 col-sm-6">
                    <label>Background color hex</label>
                    <color-picker
                        v-model="buttons[section.property].backgroundColor"
                        :color="buttons[section.property].backgroundColor"
                    />
                </div>
                <div class="form-group col-6 col-sm-6">
                    <label>Font color hex</label>
                    <color-picker
                        v-model="buttons[section.property].fontColor"
                        :color="buttons[section.property].fontColor"
                    />
                </div>
                <div class="form-group col-6 col-sm-6">
                    <label>Hover background color hex</label>
                    <color-picker
                        v-model="buttons[section.property].backgroundColorHover"
                        :color="buttons[section.property].backgroundColorHover"
                    />
                </div>
                <div class="form-group col-6 col-sm-6">
                    <label>Hover font color hex</label>
                    <color-picker
                        v-model="buttons[section.property].fontColorHover"
                        :color="buttons[section.property].fontColorHover"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import ColorPicker from '@components/elements/ColorPicker';

export default {
    components: {
        ColorPicker
    },

    props: {
        value: {
            type: Object,
            required: false,
            default: () => {
                return {
                    topNoticeBanner: {},
                    leftTopNoticeBanner: {},
                    rightTopNoticeBanner: {},
                    whyUpstep: {},
                    processSection: {},
                    bottomHomePageText: {},
                    howItWorks: {},
                    walkingLoadMoreButton: {},
                    fixedButton: {}
                };
            }
        },
        twoButtons: {
            type: Boolean,
            required: true
        },
        homepageV2: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            buttons: {},
            sections: [
                {
                    text: 'How it works',
                    property: 'howItWorks',
                    link: true
                },
                {
                    text: 'Walking with a smile - Load more button',
                    property: 'walkingLoadMoreButton',
                    link: false,
                    colors: true
                }
            ]
        };
    },

    watch: {
        buttons: {
            handler() {
                this.$emit('input', this.buttons);
            },
            deep: true
        }
    },

    created() {
        this.buttons = { ...this.value };

        if (this.homepageV2) {
            this.sections.push({
                text: 'Fixed Button',
                property: 'fixedButton',
                link: true,
                colors: true,
                enabling: true
            });
        } else {
            this.sections.push(
                {
                    text: 'Why upstep',
                    property: 'whyUpstep',
                    link: true,
                    colors: true
                },
                {
                    text: 'Process section',
                    property: 'processSection',
                    link: true,
                    colors: true
                },
                {
                    text: 'Bottom scroll down section',
                    property: 'bottomHomePageText',
                    link: true,
                    colors: true
                }
            )

            if (this.twoButtons) {
                this.sections.unshift(
                    {
                        text: 'Top banner v2 (left)',
                        property: 'leftTopNoticeBanner',
                        link: true,
                        colors: true
                    },
                    {
                        text: 'Top banner v2 (right)',
                        property: 'rightTopNoticeBanner',
                        link: true,
                        colors: true
                    }
                );
            } else {
                this.sections.unshift({
                    text: 'Top banner',
                    property: 'topNoticeBanner',
                    link: true,
                    colors: true
                });
            }
        }
    }
};
</script>
