<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div
            v-show="activeTab === 'blog'"
            class="row"
        >
            <div class="col-12 row my-2">
                <media-chooser
                    v-model="content.value.image"
                    add-buff
                    label="Image"
                    class="col-12"
                    recommended-size="570x620"
                />
                <div class="form-group col-12">
                    <label>
                        Title
                    </label>
                    <input
                        v-model="content.value.title"
                        type="text"
                        class="form-control"
                        :lable="`SideTitle`"
                    />
                </div>
                <div class="form-group col-12">
                    <label>
                        Description
                    </label>
                    <wysiwyg-editor
                        v-model="content.value.description" 
                    />
                </div>
            </div>
        </div>

        <div
            v-show="activeTab === 'blog'"
            class="row"
        >
            <h5 class="col-12 text-uppercase bg-light p-2">
                Bottom Banner
            </h5>
            <div class="form-group col-12 mt-2">
                <b-form-checkbox
                    v-model="content.value.banner.enabled"
                    name="sticky"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <label>
                    Title
                </label>
                <input
                    v-model="content.value.banner.title"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
            <div class="form-group col-6">
                <label>
                    Button Text
                </label>
                <input
                    v-model="content.value.banner.buttonText"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
            <div class="form-group col-6">
                <label>
                    Button Link
                </label>
                <input
                    v-model="content.value.banner.buttonLink"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
        </div>

        <div
            v-show="activeTab === 'article'"
            class="row mt-2"
        >
            <h5 class="col-12 text-uppercase bg-light p-2">
                Newsletter Form
            </h5>
            <div class="form-group col-12">
                <label>
                    Title
                </label>
                <input
                    v-model="content.value.newsletter.title"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Description
                </label>
                <input
                    v-model="content.value.newsletter.description"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
            <div class="form-group col-6">
                <label>
                    Button Text
                </label>
                <input
                    v-model="content.value.newsletter.buttonText"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
            <div class="form-group col-6">
                <label>
                    Button Placeholder
                </label>
                <input
                    v-model="content.value.newsletter.buttonPlaceholder"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
        </div>

        <div
            v-show="activeTab === 'tags'"
            class="row"
        >
            <div class="col-12">
                <label class="mt-3 ml-1">
                    Name *
                </label>
                <div class="d-flex flex-wrap">
                    <div class="form-group col-6">
                        <input
                            v-model="name"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': $v.name.$error }"
                        />
                    </div>
                    <div class="form-group col-6 d-flex align-items-stretch">
                        <button
                            type="button"
                            class="mx-2 btn btn-sm btn-success waves-effect waves-light"
                            @click="onAddTag"
                        >
                            <i class="mdi mdi-plus" />
                            Add Tag
                        </button>
                    </div>
                </div>

                <draggable-list
                    :key="tags.length"
                    :items="tags"
                    @element-deleted="onDeleteTag"
                    @element-moved="onMovedTag"
                >
                    <span slot-scope="row">
                        <span>
                            {{ row.element.name }}
                        </span>
                    </span>
                </draggable-list>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { requiredIf } from 'vuelidate/lib/validators';
import ContentMixin from './content.mixin.js';
import ContentTabs from '@components/contents/ContentTabs';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import DraggableList from '@components/elements/DraggableList';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        ContentTabs,
        WysiwygEditor,
        DraggableList,
        MediaChooser
    },

    mixins: [ContentMixin],

    data() {
        return {
            tabs: [
                {
                    value: 'blog',
                    text: 'Blog'
                },
                {
                    value: 'tags',
                    text: 'Tags'
                },
                {
                    value: 'article',
                    text: 'Article'
                }
            ],
            activeTab: 'blog',
            tags: [],
            name: ''
        };
    },

    validations: {
        name: {
            required: requiredIf(function(component) {
                return component._data.activeTab === 'tags';
            })
        }
    },

    created() {
        this.contentKey = 'blog';
        this.contentName = 'Blog';
    },

    mounted() {
        this.fetchTags();
    },

    methods: {
        ...mapActions({
            getTags: 'tags/index',
            createTag: 'tags/store',
            deleteTag: 'tags/delete',
            changeTagsOrder: 'tags/changeOrder'
        }),

        async fetchTags() {
            try {
                this.tags = await this.getTags();
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onAddTag() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                const createdTag = await this.createTag({
                    name: this.name
                });

                this.name = '';

                this.$v.$reset();

                this.tags = [...this.tags, createdTag];

                this.$toaster('Tag has been added!');
            } catch (err) {
                console.error(err);

                let message = null;

                if (err.response.status === 422) {
                    message = err.response.data.error;
                }

                this.$toasterError(message);
            }
        },

        async onDeleteTag(id) {
            try {
                await this.deleteTag(id);

                this.tags = this.tags.filter(item => item.id !== id);

                this.$toaster('Tag has been removed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onMovedTag({ orderedList }) {
            const orderedArray = orderedList.map((item, index) => {
                return { ...item, order: index };
            });

            await this.changeTagsOrder(orderedArray);

            this.$toaster('Order has been changed!');
        }
    }
};
</script>
