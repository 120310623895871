<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div v-show="activeTab === 'general'" class="row">
            <div class="form-group col-12 mt-2">
                <b-form-checkbox v-model="content.value.mysteryGift" switch>
                    Mystery Gift
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <b-form-checkbox v-model="content.value.upsellToCart" switch>
                    Show Upsell
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <b-form-checkbox v-model="content.value.showReviews" switch>
                    Show Reviews
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.showContinueShoppingButton"
                    switch
                >
                    Show Continue Shopping button
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.klarnaPlacement"
                    switch
                >
                    Show Klarna Placement
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.questionnaireEditingEnabled"
                    switch
                >
                    Enable option to edit questionnaire answers
                </b-form-checkbox>
            </div>
            <div class="form-group col-8">
                <label for="buttonText">Button text</label>
                <input
                    id="buttonText"
                    v-model="content.value.buttonText"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-8">
                <label for="shippingText">Shipping text</label>
                <input
                    id="shippingText"
                    v-model="content.value.shippingText"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-8">
                <media-chooser
                    v-model="content.value.shippingImage"
                    label="Shipping icon"
                />
            </div>
            <div class="form-group col-8">
                <label>Additional pair text</label>
                <wysiwyg-editor
                    v-model="content.value.additionalPairText" 
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Mobile Coupon Field
            </h5>
            <div class="form-group col-8">
                <label for="couponFieldTopText">Text</label>
                <input
                    id="couponFieldTopText"
                    v-model="content.value.couponFieldTopText"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-8">
                <label for="couponFieldTopRecommended">Recommended Coupon</label>
                <input
                    id="couponFieldTopRecommended"
                    v-model="content.value.couponFieldTopRecommended"
                    class="form-control"
                    type="text"
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Express Checkout
            </h5>
            <div class="form-group col-12 mt-2">
                <b-form-checkbox
                    v-model="content.value.expressCheckoutEnabled"
                    switch
                >
                    Show Express Checkout
                </b-form-checkbox>
            </div>
            <div class="form-group col-8">
                <label for="expressCheckoutText">Text</label>
                <input
                    id="expressCheckoutText"
                    v-model="content.value.expressCheckoutText"
                    class="form-control"
                    type="text"
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Extra Discount Banner
            </h5>
            <div class="form-group col-8">
                <label for="couponBannerText">Headline</label>
                <input
                    id="couponBannerText"
                    v-model="content.value.couponBannerText"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-8">
                <label for="couponBannerCode">Coupon Name</label>
                <input
                    id="couponBannerCode"
                    v-model="content.value.couponBannerCode"
                    class="form-control"
                    type="text"
                />
            </div>
        </div>

        <div v-show="activeTab === 'topImage'" class="row">
            <div class="col-12 my-2">
                <b-form-checkbox
                    v-model="content.value.topImage.enabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <media-chooser
                v-model="content.value.topImage.small"
                add-buff
                label="Small"
                recommended-size="500x75"
                class="col-12"
            />
            <media-chooser
                v-model="content.value.topImage.medium"
                add-buff
                label="Medium"
                recommended-size="1200x75"
                class="col-12"
            />
            <media-chooser
                v-model="content.value.topImage.big"
                add-buff
                label="Big"
                recommended-size="2340x160"
                class="col-12"
            />
        </div>

        <div v-show="activeTab === 'exitPopup'" class="row">
            <media-chooser
                v-model="content.value.exitPopup.image"
                label="Image"
                class="col-12"
            />
            <div
                v-for="index in 3"
                :key="index"
                class="form-group col-12"
            >
                <label v-if="index === 1">
                    Benefits
                </label>
                <input
                    v-model="content.value.exitPopup.benefits[index - 1]"
                    class="form-control"
                    type="text"
                />
            </div>
        </div>

        <div v-show="activeTab === 'productList'" class="row">
            <div class="col-12 my-2">
                <b-form-checkbox
                    v-model="content.value.productsListEnabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>

            <list-products
                list-name="cart"
                :fields="{
                    icon: true
                }"
            />
        </div>
    </div>
</template>

<script>
import ContentMixin from './content.mixin.js';
import ContentTabs from '@components/contents/ContentTabs';
import MediaChooser from '@components/media/MediaChooser';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import ListProducts from '@components/contents/ListProducts';

export default {
    components: {
        ContentTabs,
        MediaChooser,
        WysiwygEditor,
        ListProducts
    },

    mixins: [ContentMixin],

    data() {
        return {
            tabs: [
                {
                    value: 'general',
                    text: 'General'
                },
                {
                    value: 'topImage',
                    text: 'Top Image'
                },
                {
                    value: 'exitPopup',
                    text: 'Exit Popup'
                },
                {
                    value: 'productList',
                    text: 'Product List'
                }
            ],
            activeTab: 'general'
        };
    },

    created() {
        this.contentKey = 'cart';
        this.contentName = 'Cart';
    }
};
</script>
