<template>
    <div v-if="helpContent.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>
        <div class="d-flex flex-wrap">
            <h5 class="col-12 text-uppercase bg-light p-2">
                General
            </h5>
            <div class="form-group col-12">
                <label for="contentTitle">Title</label>
                <textarea
                    id="contentTitle"
                    v-model="helpContent.value.title"
                    :class="{ 'is-invalid': $v.helpContent.value.title.$error }"
                    class="form-control"
                    rows="3"
                />
            </div>
            <div class="form-group col-12">
                <label for="contactTitle">Contact Title</label>
                <textarea
                    id="contactTitle"
                    v-model="helpContent.value.contact.title"
                    :class="{
                        'is-invalid': $v.helpContent.value.contact.title.$error
                    }"
                    class="form-control"
                    rows="3"
                />
            </div>
            <div class="form-group col-12">
                <label for="help_email">Help Email</label>
                <input
                    id="help_email"
                    v-model="helpContent.value.contact.email"
                    :class="{
                        'is-invalid': $v.helpContent.value.contact.email.$error
                    }"
                    class="form-control"
                />
            </div>
            <div class="form-group col-md-6">
                <label>Contact Info</label>
                <wysiwyg-editor v-model="helpContent.value.contact.info" />
            </div>
            <div class="form-group col-md-6">
                <label>Contact Hours</label>
                <wysiwyg-editor
                    v-model="helpContent.value.contact.hours"
                    class="is-invalid"
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                "Thanks for contact" modal
            </h5>
            <media-chooser
                v-model="helpContent.value.contact.modalImage"
                class="col-12"
            />
            <div class="form-group col-12 col-md-6">
                <label for="modalTitle">Title</label>
                <textarea
                    id="modalTitle"
                    v-model="helpContent.value.contact.modalTitle"
                    :class="{
                        'is-invalid':
                            $v.helpContent.value.contact.modalTitle.$error
                    }"
                    class="form-control"
                    rows="3"
                />
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="modalSubtitle">Subtitle</label>
                <textarea
                    id="modalSubtitle"
                    v-model="helpContent.value.contact.modalSubtitle"
                    :class="{
                        'is-invalid':
                            $v.helpContent.value.contact.modalSubtitle.$error
                    }"
                    class="form-control"
                    rows="3"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import WereChangesAlert from '@components/alerts/WereChangesAlert';
import MediaChooser from '@components/media/MediaChooser';

export default {
    validations: {
        helpContent: {
            value: {
                title: {
                    required
                },
                contact: {
                    title: {
                        required
                    },
                    email: {
                        email
                    },
                    modalTitle: {
                        required
                    },
                    modalSubtitle: {
                        required
                    }
                }
            }
        }
    },

    components: {
        WysiwygEditor,
        WereChangesAlert,
        MediaChooser
    },

    data() {
        return {
            initValueStringify: {},
            helpContent: {}
        };
    },

    computed: {
        wereChanges() {
            return (
                JSON.stringify(this.helpContent.value) !==
                this.initValueStringify
            );
        }
    },

    async mounted() {
        try {
            const response = await this.getContent({
                key: 'help'
            });

            this.helpContent = { ...response };

            this.initValueStringify = JSON.stringify(this.helpContent.value);
        } catch (err) {
            console.error(err);

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            getContent: 'contents/show',
            updateContent: 'contents/update'
        }),

        async save() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            if (!this.areWysiwygsFilled()) {
                this.$toasterWarning('Please fill all fields!');

                return;
            }

            try {
                await this.updateContent({
                    key: this.helpContent.key,
                    contentData: this.helpContent
                });

                this.initValueStringify = JSON.stringify(
                    this.helpContent.value
                );

                this.$toaster('Help has been updated!');
            } catch (err) {
                console.error(err);

                let message = null;

                if (err.response.data.errors) {
                    message = err.response.data.errors.join('. ');
                }

                this.$toasterError(message);
            }
        },

        areWysiwygsFilled() {
            const regex = /(<([^>]+)>)/gi;

            const infoWithoutHtml = this.helpContent.value.contact.info.replace(
                regex,
                ''
            );
            const hoursWithoutHtml = this.helpContent.value.contact.hours.replace(
                regex,
                ''
            );

            return infoWithoutHtml && hoursWithoutHtml;
        }
    }
};
</script>
