<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>
        <div class="d-flex flex-wrap">
            <div class="form-group col-12">
                <label for="orderTitle">Title</label>
                <textarea
                    id="orderTitle"
                    v-model="content.value.title"
                    :class="{
                        'is-invalid': $v.content.value.title.$error
                    }"
                    :error-messages="titleErrors"
                    class="form-control"
                    rows="3"
                    @input="$v.content.value.title.$touch"
                    @blur="$v.content.value.title.$touch"
                />
                <div v-if="titleErrors.length" class="invalid-feedback">
                    {{ titleErrors[0] }}
                </div>
            </div>
            <div class="form-group col-12">
                <label>
                    Subtitle
                </label>
                <wysiwyg-editor v-model="content.value.subtitle" />
            </div>
            <div class="form-group col-12">
                <label>
                    Bottom Text
                </label>
                <wysiwyg-editor v-model="content.value.bottomText" />
            </div>
        </div>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import ContentMixin from './content.mixin.js';

export default {
    components: {
        WysiwygEditor,
    },

    mixins: [ContentMixin],

    validations: {
        content: {
            value: {
                title: {
                    required
                }
            }
        }
    },

    computed: {
        titleErrors() {
            const errors = [];

            if (!this.$v.content.value.title.$dirty) return errors;

            !this.$v.content.value.title.required &&
                errors.push('This field is required!');

            return errors;
        }
    },

    created() {
        this.contentKey = 'order-tracking';
        this.contentName = 'Order Tracking';
    }
};
</script>
