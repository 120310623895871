<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Section 1
            </h5>
            <div class="col-12">
                <label for="section1-text">Title</label>
                <input
                    id="section1-text"
                    v-model="content.value.section1.title"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-12 mt-1">
                <wysiwyg-editor
                    v-model="content.value.section1.text"
                    label="Text"
                />
            </div>
            <media-chooser
                v-model="content.value.section1.image"
                add-buff
                class="col-12"
            />
        </div>
        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Section 2
            </h5>
            <div class="col-12">
                <label for="section1-text">Title</label>
                <input
                    id="section1-text"
                    v-model="content.value.section2.title"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-12 mt-1">
                <wysiwyg-editor
                    v-model="content.value.section2.text"
                    label="Text"
                />
            </div>
            <media-chooser
                v-model="content.value.section2.image"
                add-buff
                class="col-12"
            />
        </div>
        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Phil Section
            </h5>
            <div class="col-12">
                <wysiwyg-editor
                    v-model="content.value.sectionPhil.title"
                    label="Title"
                />
            </div>
            <div class="form-group col-12 mt-1">
                <wysiwyg-editor
                    v-model="content.value.sectionPhil.text"
                    label="Text"
                />
            </div>
            <media-chooser
                v-model="content.value.sectionPhil.image"
                label="Image"
                add-buff
                class="col-12"
            />
            <div class="form-group col-12 mt-1">
                <wysiwyg-editor
                    v-model="content.value.sectionPhil.imageDescription"
                    label="Image Description"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ContentMixin from './content.mixin.js';
import MediaChooser from '@components/media/MediaChooser';
import WysiwygEditor from '@components/elements/WysiwygEditor';

export default {
    components: {
        MediaChooser,
        WysiwygEditor
    },
    mixins: [ContentMixin],

    created() {
        this.contentKey = 'why-upstep';
        this.contentName = 'Why Upstep';
    }
};
</script>
