<template>
    <div class="table-responsive p-1">
        <slot name="list-header" :addItem="addItem" />
        <table id="faq-datatable" class="table table-bordered table-striped">
            <thead>
                <tr class="text-center">
                    <th>#</th>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Remove</th>
                </tr>
            </thead>
            <draggable v-model="items" tag="tbody" ghost-class="ghost">
                <tr v-for="(question, index) in items" :key="index">
                    <td class="text-center align-middle">
                        {{ index + 1 }}
                    </td>
                    <td>
                        <div class="form-group">
                            <textarea
                                v-model="question.question"
                                type="text"
                                class="form-control"
                            />
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <textarea
                                v-model="question.answer"
                                type="text"
                                class="form-control"
                            />
                        </div>
                    </td>
                    <td class="text-center align-middle">
                        <a style="cursor:pointer" @click="deleteItem(index)">
                            <i class="far fa-trash-alt mr-1" />
                        </a>
                    </td>
                </tr>
            </draggable>
        </table>
    </div>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
    components: {
        Draggable
    },

    props: {
        value: {
            type: Array,
            required: true
        }
    },

    data: () => {
        return {
            items: []
        };
    },

    watch: {
        items: {
            handler: function() {
                this.$emit('input', this.items);
            },
            deep: true
        }
    },

    mounted() {
        this.items = [...this.value];
    },

    methods: {
        addItem() {
            const lastItem = this.items[this.items.length - 1] || {};

            if (this.items.length && (!lastItem.question || !lastItem.answer)) {
                this.$toasterWarning(
                    'Please fill the previous question before adding a new one!'
                );

                return;
            }

            const newQuestion = {
                question: '',
                answer: ''
            };

            this.items = [...this.items, newQuestion];
        },

        async deleteItem(index) {
            const confirmed = await this.$confirmationModal(
                'Are you sure you want to delete this question?'
            );

            if (confirmed) {
                this.items.splice(index, 1);
            }
        }
    }
};
</script>
