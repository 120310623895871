<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div class="form-group col-12">
                <b-form-checkbox v-model="content.value.enabled" switch>
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <label for="contentTitle">Title</label>
                <textarea
                    id="contentTitle"
                    v-model="content.value.title"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label>Button Text</label>
                <input
                    v-model="content.value.buttonText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label>Questionnaire</label>
                <b-form-select
                    v-model="content.value.questionnaireName"
                    :options="questionnaires"
                    text-field="name"
                    value-field="name"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ContentMixin from './content.mixin.js';

export default {
    mixins: [ContentMixin],

    data() {
        return {
            questionnaires: []
        };
    },

    created() {
        this.contentKey = 'homepage-questionnaire';
        this.contentName = 'Questionnaire';

        this.fetchQuestionnaires();
    },

    methods: {
        ...mapActions({
            getQuestionnaires: 'questionnaires/index'
        }),

        async fetchQuestionnaires() {
            try {
                this.questionnaires = await this.getQuestionnaires({
                    prePurchaseOnly: true
                });
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        }
    }
};
</script>
