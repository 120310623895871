<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div v-show="activeTab === 'content'" class="row">
            <div class="col-12 row my-2">
                <media-chooser
                    v-model="content.value.desktopImage"
                    add-buff
                    label="Desktop image"
                    class="col-12"
                />

                <media-chooser
                    v-model="content.value.mobileImage"
                    add-buff
                    label="Mobile image"
                    class="col-12"
                />

                <div class="form-group col-12">
                    <label>
                        General Title
                    </label>
                    <input
                        v-model="content.value.title"
                        type="text"
                        class="form-control"
                    />
                </div>
                <div class="form-group col-12">
                    <label>
                        Personal Title
                    </label>
                    <input
                        v-model="content.value.personalTitle"
                        type="text"
                        class="form-control"
                    />
                </div>
                <div class="form-group col-12">
                    <label>
                        Description
                    </label>
                    <wysiwyg-editor v-model="content.value.description" />
                </div>
            </div>
        </div>

        <div v-show="activeTab === 'categories'" class="row">
            <single-subcategory
                v-if="selectedSubcategory"
                :category-id="selectedCategory"
                :subcategory-id="selectedSubcategory"
            />
            <single-category
                v-else-if="selectedCategory"
                :category-id="selectedCategory"
            />
            <categories-list v-else />
        </div>
    </div>
</template>

<script>
import ContentMixin from './content.mixin.js';
import ContentTabs from '@components/contents/ContentTabs';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import CategoriesList from './exercises/CategoriesList';
import SingleCategory from './exercises/SingleCategory';
import SingleSubcategory from './exercises/SingleSubcategory';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        ContentTabs,
        WysiwygEditor,
        CategoriesList,
        SingleCategory,
        SingleSubcategory,
        MediaChooser
    },

    mixins: [ContentMixin],

    data() {
        return {
            tabs: [
                {
                    value: 'content',
                    text: 'Content'
                },
                {
                    value: 'categories',
                    text: 'Categories'
                }
            ],
            activeTab: 'content',
            selectedCategory: '',
            selectedSubcategory: ''
        };
    },

    watch: {
        $route: {
            handler() {
                const {
                    categories,
                    categoryId,
                    subcategoryId
                } = this.$route.query;

                if (categories || categoryId) {
                    this.activeTab = 'categories';
                }

                if (categoryId) {
                    this.selectedCategory = categoryId;
                }

                if (subcategoryId) {
                    this.selectedSubcategory = subcategoryId;
                }
            },
            immediate: true
        }
    },

    created() {
        this.contentKey = 'exercises';
        this.contentName = 'Exercises';
    }
};
</script>
