<template>
    <div :class="{ error: errorBorder }">
        <label v-if="label">{{ label }}</label>
        <editor
            key="default"
            :api-key="apiKey"
            :value="value"
            :init="initEditorSettings"
            @input="$emit('input', $event)"
        />
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import { tinymce } from '@/config';

export default {
    components: {
        Editor
    },

    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        errorBorder: {
            type: Boolean,
            required: false,
            default: false
        },
        height: {
            type: String,
            required: false,
            default: '200'
        }
    },

    data() {
        return {
            apiKey: tinymce
        };
    },

    computed: {
        initEditorSettings() {
            return {
                height: this.height,
                menubar: false,
                statusbar: false,
                skin: 'small',
                icons: 'small',
                lineheight_formats:
                    '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 36pt',
                plugins: [
                    'code advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                    'directionality letterspacing'
                ],
                toolbar: `
                    code | undo redo | formatselect fontsizeselect | bold italic underline strikethrough |
                    superscript subscript | forecolor backcolor| link image blockquote |
                    alignleft aligncenter alignright alignjustify |
                    bullist numlist outdent indent | removeformat | lineheight letterspacing | help
                `,
                table_appearance_options: false,
                relative_urls: true,
                setup: () => {
                    this.loadCustomPlugins();
                }
            };
        }
    },

    methods: {
        loadCustomPlugins() {
            window.tinymce.PluginManager.add('letterspacing', function(editor) {
                const letterSpacingIcon =
                    '<svg height="22" width="22" viewBox="0 0 512 512"><path d="M133.39,317.7q16.8-7.54,33.39-28.46h1.66l9.74,31.88H213V166.88q0-40.78-22.29-59.83t-66-19a188.32,188.32,0,0,0-41.89,5.07,170.08,170.08,0,0,0-40.86,14.8l15.76,34.78A256.34,256.34,0,0,1,88.8,130.45a110.27,110.27,0,0,1,33.8-5.18q21.57,0,31.84,10.87T164.7,169v12.22l-39.61,1.24q-53.91,1.67-80.25,19.67T18.5,255.49q0,33.35,19,51.55t52.77,18.22Q116.59,325.26,133.39,317.7ZM69.1,255.91q0-21.12,15.66-31.37t50.08-11.49l29.45-1.24v19.88q0,26.7-16.07,41.5T105.6,288q-18.25,0-27.37-8.39T69.1,255.91Z"/><path d="M469.55,120.08Q445.6,89,402.67,89q-45.21,0-68.44,34.16h-2.07q.4-4.55,1.24-21.32t.83-25.26V0H285.5V322.14H322L330.91,295h3.32q24.06,31.26,67.61,31.26,43.13,0,67.4-31.37t24.26-87.67Q493.5,151.13,469.55,120.08Zm-39.4,146q-13.38,20.5-39.3,20.5-30.08,0-43.35-18.74t-13.27-60.55v-3.32q.42-40.57,13.38-57.86T390,128.77q53.51,0,53.5,78Q443.52,245.54,430.15,266Z"/><path d="M331.07,363.56a3.56,3.56,0,0,0-5,.2L313,377.84a3.57,3.57,0,0,0,.21,5.07l45.42,41.23H139l45.46-41.23a3.56,3.56,0,0,0,.21-5.07l-13.11-14.08a3.56,3.56,0,0,0-5-.2L88.81,434.47a3.56,3.56,0,0,0,0,5.26l78.12,71.34a3.56,3.56,0,0,0,5-.2l13.11-14.09a3.56,3.56,0,0,0-.21-5.06l-45.42-41.23H359l-45.46,41.23a3.55,3.55,0,0,0-.21,5.06l13.11,14.09a3.57,3.57,0,0,0,5,.2l77.71-70.91a3.56,3.56,0,0,0,0-5.26Z"/></svg>';

                editor.ui.registry.addIcon('letterspacing', letterSpacingIcon);

                const setLetterSpacing = value => {
                    const { formatter } = window.tinymce.activeEditor;

                    formatter.register('letterspacing', {
                        inline: 'span',
                        styles: { letterSpacing: `${value}px` }
                    });

                    formatter.apply('letterspacing');
                };

                editor.ui.registry.addMenuButton('letterspacing', {
                    icon: 'letterspacing',
                    fetch(callback) {
                        const values = [-1, -0.5, -0.2, 0, 0.2, 0.5, 1, 1.5, 2, 3, 4, 6];

                        const items = values.map(value => ({
                            type: 'menuitem',
                            text: `${value}px`,
                            onAction() {
                                setLetterSpacing(value);
                            }
                        }));

                        callback(items);
                    }
                });
            });
        }
    }
};
</script>

<style lang="scss">
.tox-tinymce {
    border: 1px solid #ced4da !important;
    border-radius: 0.2rem !important;
}

.error {
    .tox-tinymce {
        border: 1px solid #dd0027 !important;
    }
}
</style>
