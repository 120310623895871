<template>
    <div class="col-12">
        <were-changes-alert
            :were-changes="wereChanges"
            @save="onSubcategoryUpdate"
        />
        <div class="d-flex flex-wrap">
            <div class="form-group col-6 pl-0">
                <label>
                    Name *
                </label>
                <input
                    v-model="subcategory.name"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.subcategory.name.$error }"
                />
            </div>
            <div class="form-group col-6 px-0">
                <label>
                    Identifier *
                </label>
                <input
                    v-model="subcategory.identifier"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.subcategory.identifier.$error }"
                />
            </div>
            <div class="form-group col-12 px-0">
                <label class="mt-0 ml-0">
                    Description *
                </label>
                <textarea
                    v-model="subcategory.description"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.subcategory.description.$error }"
                />
            </div>
            <div class="form-group col-12 pl-0">
                <b-form-checkbox v-model="subcategory.is_default" switch>
                    Is Default
                </b-form-checkbox>
            </div>

            <div
                v-for="(exercises, index) in exercisesByLevel"
                :key="index"
                class="col-12 pl-0"
            >
                <h5 class="col-12 text-uppercase bg-light p-2 mb-0">
                    Exercises Level {{ index + 1 }}
                </h5>

                <div class="form-group col-6 pl-0 mt-2">
                    <b-form-select
                        v-model="selectedExercise"
                        :options="selectOptions"
                    />
                </div>

                <div
                    class="form-group col-6 d-flex align-items-stretch pl-0 mt-2"
                >
                    <button
                        type="button"
                        class="ml-0 btn btn-sm btn-success waves-effect waves-light"
                        @click="onExerciseAdd(index)"
                    >
                        <i class="mdi mdi-plus" />
                        Add Exercise
                    </button>
                </div>

                <draggable-list
                    v-if="exercises"
                    :key="exercises.length"
                    :items="exercises"
                    is-editable
                    @edit-element="openExercise"
                    @element-deleted="onExerciseDelete"
                    @element-moved="onExerciseMove"
                >
                    <span slot-scope="row">
                        <span>
                            {{ row.element.name }}
                        </span>
                    </span>
                </draggable-list>
            </div>
        </div>

        <div
            class="form-group mt-5 d-flex align-items-stretch pl-0 float-right"
        >
            <button
                type="button"
                class="mx-0 btn btn-sm btn-danger waves-effect waves-light"
                @click="back"
            >
                Back to subcategories
            </button>
        </div>

        <div class="col-12 mt-2 pl-0">
            <button
                class="btn btn-info waves-effect waves-light"
                type="button"
                @click="addLevel"
            >
                <i class="fas fa-plus" />
                Add level
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import DraggableList from '@components/elements/DraggableList';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    components: {
        DraggableList,
        WereChangesAlert
    },

    props: {
        categoryId: {
            type: String,
            required: true
        },
        subcategoryId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            allExercises: [],
            selectedExercise: {},
            subcategory: {},
            exercisesByLevel: [],
            subcategoryLevels: 0,
            initSubcategoryStringify: ''
        };
    },

    computed: {
        wereChanges() {
            return (
                this.initSubcategoryStringify !==
                JSON.stringify({ ...this.subcategory, exercises: [] })
            );
        },

        selectOptions() {
            return this.allExercises.map(exercise => {
                return {
                    text: exercise.name,
                    value: exercise
                };
            });
        }
    },

    watch: {
        'subcategory.exercises'() {
            const temp = [];

            this.subcategory.exercises.forEach(exercise => {
                const level =
                    exercise.exercise_2_exercise_subcategory.level - 1;

                if (temp[level]) {
                    temp[level].push(exercise);
                } else {
                    temp[level] = [exercise];
                }
            });

            this.exercisesByLevel = temp;
        }
    },

    validations: {
        subcategory: {
            name: {
                required
            },
            description: {
                required
            },
            identifier: {
                required
            }
        }
    },

    mounted() {
        this.fetchSubcategory();
        this.fetchExercises();
    },

    methods: {
        ...mapActions({
            getExercises: 'exercises/getAll',
            deleteExercise: 'exercises/delete',
            changeExercisesOrder: 'exercises/changeOrder',
            getSubcategory: 'exerciseSubcategories/show',
            updateSubcategory: 'exerciseSubcategories/update',
            addExerciseToSubcategory: 'exerciseSubcategories/addExercise',
            removeExerciseFromSubcategory:
                'exerciseSubcategories/removeExercise'
        }),

        async fetchSubcategory() {
            try {
                this.subcategory = await this.getSubcategory(
                    this.subcategoryId
                );

                this.initSubcategoryStringify = JSON.stringify({
                    ...this.subcategory,
                    exercises: []
                });
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async fetchExercises() {
            try {
                const exercises = await this.getExercises();

                this.allExercises = exercises.filter(exercise =>
                    exercise.subcategories.every(
                        subcategory => subcategory.id !== this.subcategoryId
                    )
                );
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onExerciseMove({ orderedList }) {
            try {
                const orderedArray = orderedList.map((item, index) => {
                    return { ...item, order: index };
                });

                await this.changeExercisesOrder({
                    subcategoryId: this.subcategoryId,
                    items: orderedArray
                });

                this.$toaster('Order has been changed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onSubcategoryUpdate() {
            try {
                this.$v.$touch();

                if (this.$v.$invalid) {
                    return;
                }

                await this.updateSubcategory({
                    id: this.subcategoryId,
                    ...this.subcategory
                });

                this.initSubcategoryStringify = JSON.stringify({
                    ...this.subcategory,
                    exercises: []
                });

                this.$toaster('Subcategory has been changed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onExerciseAdd(level) {
            try {
                const updatedSubcategory = await this.addExerciseToSubcategory({
                    subcategoryId: this.subcategoryId,
                    exerciseId: this.selectedExercise.id,
                    level: level + 1
                });

                this.subcategory = updatedSubcategory;
                this.allExercises = this.allExercises.filter(
                    exercise => exercise.id !== this.selectedExercise.id
                );
                this.selectedExercise = {};

                this.initSubcategoryStringify = JSON.stringify({
                    ...this.subcategory,
                    exercises: []
                });

                this.$toaster('Exercise has been added!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onExerciseDelete(id) {
            try {
                await this.removeExerciseFromSubcategory({
                    subcategoryId: this.subcategoryId,
                    exerciseId: id
                });

                this.subcategory.exercises = this.subcategory.exercises.filter(
                    item => item.id !== id
                );

                this.$toaster('Exercise has been removed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        openExercise(id) {
            this.$router.push(`/exercises/${id}/edit`);
        },

        back() {
            this.$router.push({
                path: this.$route.path,
                query: { categoryId: this.categoryId }
            });
        },

        addLevel() {
            this.exercisesByLevel.push([]);
        }
    }
};
</script>
