<template>
    <b-modal
        :id="modalId"
        class="modal-demo "
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        @show="openModal"
        @hide="hideModal"
    >
        <template slot="modal-title">
            Change media order
        </template>

        <div class="order-modal">
            <draggable
                v-if="media && media.length"
                :key="orderKey"
                :list="mediaList"
                group="product_image"
                ghost-class="ghost"
                tag="div"
                class="image-container"
                @start="drag = true"
                @end="onFinishDraggingMedia"
            >
                <div
                    v-for="(mediaItem, index) in mediaList"
                    :key="`media_${index}`"
                    v-media-url="mediaItem"
                />
            </draggable>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-end">
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="hideModal"
                >
                    Done
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
    components: {
        Draggable
    },

    props: {
        media: {
            type: Array,
            required: false,
            default: () => []
        },
        modalId: {
            type: String,
            required: false,
            default: 'mediaOrderModal'
        }
    },

    data() {
        return {
            drag: true,
            mediaList: []
        };
    },

    computed: {
        orderKey() {
            return this.mediaList.reduce(
                (key, { filename }) => key + filename,
                ''
            );
        }
    },

    methods: {
        onFinishDraggingMedia() {
            this.drag = false;
            this.$emit('orderChanged', this.mediaList);
        },

        openModal() {
            this.mediaList = [...this.media];
        },

        hideModal() {
            this.$bvModal.hide(this.modalId);
        }
    }
};
</script>
