<template>
    <div class="col-12 mt-3">
        <h5 v-if="title" class="text-uppercase mt-0 bg-light p-2">
            {{ title }}
        </h5>
        <div class="row">
            <div v-if="componentFields.title" class="col-12 form-group">
                <label for="title">Title</label>
                <input
                    id="title"
                    v-model="content.title"
                    type="text"
                    class="form-control"
                    @input="emitContent"
                />
            </div>
            <div v-if="componentFields.content" class="col-12 mb-2">
                <wysiwyg-editor
                    v-model="content.content"
                    label="Content"
                    @input="emitContent"
                />
            </div>
            <media-chooser
                v-if="componentFields.image"
                v-model="content.image"
                add-buff
                class="col-12"
                label="Image"
                @input="emitContent"
            />
        </div>
    </div>
</template>

<script>
import MediaChooser from '@components/media/MediaChooser';
import WysiwygEditor from '@components/elements/WysiwygEditor';

export default {
    components: {
        MediaChooser,
        WysiwygEditor
    },

    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({})
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        fields: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    data() {
        return {
            content: {},
            defaultFields: {
                title: false,
                content: false,
                image: false
            }
        };
    },

    computed: {
        componentFields() {
            return {
                ...this.defaultFields,
                ...this.fields
            };
        }
    },

    created() {
        this.content = { ...this.value };
    },

    methods: {
        emitContent() {
            this.$emit('input', this.content);
        }
    }
};
</script>
