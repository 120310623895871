<template>
    <div>
        <div class="form-group col-12 mt-3">
            <b-form-checkbox v-model="linkOnly" switch>
                Link Only
            </b-form-checkbox>
        </div>
        <div v-if="linkOnly" class="form-group col-12">
            <label :id="id">Video Link</label>
            <input
                :id="id"
                v-model="video.link"
                type="text"
                class="form-control"
                placeholder="Link"
                @input="$emit('input', video)"
            />
        </div>
        <div v-else class="form-group col-12">
            <label>Video</label>
            <media-chooser
                :key="videoKey"
                :value="video.videoData"
                type="video"
                @input="onVideoChange"
            />
        </div>
        <div v-if="thumbnailEnabled" class="form-group col-12">
            <label>Thumbnail</label>
            <media-chooser
                :key="imageKey"
                :value="video.image"
                type="image"
                @input="onImageChange"
            />
        </div>
    </div>
</template>

<script>
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        MediaChooser
    },

    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({})
        },
        thumbnailEnabled: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data() {
        return {
            video: {},
            imageKey: 0,
            videoKey: 0,
            id: 'video',
            linkOnly: true
        };
    },

    watch: {
        linkOnly() {
            this.video.linkOnly = this.linkOnly;

            this.$emit('input', this.video);
        }
    },

    created() {
        this.id = Math.random()
            .toString(36)
            .substring(2, 15)
            .match(/[a-zA-Z]+/g)
            .join('');
    },

    mounted() {
        this.video = { ...this.value };
        this.linkOnly = this.video.linkOnly;
    },

    methods: {
        onImageChange(image) {
            if (image.id) {
                this.video.image = { ...image };
            } else {
                this.video.image = {};
            }

            this.$emit('input', this.video);
            this.imageKey += 1;
        },

        onVideoChange(video) {
            if (video.id) {
                this.video.videoData = { ...video };
            } else {
                this.video.videoData = {};
            }

            this.$emit('input', this.video);
            this.videoKey += 1;
        }
    }
};
</script>
