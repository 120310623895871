<template>
    <layout>
        <page-header :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div v-if="currentContentComponent" class="card-body">
                        <component :is="currentContentComponent"></component>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import appConfig from '@src/app.config';
import ContentPages from '@views/contents';

export default {
    page: {
        title: 'Contents',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        ...ContentPages
    },

    data() {
        return {
            title: 'Contents',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Contents'
                }
            ],
            currentContentComponent: '',
            contentsItems: [
                {
                    key: 'about',
                    text: 'About',
                    component: 'content-about'
                },
                {
                    key: 'accounts-page',
                    text: 'Accounts Page',
                    component: 'content-accounts-page'
                },
                {
                    key: 'bottom-home-page-text',
                    text: 'Bottom scroll text',
                    component: 'content-bottom-scroll-text'
                },
                {
                    key: 'blog',
                    text: 'Blog',
                    component: 'content-blog'
                },
                {
                    key: 'cart',
                    text: 'Cart',
                    component: 'content-cart'
                },
                {
                    key: 'checkout',
                    text: 'Checkout',
                    component: 'content-checkout'
                },
                {
                    key: 'exercises',
                    text: 'Exercises',
                    component: 'content-exercises'
                },
                {
                    key: 'faq',
                    text: 'FAQ',
                    component: 'content-faq'
                },
                {
                    key: 'fedex',
                    text: 'Fedex',
                    component: 'content-fedex'
                },
                {
                    key: 'follow-us',
                    text: 'Follow Us',
                    component: 'content-follow-us'
                },
                {
                    key: 'footer',
                    text: 'Footer',
                    component: 'content-footer'
                },
                {
                    key: 'gift-card',
                    text: 'Gift Card',
                    component: 'content-gift-card'
                },
                {
                    key: 'guarantee',
                    text: 'Guarantee',
                    component: 'content-guarantee'
                },
                {
                    key: 'header',
                    text: 'Header',
                    component: 'content-header'
                },
                {
                    key: 'help',
                    text: 'Help',
                    component: 'content-help'
                },
                {
                    key: 'homepage-faq',
                    text: 'Homepage Faq',
                    component: 'content-homepage-faq'
                },
                {
                    key: 'homepage-how-it-works',
                    text: 'Homepage How It Works',
                    component: 'content-homepage-how-it-works'
                },
                {
                    key: 'homepage-questionnaire',
                    text: 'Homepage Questionnaire',
                    component: 'content-homepage-questionnaire'
                },
                {
                    key: 'how-it-works',
                    text: 'How It Works',
                    component: 'content-how-it-works'
                },
                {
                    key: 'impression-kit',
                    text: 'Impression Kit',
                    component: 'content-impression-kit'
                },
                {
                    key: 'menu-main',
                    text: 'Menu Main',
                    component: 'menu-main'
                },
                {
                    key: 'menu-mobile',
                    text: 'Menu Mobile',
                    component: 'menu-mobile'
                },
                {
                    key: 'menu-top',
                    text: 'Menu Top',
                    component: 'menu-top'
                },
                {
                    key: 'newsletter-modal',
                    text: 'Newsletter Modal',
                    component: 'content-newsletter-modal'
                },
                {
                    key: 'notice',
                    text: 'Notice',
                    component: 'content-notice'
                },
                {
                    key: 'one-time-offer',
                    text: 'One Time Offer',
                    component: 'content-one-time-offer'
                },
                {
                    key: 'one-time-offer-ikf',
                    text: 'One Time Offer',
                    component: 'content-one-time-offer-ikf'
                },
                {
                    key: 'one-time-offer-secondary',
                    text: 'One Time Offer',
                    component: 'content-one-time-offer-secondary'
                },
                {
                    key: 'one-time-offer-discount',
                    text: 'One Time Offer',
                    component: 'content-one-time-offer-discount'
                },
                {
                    key: 'order-tracking',
                    text: 'Order Tracking',
                    component: 'content-order-tracking'
                },
                {
                    key: 'pages-titles',
                    text: 'Pages Titles',
                    component: 'content-pages-titles'
                },
                {
                    key: 'post-purchase',
                    text: 'Post Purchase',
                    component: 'content-post-purchase'
                },
                {
                    key: 'privacy-policy',
                    text: 'Privacy Policy',
                    component: 'content-privacy-policy'
                },
                {
                    key: 'process-upstep',
                    text: 'Process',
                    component: 'content-process-upstep'
                },
                {
                    key: 'product-page',
                    text: 'Product Page',
                    component: 'content-product-page'
                },
                {
                    key: 'product-suggestion',
                    text: 'Product Suggestion',
                    component: 'content-product-suggestion'
                },
                {
                    key: 'promotion-slider',
                    text: 'Promotion Slider',
                    component: 'content-promotion-slider'
                },
                {
                    key: 'reorder',
                    text: 'Reorder',
                    component: 'content-reorder'
                },
                {
                    key: 'reviews',
                    text: 'Reviews',
                    component: 'content-reviews'
                },
                {
                    key: 'shop',
                    text: 'Shop',
                    component: 'content-shop'
                },
                {
                    key: 'terms-and-conditions',
                    text: 'Terms and Conditions',
                    component: 'content-terms-and-conditions'
                },
                {
                    key: 'timer',
                    text: 'Timer',
                    component: 'content-timer'
                },
                {
                    key: 'thank-you',
                    text: 'Thank You',
                    component: 'content-thank-you'
                },
                {
                    key: 'version-alert',
                    text: 'Version Alert',
                    component: 'content-version-alert'
                },
                {
                    key: 'versions',
                    text: 'Versions',
                    component: 'content-versions'
                },
                {
                    key: 'tutorial-page',
                    text: 'Tutorial Page',
                    component: 'content-tutorial-page'
                },
                {
                    key: 'upsell',
                    text: 'Upsell',
                    component: 'content-upsell'
                },
                {
                    key: 'walking-with-a-smile',
                    text: 'Walking With a Smile',
                    component: 'content-walking-with-a-smile'
                },
                {
                    key: 'why-upstep',
                    text: 'Why Upstep',
                    component: 'content-why-upstep'
                },
                {
                    key: 'mobile-3d-fluent-flow',
                    text: 'Mobile 3D Fluent Flow',
                    component: 'content-mobile-fluent-flow'
                },
                {
                    key: 'mobile-3d-guidance',
                    text: 'Mobile 3D Guidance',
                    component: 'content-mobile-guidance'
                },
                {
                    key: 'mobile-3d-login',
                    text: 'Mobile 3D Login',
                    component: 'content-mobile-login'
                },
                {
                    key: 'mobile-3d-manually-flow',
                    text: 'Mobile 3D Manually Flow',
                    component: 'content-mobile-manually-flow'
                },
                {
                    key: 'mobile-3d-photo-stage',
                    text: 'Mobile 3D Photo Stage',
                    component: 'content-mobile-photo-stage'
                },
                {
                    key: 'mobile-3d-preview',
                    text: 'Mobile 3D Preview',
                    component: 'content-mobile-preview'
                },
                {
                    key: 'mobile-3d-scanning-tips',
                    text: 'Mobile 3D Scanning Tips',
                    component: 'content-mobile-scanning-tips'
                },
                {
                    key: 'mobile-3d-scans-scale',
                    text: 'Mobile 3D Scans Scale',
                    component: 'content-mobile-scans-scale'
                },
                {
                    key: 'mobile-3d-success',
                    text: 'Mobile 3D Success',
                    component: 'content-mobile-success'
                },
                {
                    key: 'mobile-3d-voice-over',
                    text: 'Mobile 3D Voice Over',
                    component: 'content-mobile-voice-over'
                }
            ]
        };
    },

    mounted() {
        const currentKey = this.$route.params.key;
        const currentItem = this.contentsItems.find(
            item => item.key === currentKey
        );

        this.currentContentComponent = currentItem.component;
        this.title = currentItem.text;

        this.items = [...this.items, { text: currentItem.text, active: true }];
    }
};
</script>
