<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Guidance
            </h5>
            <div class="form-group col-12">
                <label for="contentTitle">Title</label>
                <textarea
                    id="contentTitle"
                    v-model="content.value.title"
                    :class="{ 'is-invalid': $v.content.value.title.$error }"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="contentSubtitle">Subtitle</label>
                <textarea
                    id="contentSubtitle"
                    v-model="content.value.subtitle"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="col-12">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Title' and 'Subtitle' you can use variables. There are predefined variables for first name -
                    <b>{Name.firstName}</b> and last name - <b>{Name.lastName}</b>.
                </div>
            </div>
            <div class="form-group col-12">
                <label>
                    Button Text
                </label>
                <input
                    v-model="content.value.buttonText"
                    type="text"
                    class="form-control"
                />
            </div>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Video
            </h5>
            <div class="col-12 px-0 mt-n2">
                <video-content v-model="content.value.video" />
            </div>
            <div class="form-group col-12 mt-1">
                <b-form-checkbox
                    v-model="content.value.videoAutoplayEnabled"
                    switch
                >
                    Autoplay Enabled
                </b-form-checkbox>
            </div>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Pre Foot Detection
            </h5>
            <div class="form-group col-12">
                <label for="preDetectionText">Text</label>
                <textarea
                    id="preDetectionText"
                    v-model="content.value.preDetectionText"
                    :class="{ 'is-invalid': $v.content.value.preDetectionText.$error }"
                    class="form-control"
                    rows="2"
                />
            </div>
            <media-chooser
                v-model="content.value.preDetectionImage"
                label="Image"
                class="col-12"
            />
            <div class="form-group col-12 mt-1">
                <b-form-checkbox
                    v-model="content.value.preDetectionButtonEnabled"
                    switch
                >
                    CTA Button Enabled
                </b-form-checkbox>
            </div>
            <div
                v-if="!content.value.preDetectionButtonEnabled"
                class="form-group col-12"
            >
                <label>
                    Push next screen delay (seconds)
                </label>
                <input
                    v-model.number="content.value.pushNextScreenDelay"
                    type="number"
                    class="form-control"
                />
            </div>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Foot Detection
            </h5>
            <div class="form-group col-12">
                <label for="leftFootTitle">Left Foot Title</label>
                <textarea
                    id="leftFootTitle"
                    v-model="content.value.leftFootTitle"
                    :class="{ 'is-invalid': $v.content.value.leftFootTitle.$error }"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="rightFootTitle">Right Foot Title</label>
                <textarea
                    id="rightFootTitle"
                    v-model="content.value.rightFootTitle"
                    :class="{ 'is-invalid': $v.content.value.rightFootTitle.$error }"
                    class="form-control"
                    rows="2"
                />
            </div>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Time Delay
            </h5>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.counterEnabled"
                    switch
                >
                    Show Counter
                </b-form-checkbox>
            </div>
            <div class="form-group col-4">
                <label>
                    Counter delay (seconds)
                </label>
                <input
                    v-model.number="content.value.counterDelay"
                    type="number"
                    class="form-control"
                />
            </div>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Auto Capture
            </h5>
            <div class="col-12 ml-1 mt-1">
                <div
                    v-for="(autoCaptureMode, index) in autoCaptureModeOptions"
                    :key="index"
                    class="radio radio-primary"
                >
                    <input
                        :id="autoCaptureMode"
                        v-model="content.value.autoCaptureMode"
                        type="radio"
                        name="customRadio"
                        :value="autoCaptureMode"
                    />
                    <label :for="autoCaptureMode">
                        {{ autoCaptureMode | startCase }}
                    </label>
                </div>
            </div>
            <div
                v-if="content.value.autoCaptureMode === 'video'"
                class="form-group col-4 mt-2"
            >
                <label>
                    Video length (seconds)
                </label>
                <input
                    v-model.number="content.value.autoCaptureVideoLength"
                    type="number"
                    class="form-control"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ContentMixin from '../content.mixin.js';
import MediaChooser from '@components/media/MediaChooser';
import VideoContent from '@components/elements/contents-builder-elements/Video';

export default {
    components: {
        MediaChooser,
        VideoContent
    },

    mixins: [ContentMixin],

    data() {
        return {
            autoCaptureModeOptions: [
                'image',
                'video'
            ]
        }
    },

    validations: {
        content: {
            value: {
                title: {
                    required
                },
                leftFootTitle: {
                    required
                },
                rightFootTitle: {
                    required
                },
                preDetectionText: {
                    required
                }
            }
        }
    },

    created() {
        this.contentKey = 'mobile-3d-guidance';
        this.contentName = 'Mobile 3D Guidance';
    }
};
</script>
