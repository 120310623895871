<template>
    <b-modal
        id="modalElementCreateEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="xl"
        centered
        @show="openModalHandler"
        @hidden="hideModal"
    >
        <template slot="modal-title">
            {{ modalTitle }}
        </template>
        <div class="custom-modal-text text-left row">
            <div class="col-md-6 form-group">
                <label for="type">Type</label>
                <b-form-select v-model="data.type" :options="typeOptions" />
            </div>
            <div class="col-md-6 form-group">
                <label for="customerName">Customer Name</label>
                <input
                    id="customerName"
                    v-model="data.customerName"
                    class="form-control"
                    :class="{
                        'is-invalid': $v.data.customerName.$error
                    }"
                    type="text"
                />
            </div>
            <div class="col-12 form-group">
                <label for="title">Title</label>
                <textarea
                    id="title"
                    v-model="data.title"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.data.title.$error }"
                />
            </div>
            <div class="col-md-6">
                <div v-if="data.forEveryday" class="form-group">
                    <label for="everyDayText">For Everyday Button Text</label>
                    <input
                        id="everyDayText"
                        v-model="data.forEveryday.text"
                        type="text"
                        class="form-control"
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div v-if="data.forEveryday" class="form-group">
                    <label for="everyDayLink">For Everyday Button Link</label>
                    <input
                        id="everyDayLink"
                        v-model="data.forEveryday.link"
                        type="text"
                        class="form-control"
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div v-if="data.forSport" class="form-group">
                    <label for="forSportText">For Sport Button Text</label>
                    <input
                        id="forSportText"
                        v-model="data.forSport.text"
                        type="text"
                        class="form-control"
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div v-if="data.forSport" class="form-group">
                    <label for="forSportLink">For Sport Button Link</label>
                    <input
                        id="forSportLink"
                        v-model="data.forSport.link"
                        type="text"
                        class="form-control"
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div v-if="isHomepage && data.singleButton" class="form-group">
                    <label for="singleButtonText">Single Button Text</label>
                    <input
                        id="singleButtonText"
                        v-model="data.singleButton.text"
                        type="text"
                        class="form-control"
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div v-if="isHomepage && data.singleButton" class="form-group">
                    <label for="singleButtonLink">Single Button Link</label>
                    <input
                        id="singleButtonLink"
                        v-model="data.singleButton.link"
                        type="text"
                        class="form-control"
                    />
                </div>
            </div>
            <div v-if="data.type === 'video'" class="form-group col-12">
                <label for="videoLink">Vimeo Video link</label>
                <input
                    id="videoLink"
                    v-model="data.source"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': $v.data.source.$error
                    }"
                />
            </div>
            <div v-if="data.description" class="form-group col-md-6">
                <label>Description</label>
                <wysiwyg-editor :key="data.order" v-model="data.description" />
            </div>
            <div class="form-group col-md-6">
                <label>Thumbnail</label>
                <media-chooser
                    v-if="data.type === 'picture'"
                    :key="`${data.type}_${data.customerName}`"
                    v-model="data.source"
                    :error-border="$v.data.source.$error"
                    style="margin: 0 !important"
                />
                <media-chooser
                    v-else
                    :key="`${data.type}_${data.customerName}`"
                    v-model="data.thumbnail"
                    :error-border="$v.data.thumbnail.$error"
                    style="margin: 0 !important"
                />
            </div>
            <div class="form-group col-6">
                <b-form-checkbox v-model="data.verified" switch>
                    Verified
                </b-form-checkbox>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light ml-1"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="submitElementDataHandler"
                >
                    {{ buttonText }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import MediaChooser from '@components/media/MediaChooser';
import WysiwygEditor from '@components/elements/WysiwygEditor';

export default {
    validations: {
        data: {
            title: {
                maxLength: maxLength(300)
            },
            customerName: {
                required
            },
            source: {
                required: (value, obj) => {
                    if (obj.type === 'video') {
                        return !!value;
                    }

                    return !!(value && value.id);
                }
            },
            thumbnail: {
                required: (value, obj) => {
                    if (obj.type !== 'video') {
                        return true;
                    }

                    return !!(value && value.id);
                }
            }
        }
    },

    components: {
        WysiwygEditor,
        MediaChooser
    },

    props: {
        updatingElement: {
            type: Boolean,
            required: false,
            default: true
        },
        element: {
            type: Object,
            required: false,
            default: null
        },
        modalTitle: {
            type: String,
            required: false,
            default: 'Add New Customer'
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Save'
        },
        updatingIndex: {
            type: Number,
            required: false,
            default: null
        },
        isHomepage: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            data: {},
            typeOptions: [
                { value: 'picture', text: 'Picture' },
                { value: 'video', text: 'Video' }
            ]
        };
    },

    methods: {
        hideModal() {
            this.data.description = null;
            this.$bvModal.hide('modalElementCreateEdit');
            this.$emit('hidden');
        },

        async openModalHandler() {
            await this.$nextTick();

            if (this.element && this.updatingElement) {
                this.data = { ...this.element };

                if (!this.data.source && !this.data.thumbnail) {
                    return;
                }

                let property = 'thumbnail';

                if (this.data.type === 'picture') {
                    property = 'source';
                }

                this.data.description = this.element.description;

                return;
            }

            this.data = {
                type: 'picture',
                forEveryday: {},
                forSport: {},
                description: '<div><br></div>',
                verified: false
            };

            if (this.isHomepage) {
                this.data.singleButton = {};
            }
        },

        async submitElementDataHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            if (this.updatingElement) {
                await this.$emit(
                    'updateElement',
                    { ...this.data },
                    this.updatingIndex
                );
            } else {
                await this.$emit('createElement', { ...this.data });
            }

            this.hideModal();
            this.$v.$reset();
        }
    }
};
</script>
