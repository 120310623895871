<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row col-12">
            <h5 class="col-12 d-flex align-items-center justify-content-between text-uppercase bg-light p-2">
                Order history
            </h5>
            <div class="col-12 mt-2">
                <b-form-checkbox v-model="content.value.otpLoginEnabled" switch>
                    OTP Login Enabled
                </b-form-checkbox>
            </div>
            <div class="col-12 mt-2">
                <b-form-checkbox v-model="content.value.questionnaireButtonEnabled" switch>
                    Show reorder questionnaire button
                </b-form-checkbox>
            </div>
            <div class="mt-3 form-group col-12">
                <wysiwyg-editor
                    v-model="content.value.orderPromotionText"
                    label="Order Promotion Text"
                />
            </div>
        </div>

        <div v-show="false" class="row">
            <div v-for="(item, index) in content.value.benefits" :key="index" class="col-6">
                <h5
                    class="col-12 d-flex align-items-center justify-content-between text-uppercase bg-light p-2"
                >
                    <div> Benfit {{ index + 1 }} </div>
                    <button
                        class="btn btn-danger waves-effect waves-light"
                        type="button"
                        @click="deleteItem(index)"
                    >
                        <i class="far fa-trash-alt" />
                    </button>
                </h5>
                <media-chooser
                    v-model="item.image"
                    label="Image"
                    class="col-12"
                />
                <div class="form-group col-12">
                    <label :for="`changeText${index}`">Title</label>
                    <textarea
                        :id="`changeText${index}`"
                        v-model="item.title"
                        class="form-control"
                        rows="1"
                    />
                </div>
                <div class="form-group col-12">
                    <label>Description</label>
                    <textarea
                        :id="`changeText${index}`"
                        v-model="item.text"
                        class="form-control"
                        rows="2"
                    />
                </div>
            </div>

            <div class="col-12 mt-3">
                <button
                    class="btn btn-info waves-effect waves-light"
                    type="button"
                    @click="addItem"
                >
                    <i class="fas fa-plus" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import MediaChooser from '@components/media/MediaChooser';
import ContentMixin from './content.mixin.js';

export default {
    components: {
        WysiwygEditor,
        MediaChooser
    },

    mixins: [ContentMixin],

    validations: {
        content: {
            value: {
                benefits: {
                    required
                }
            }
        }
    },

    created() {
        this.contentKey = 'reorder';
        this.contentName = 'Reorder';
    },

    methods: {
        addItem() {
            this.content.value.benefits = [
                ...this.content.value.benefits,
                {
                    title: '',
                    text: '',
                    image: {}
                }
            ];
        },

        deleteItem(index) {
            this.content.value.benefits.splice(index, 1);
        }
    }
};
</script>
