<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div
            v-show="activeTab === 'product'"
            class="row"
        >
            <media-chooser
                v-model="content.value.cartImage"
                label="Cart Image"
                class="col-12"
            />
            <div class="form-group col-12">
                <label>Description Title</label>
                <textarea
                    v-model="content.value.descriptionTitle"
                    class="form-control"
                    rows="1"
                />
            </div>
            <div class="form-group col-12">
                <label>Description</label>
                <textarea
                    v-model="content.value.description"
                    class="form-control"
                    rows="3"
                />
            </div>
            <div class="form-group col-6">
                <label>
                    Price
                    <span class="text-danger">*</span>
                </label>
                <currency-input
                    v-model.number="content.value.price"
                    type="text"
                    class="form-control"
                    placeholder="Enter amount"
                />
            </div>

            <div class="form-group col-6">
                <label>Sale Price</label>
                <currency-input
                    v-model.number="content.value.salePrice"
                    class="form-control"
                    placeholder="Enter amount"
                />
            </div>
        </div>
        <div 
            v-show="activeTab === 'info'"
            class="row"
        >
            <media-chooser
                v-model="content.value.image"
                label="Image"
                class="col-12"
            />
            <div class="form-group col-12">
                <label>Title</label>
                <wysiwyg-editor v-model="content.value.title" />
            </div>
            <div class="col-6 form-group">
                <label>Product Page Button Text</label>
                <input
                    v-model="content.value.buttonTextProductPage"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-6 form-group">
                <label>Informations Page Button Text</label>
                <input
                    v-model="content.value.buttonText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label>Product Page Subtitle</label>
                <input
                    v-model="content.value.subtitleProductPage"
                    type="text"
                    class="form-control"
                />
            </div>
        </div>
        <div
            v-show="activeTab === 'info'"
            class="row"
        >
            <div
                v-for="(item, index) in content.value.benefits"
                :key="index"
                class="col-4"
            >
                <h5
                    class="col-12 d-flex align-items-center justify-content-between text-uppercase bg-light p-2"
                >
                    <div> Benfit {{ index + 1 }} </div>
                    <button
                        class="btn btn-danger waves-effect waves-light"
                        type="button"
                        @click="deleteItem(index)"
                    >
                        <i class="far fa-trash-alt" />
                    </button>
                </h5>
                <media-chooser
                    v-model="item.image"
                    label="Image"
                    class="col-12"
                />
                <div class="form-group col-12">
                    <label :for="`changeText${index}`">Title</label>
                    <textarea
                        :id="`changeText${index}`"
                        v-model="item.title"
                        class="form-control"
                        rows="1"
                    />
                </div>
                <div class="form-group col-12">
                    <label>Description</label>
                    <textarea
                        :id="`changeText${index}`"
                        v-model="item.text"
                        class="form-control"
                        rows="3"
                    />
                </div>
            </div>

            <div class="col-12 mt-3">
                <button
                    class="btn btn-info waves-effect waves-light"
                    type="button"
                    @click="addItem"
                >
                    <i class="fas fa-plus" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ContentMixin from './content.mixin.js';
import ContentTabs from '@components/contents/ContentTabs';
import CurrencyInput from '@components/elements/CurrencyInput';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        ContentTabs,
        CurrencyInput,
        WysiwygEditor,
        MediaChooser
    },

    mixins: [ContentMixin],

    data() {
        return {
            tabs: [
                {
                    value: 'product',
                    text: 'Product'
                },
                {
                    value: 'info',
                    text: 'Informations'
                }
            ],
            activeTab: 'product'
        };
    },

    created() {
        this.contentKey = 'impression-kit';
        this.contentName = 'Impression Kit';
    },

    methods: {
        addItem() {
            this.content.value.benefits = [
                ...this.content.value.benefits,
                {
                    title: '',
                    text: '',
                    image: {}
                }
            ];
        },

        deleteItem(index) {
            this.content.value.benefits.splice(index, 1);
        },

        afterDataFetch() {
            this.content.value.price /= 100;
            this.content.value.salePrice /= 100;
        },

        beforeSave() {
            this.content.value.price *= 100;
            this.content.value.salePrice *= 100;
        },

        afterSave() {
            this.content.value.price /= 100;
            this.content.value.salePrice /= 100;
        }
    }
};
</script>
