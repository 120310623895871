<template>
    <div>
        <div class="form-group">
            <button
                class="btn btn-info waves-effect waves-light"
                @click="$bvModal.show('createCategoryModal')"
            >
                <i class="fas fa-plus-circle" />
                Add category
            </button>
            <button
                v-if="activeShopCategoryId"
                type="button"
                class="btn btn-success waves-effect waves-light ml-2"
                @click="onUpdateShopCategory"
            >
                <i class="fas fa-save" />
                Save
            </button>
        </div>

        <div class="row">
            <ul class="nav nav-pills navtab-bg nav-justified my-2 col-12">
                <li class="nav-item">
                    <a
                        href="#"
                        data-toggle="tab"
                        aria-expanded="false"
                        class="nav-link"
                        :class="{
                            active: !activeShopCategoryId
                        }"
                        @click.prevent="activeShopCategoryId = null"
                    >
                        Terminal
                    </a>
                </li>
                <li
                    v-for="shopCategory in shopCategories"
                    :key="shopCategory.id"
                    class="nav-item"
                >
                    <a
                        href="#"
                        data-toggle="tab"
                        aria-expanded="false"
                        class="nav-link"
                        :class="{
                            active:
                                activeShopCategoryId &&
                                activeShopCategoryId === shopCategory.id
                        }"
                        @click.prevent="activeShopCategoryId = shopCategory.id"
                    >
                        {{ shopCategory.name }}
                    </a>
                </li>
            </ul>
        </div>

        <div
            v-show="!activeShopCategoryId"
            class="row"
        >
            <div class="col-12">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Categories
                </h5>
                <draggable-list
                    :key="shopCategories.length"
                    :items="shopCategories"
                    @element-deleted="onDeleteShopCategory"
                    @element-moved="onMovedShopCategory"
                >
                    <span
                        slot-scope="row"
                    >
                        <span>
                            {{ row.element.name }}
                        </span>
                    </span>
                </draggable-list>
            </div>
        </div>

        <div
            v-for="shopCategory in shopCategories"
            :key="shopCategory.id"
        >
            <div 
                v-show="activeShopCategoryId === shopCategory.id"
                class="row col-12 my-2"
            >
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Terminal
                </h5>
                <media-chooser
                    v-model="shopCategory.terminal_image"
                    add-buff
                    label="Image"
                    class="col-12"
                    recommended-size="570x620"
                />
                <div class="form-group col-12">
                    <label>
                        Title
                    </label>
                    <input
                        v-model="shopCategory.terminal_title"
                        type="text"
                        class="form-control"
                    />
                </div>
                <div class="form-group col-12">
                    <label>
                        Description
                    </label>
                    <input
                        v-model="shopCategory.terminal_description"
                        type="text"
                        class="form-control"
                    />
                </div>
                <div class="form-group col-12">
                    <label>
                        Button Text
                    </label>
                    <input
                        v-model="shopCategory.terminal_button_text"
                        type="text"
                        class="form-control"
                    />
                </div>
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Banner
                </h5>
                <media-chooser
                    v-model="shopCategory.image"
                    label="Background Image"
                    class="col-6"
                />
                <media-chooser
                    v-model="shopCategory.mobile_image"
                    label="Background Image Mobile"
                    class="col-6"
                />
                <div class="form-group col-12">
                    <label>Title</label>
                    <input
                        v-model="shopCategory.title"
                        class="form-control"
                    />
                </div>
                <div class="form-group col-12">
                    <label>Description</label>
                    <wysiwyg-editor v-model="shopCategory.description" />
                </div>

                <h5 class="col-12 text-uppercase bg-light p-2">
                    Benefits
                </h5>
                <div class="form-group col-12 my-2">
                    <b-form-checkbox v-model="shopCategory.benefits_enabled" switch>
                        Show Benefits
                    </b-form-checkbox>
                </div>
                <div
                    v-for="(item, index) in shopCategory.benefits"
                    :key="index"
                    class="col-4"
                >
                    <media-chooser
                        v-model="item.image"
                        label="Image"
                        class="col-12"
                    />
                    <div class="form-group col-12">
                        <label :for="`benefit_title_${index}`">Title</label>
                        <textarea
                            :id="`benefit_title_${index}`"
                            v-model="item.title"
                            class="form-control"
                            rows="2"
                        />
                    </div>
                    <div class="form-group col-12">
                        <label :for="`benefit_description_${index}`">Description</label>
                        <textarea
                            :id="`benefit_description_${index}`"
                            v-model="item.description"
                            class="form-control"
                            rows="2"
                        />
                    </div>
                </div>

                <h5 class="col-12 text-uppercase bg-light p-2">
                    Bottom Banner
                </h5>
                <div class="form-group col-12 mt-2">
                    <b-form-checkbox
                        v-model="shopCategory.bottom_banner.enabled"
                        name="sticky"
                        switch
                    >
                        Enabled
                    </b-form-checkbox>
                </div>
                <div class="form-group col-12">
                    <label>
                        Title
                    </label>
                    <input
                        v-model="shopCategory.bottom_banner.title"
                        type="text"
                        class="form-control"
                    />
                </div>
                <div class="form-group col-6">
                    <label>
                        Button Text
                    </label>
                    <input
                        v-model="shopCategory.bottom_banner.buttonText"
                        type="text"
                        class="form-control"
                    />
                </div>
                <div class="form-group col-6">
                    <label>
                        Button Link
                    </label>
                    <input
                        v-model="shopCategory.bottom_banner.buttonLink"
                        type="text"
                        class="form-control"
                    />
                </div>

                <h5 class="col-12 text-uppercase bg-light p-2">
                    Tooltip Modal
                </h5>
                <div class="form-group col-12">
                    <label>
                        Button Text
                    </label>
                    <input
                        v-model="shopCategory.modal_button_text"
                        type="text"
                        class="form-control"
                    />
                </div>
            </div>
        </div>

        <div
            v-show="activeShopCategoryId"
            class="row"
        >
            <div v-if="products.length" class="col-12">
                <h5 class="text-uppercase bg-light p-2">
                    Product List
                </h5>

                <div class="d-flex flex-wrap mt-4">
                    <div class="form-group col-6">
                        <b-form-select
                            v-model="selectedProduct"
                            :options="productOptions"
                        />
                    </div>

                    <div class="form-group col-6 d-flex align-items-stretch">
                        <button
                            type="button"
                            class="mx-2 btn btn-sm btn-success waves-effect waves-light add-button"
                            @click="onAddShopProduct('product')"
                        >
                            <i class="mdi mdi-plus" />
                            Add Product
                        </button>
                    </div>
                </div>

                <div class="d-flex flex-wrap mt-4">
                    <div class="form-group col-6">
                        <b-form-select
                            v-model="selectedSecondaryProduct"
                            :options="secondaryProductOptions"
                        />
                    </div>

                    <div class="form-group col-6 d-flex align-items-stretch">
                        <button
                            type="button"
                            class="mx-2 btn btn-sm btn-success waves-effect waves-light add-button"
                            @click="onAddShopProduct('secondaryProduct')"
                        >
                            <i class="mdi mdi-plus" />
                            Add Other Product
                        </button>
                    </div>
                </div>

                <div class="d-flex flex-wrap mt-4">
                    <div class="form-group col-6">
                        <b-form-select
                            v-model="selectedBundle"
                            :options="bundleOptions"
                        />
                    </div>

                    <div class="form-group col-6 d-flex align-items-stretch">
                        <button
                            type="button"
                            class="mx-2 btn btn-sm btn-success waves-effect waves-light add-button"
                            @click="onAddShopProduct('bundle')"
                        >
                            <i class="mdi mdi-plus" />
                            Add Bundle
                        </button>
                    </div>
                </div>

                <div class="d-flex flex-wrap mt-4">
                    <div class="form-group col-6">
                        <b-form-select
                            v-model="selectedCustomItem"
                            :options="customItemOptions"
                        />
                    </div>

                    <div class="form-group col-6 d-flex align-items-stretch">
                        <button
                            type="button"
                            class="mx-2 btn btn-sm btn-success waves-effect waves-light add-button"
                            @click="onAddShopProduct('customItem')"
                        >
                            <i class="mdi mdi-plus" />
                            Add Custom Item
                        </button>
                    </div>
                </div>

                <div class="d-flex flex-wrap">
                    <media-chooser
                        v-model="selectedImage"
                        class="col-6"
                    />
                    <div class="form-group col-6 d-flex align-items-center">
                        <button
                            type="button"
                            class="mx-2 btn btn-sm btn-success waves-effect waves-light add-button"
                            @click="onAddShopProduct('image')"
                        >
                            <i class="mdi mdi-plus" />
                            Add Image
                        </button>
                    </div>
                </div>

                <draggable-list
                    :key="shopProducts.length"
                    :items="shopProducts"
                    @element-deleted="onDeleteShopProduct"
                    @element-moved="onMovedShopProduct"
                >
                    <span
                        v-if="row.element.product"
                        slot-scope="row"
                    >
                        <span>
                            {{ row.element.product.shop_name }}
                        </span>
                        <span style="color: grey">
                            - {{ row.element.product.name }}
                        </span>
                    </span>
                    <span
                        v-else-if="row.element.secondaryProduct"
                        slot-scope="row"
                    >
                        <span>
                            {{ row.element.secondaryProduct.shop_name }}
                        </span>
                        <span style="color: grey">
                            - {{ row.element.secondaryProduct.name }}
                        </span>
                    </span>
                    <span
                        v-else-if="row.element.bundle"
                        slot-scope="row"
                    >
                        <span>
                            {{ row.element.bundle.shop_name }}
                        </span>
                        <span style="color: grey">
                            - {{ row.element.bundle.name }}
                        </span>
                    </span>
                    <div v-else-if="row.element.custom_item">
                        <span class="text-primary">
                            {{ formatCustomItemName(row.element.custom_item) }}
                        </span>
                    </div>
                    <div
                        v-else
                        v-media-url="row.element.image"
                    />
                </draggable-list>
            </div>
        </div>

        <create-category-modal
            @submit="onCreateShopCategory"
        />
    </div>
</template>

<script>
import startCase from 'lodash/startCase';
import { mapActions } from 'vuex';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import DraggableList from '@components/elements/DraggableList';
import MediaChooser from '@components/media/MediaChooser';
import CreateCategoryModal from './shop/CreateCategoryModal.vue';

export default {
    components: {
        WysiwygEditor,
        DraggableList,
        MediaChooser,
        CreateCategoryModal
    },

    data() {
        return {
            activeShopCategoryId: null,
            products: [],
            secondaryProducts: [],
            bundles: [],
            selectedProduct: {},
            selectedSecondaryProduct: {},
            selectedBundle: {},
            shopCategories: [],
            shopProducts: [],
            selectedImage: {},
            selectedCustomItem: null,
            customItemOptions: [
                {
                    text: 'Klarna Promotion Box',
                    value: 'klarna-promotion-box'
                }
            ]
        };
    },

    computed: {
        productOptions() {
            return this.products.map(product => {
                return {
                    text: product.name,
                    value: product
                }
            })
        },

        secondaryProductOptions() {
            return this.secondaryProducts.map(product => {
                return {
                    text: product.name,
                    value: product
                }
            })
        },

        bundleOptions() {
            return this.bundles.map(bundle => {
                return {
                    text: bundle.name,
                    value: bundle
                }
            })
        }
    },

    watch: {
        activeShopCategoryId() {
            if (this.activeShopCategoryId) {
                this.selectedProduct = {};
                this.shopProducts = [];

                this.fetchShopProducts();
            }
        }
    },

    async mounted() {
        await this.fetchShopCategories();
        await this.fetchProducts();
        await this.fetchSecondaryProducts();
        await this.fetchBundles();
    },

    methods: {
        ...mapActions({
            getShopCategories: 'shopCategories/index',
            getShopCategory: 'shopCategories/show',
            createShopCategory: 'shopCategories/store',
            updateShopCategory: 'shopCategories/update',
            deleteShopCategory: 'shopCategories/delete',
            changeShopCategoriesOrder: 'shopCategories/changeOrder',
            getShopProducts: 'shopProducts/getByCategory',
            createShopProduct: 'shopProducts/store',
            deleteShopProduct: 'shopProducts/delete',
            changeShopProductsOrder: 'shopProducts/changeOrder',
            getProducts: 'products/index',
            getSecondaryProducts: 'secondaryProducts/index',
            getBundles: 'bundles/index'
        }),

        async fetchShopCategories() {
            try  {
                this.shopCategories = await this.getShopCategories();
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async fetchShopProducts() {
            try {
                this.shopProducts = await this.getShopProducts(this.activeShopCategoryId);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async fetchProducts() {
            try {
                const { rows } = await this.getProducts();

                this.products = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async fetchSecondaryProducts() {
            try {
                const { rows } = await this.getSecondaryProducts();

                this.secondaryProducts = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async fetchBundles() {
            try {
                const { rows } = await this.getBundles();

                this.bundles = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onCreateShopCategory(data) {
            try {
                const shopCategory = await this.createShopCategory(data);

                this.shopCategories.push(shopCategory);

                this.$toaster('Category has been added!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onUpdateShopCategory() {
            const shopCategory = this.shopCategories.find(shopCategory => shopCategory.id === this.activeShopCategoryId);

            try {
                await this.updateShopCategory(shopCategory);

                this.$toaster('Category has been updated!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onDeleteShopCategory(id) {
            try {
                await this.deleteShopCategory(id);

                this.shopCategories = this.shopCategories.filter(item => item.id !== id);

                this.$toaster('Category has been removed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onMovedShopCategory({ orderedList }) {
            const orderedArray = orderedList.map((item, index) => {
                return { ...item, order: index };
            });

            await this.changeShopCategoriesOrder(orderedArray);

            this.$toaster('Order has been changed!');
        },

        async onAddShopProduct(type) {
            const data = {
                shopCategoryId: this.activeShopCategoryId
            }

            if (type === 'product' && this.selectedProduct.id) {
                data.productId = this.selectedProduct.id;
            } else if (type === 'secondaryProduct' && this.selectedSecondaryProduct.id) {
                data.secondaryProductId = this.selectedSecondaryProduct.id;
            } else if (type === 'bundle' && this.selectedBundle.id) {
                data.bundleId = this.selectedBundle.id;
            } else if (type === 'customItem' && this.selectedCustomItem) {
                data.customItem = this.selectedCustomItem;
            } else if (type === 'image' && this.selectedImage.id) {
                data.image = this.selectedImage;
            } else {
                return;
            }

            try {
                const createdShopProduct = await this.createShopProduct(data);

                this.selectedProduct = {};
                this.selectedSecondaryProduct = {};
                this.selectedBundle = {};
                this.selectedCustomItem = null;
                this.selectedImage = {};

                this.shopProducts = [...this.shopProducts, createdShopProduct];

                this.$toaster('Item has been added!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async onDeleteShopProduct(id) {
            try {
                await this.deleteShopProduct(id);

                this.shopProducts = this.shopProducts.filter(item => item.id !== id);

                this.$toaster('Item has been removed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async onMovedShopProduct({ orderedList }) {
            const orderedArray = orderedList.map((item, index) => {
                return { ...item, order: index };
            });

            await this.changeShopProductsOrder(orderedArray);

            this.$toaster('Order has been changed!');
        },

        formatCustomItemName(customItem) {
            return startCase(customItem);
        }
    }
};
</script>

<style lang="scss" scoped>
.add-button {
    height: 38px;
    width: 170px;
}
</style>
