<template>
    <div
        v-if="followUsContent && followUsContent.value"
        id="settings"
        class="tab-pane"
    >
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <form @submit.prevent="onSubmitSocialsForm">
            <h5 class="col-12 text-uppercase bg-light p-2">
                <i class="mdi mdi-earth mr-1" />Social
            </h5>
            <div class="row">
                <div v-if="followUsContent.value.facebook" class="col-md-6">
                    <div class="form-group">
                        <label for="social-fa">Facebook</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i
                                        :class="{
                                            'fb_icon-color':
                                                followUsContent.value.facebook
                                                    .active
                                        }"
                                        class="custom-icon fab fa-facebook"
                                    />
                                </span>
                            </div>
                            <input
                                id="social-fa"
                                v-model="followUsContent.value.facebook.url"
                                type="text"
                                class="form-control"
                                placeholder="Url"
                            />
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <b-form-checkbox
                                        v-model="
                                            followUsContent.value.facebook
                                                .active
                                        "
                                    >
                                        Active
                                    </b-form-checkbox>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="followUsContent.value.twitter" class="col-md-6">
                    <div class="form-group">
                        <label for="social-twitter">Twitter</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i
                                        :class="{
                                            'twitter_icon-color':
                                                followUsContent.value.twitter
                                                    .active
                                        }"
                                        class="custom-icon fab fa-twitter"
                                    />
                                </span>
                            </div>
                            <input
                                id="social-twitter"
                                v-model="followUsContent.value.twitter.url"
                                type="text"
                                class="form-control"
                                placeholder="Url"
                            />
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <b-form-checkbox
                                        v-model="
                                            followUsContent.value.twitter.active
                                        "
                                    >Active
                                    </b-form-checkbox>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div v-if="followUsContent.value.instagram" class="col-md-6">
                    <div class="form-group">
                        <label for="social-insta">Instagram</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i
                                        :class="{
                                            'insta_icon-color':
                                                followUsContent.value.instagram
                                                    .active
                                        }"
                                        class="custom-icon fab fa-instagram"
                                    />
                                </span>
                            </div>
                            <input
                                id="social-insta"
                                v-model="followUsContent.value.instagram.url"
                                type="text"
                                class="form-control"
                                placeholder="Url"
                            />
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <b-form-checkbox
                                        v-model="
                                            followUsContent.value.instagram
                                                .active
                                        "
                                    >Active
                                    </b-form-checkbox>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="followUsContent.value.youtube" class="col-md-6">
                    <div class="form-group">
                        <label for="social-yt">Youtube</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i
                                        :class="{
                                            'yt_icon-color':
                                                followUsContent.value.youtube
                                                    .active
                                        }"
                                        class="custom-icon fab fa-youtube"
                                    />
                                </span>
                            </div>
                            <input
                                id="social-yt"
                                v-model="followUsContent.value.youtube.url"
                                type="text"
                                class="form-control"
                                placeholder="Url"
                            />
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <b-form-checkbox
                                        v-model="
                                            followUsContent.value.youtube.active
                                        "
                                    >Active
                                    </b-form-checkbox>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    components: { WereChangesAlert },

    data() {
        const socials = ['facebook', 'instagram', 'twitter', 'youtube'];

        return {
            initValueStringify: {},
            followUsContent: {
                key: 'follow-us',
                value: Object.fromEntries(
                    socials.map(value => [value, { url: '', active: false }])
                )
            }
        };
    },

    computed: {
        wereChanges() {
            return (
                JSON.stringify(this.followUsContent.value) !==
                this.initValueStringify
            );
        }
    },

    async mounted() {
        try {
            const response = await this.getContent({ key: 'follow-us' });

            this.followUsContent = { ...response };

            this.initValueStringify = JSON.stringify(
                this.followUsContent.value
            );
        } catch (err) {
            console.error(err);

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            getContent: 'contents/show',
            updateContent: 'contents/update'
        }),

        onSubmitSocialsForm() {
            this.save();
        },

        async save() {
            try {
                await this.updateContent({
                    key: this.followUsContent.key,
                    contentData: this.followUsContent
                });

                this.initValueStringify = JSON.stringify(
                    this.followUsContent.value
                );

                this.$toaster('Socials has been updated!');
            } catch (err) {
                console.error(err);

                let message = null;

                if (err.response.data.errors) {
                    message = err.response.data.errors.join('. ');
                }

                this.$toasterError(message);
            }
        }
    }
};
</script>

<style>
.insta_icon-color {
    background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    );
    -webkit-background-clip: text;
    /* Also define standard property for compatibility */
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.yt_icon-color {
    color: #ff0001;
}

.fb_icon-color {
    color: #3a559f;
}

.twitter_icon-color {
    color: #28aae1;
}

.custom-icon {
    transition: all 0.5s ease;
}
</style>
