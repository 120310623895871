<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div v-show="activeTab === 'general'" class="row">
            <media-chooser
                v-model="content.value.logo"
                add-buff
                label="Logo"
                class="col-12"
            />
            <div class="col-12 form-group">
                <label for="title">Title</label>
                <input
                    id="title"
                    v-model="content.value.title"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="text">Text</label>
                <wysiwyg-editor v-model="content.value.text" />
            </div>
            <media-chooser
                v-model="content.value.image"
                add-buff
                label="Image"
                class="col-12"
            />
            <div class="col-12 form-group">
                <label for="buttonText">Button Text</label>
                <input
                    id="buttonText"
                    v-model="content.value.buttonText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group mt-2">
                <b-form-checkbox
                    v-model="content.value.uploadLaterEnabled"
                    switch
                >
                    Upload Later Enabled
                </b-form-checkbox>
            </div>
        </div>

        <div v-show="activeTab === 'success'" class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Desktop
            </h5>
            <div class="col-12 form-group">
                <label for="successTitle">Title</label>
                <input
                    id="successTitle"
                    v-model="content.value.successTitle"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="successText">Text</label>
                <wysiwyg-editor v-model="content.value.successText" />
            </div>
            <media-chooser
                v-model="content.value.successImage"
                add-buff
                label="Image"
                class="col-12"
            />
            <div class="col-12 form-group">
                <label for="buttonSuccessText">Track Order Button Text</label>
                <input
                    id="buttonSuccessText"
                    v-model="content.value.successButtonText"
                    type="text"
                    class="form-control"
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Mobile
            </h5>
            <div class="col-12 form-group">
                <label for="successTitle">Title</label>
                <input
                    id="successTitle"
                    v-model="content.value.successTitleMobile"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="successText">Text</label>
                <wysiwyg-editor v-model="content.value.successTextMobile" />
            </div>
            <media-chooser
                v-model="content.value.successImageMobile"
                add-buff
                label="Image Mobile"
                class="col-12"
            />
        </div>

        <div v-show="activeTab === '3d-app'" class="row">
            <div class="col-12 form-group mt-2">
                <b-form-checkbox
                    v-model="content.value.appFlowEnabled"
                    switch
                >
                    3D App Flow Enabled
                </b-form-checkbox>
            </div>
            <div class="col-12 form-group mt-2">
                <b-form-checkbox
                    v-model="content.value.appFlowDesktopEnabled"
                    switch
                >
                    3D App Flow for Desktop Enabled
                </b-form-checkbox>
            </div>
            <div class="col-12 form-group">
                <label for="appStoreUrl">App Store Url</label>
                <input
                    id="appStoreUrl"
                    v-model="content.value.appStoreUrl"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Change status to PAID when scans were not completed after how many hours
                </label>
                <input
                    v-model.number="content.value.rejectPaidAppTimeout"
                    type="number"
                    class="form-control"
                />
            </div>

            <h5 class="col-12 text-uppercase bg-light p-2">
                Upgrade Notification Desktop
            </h5>
            <div class="col-12 form-group">
                <label for="upgradeNotificationTitle">Title</label>
                <input
                    id="upgradeNotificationTitle"
                    v-model="content.value.upgradeNotificationDesktop.title"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="upgradeNotificationText">Text</label>
                <wysiwyg-editor v-model="content.value.upgradeNotificationDesktop.text" />
            </div>

            <h5 class="col-12 text-uppercase bg-light p-2">
                Upgrade Notification Mobile
            </h5>
            <div class="col-12 form-group">
                <label for="upgradeNotificationTitle">Title</label>
                <input
                    id="upgradeNotificationTitle"
                    v-model="content.value.upgradeNotification.title"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="upgradeNotificationText">Text</label>
                <wysiwyg-editor v-model="content.value.upgradeNotification.text" />
            </div>
            <div class="col-12 form-group">
                <label for="upgradeNotificationButtonText">Button Text</label>
                <input
                    id="upgradeNotificationButtonText"
                    v-model="content.value.upgradeNotification.buttonText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <b-form-checkbox
                    v-model="content.value.upgradeNotification.videoEnabled"
                    switch
                >
                    Video Enabled
                </b-form-checkbox>
            </div>
            <div v-if="content.value.upgradeNotification.videoEnabled" class="col-12 px-0 mt-n3">
                <video-content
                    v-model="content.value.upgradeNotification.video"
                    :thumbnail-enabled="false"
                />
            </div>

            <h5 class="col-12 text-uppercase bg-light p-2">
                Upgrade Accepted
            </h5>
            <div class="col-12 form-group">
                <label for="scanCompletedTitle">Title</label>
                <input
                    id="scanCompletedTitle"
                    v-model="content.value.upgradeAccepted.title"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="scanCompletedText">Text</label>
                <wysiwyg-editor v-model="content.value.upgradeAccepted.text" />
            </div>
            <div class="col-12 form-group">
                <b-form-checkbox
                    v-model="content.value.upgradeAccepted.rejectButtonEnabled"
                    switch
                >
                    Reject Button Enabled
                </b-form-checkbox>
            </div>
            <div class="col-12 px-0 mt-n3">
                <video-content
                    v-model="content.value.upgradeAccepted.video"
                    :thumbnail-enabled="false"
                />
            </div>

            <h5 class="col-12 text-uppercase bg-light p-2">
                Foot Scan Not Completed
            </h5>
            <div class="col-12 form-group">
                <label for="scanNotCompletedTitle">Title</label>
                <input
                    id="scanNotCompletedTitle"
                    v-model="content.value.scanNotCompleted.title"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="scanNotCompletedText">Text</label>
                <wysiwyg-editor v-model="content.value.scanNotCompleted.text" />
            </div>
            <div class="col-12 form-group">
                <b-form-checkbox
                    v-model="content.value.scanNotCompleted.rejectButtonEnabled"
                    switch
                >
                    Reject Button Enabled
                </b-form-checkbox>
            </div>
            <div class="col-12 px-0 mt-n3">
                <video-content
                    v-model="content.value.scanNotCompleted.video"
                    :thumbnail-enabled="false"
                />
            </div>

            <h5 class="col-12 text-uppercase bg-light p-2">
                Foot Scan Completed
            </h5>
            <div class="col-12 form-group">
                <label for="scanCompletedTitle">Title</label>
                <input
                    id="scanCompletedTitle"
                    v-model="content.value.scanCompleted.title"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="scanCompletedText">Text</label>
                <wysiwyg-editor v-model="content.value.scanCompleted.text" />
            </div>
            <media-chooser
                v-model="content.value.scanCompleted.image"
                add-buff
                label="Image"
                class="col-12"
            />
            <div class="col-12 form-group">
                <b-form-checkbox
                    v-model="content.value.scanCompleted.trackingButtonEnabled"
                    switch
                >
                    Order Tracking Button Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <label>
                    Button Text
                </label>
                <input
                    v-model="content.value.scanCompleted.buttonText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div v-if="!content.value.scanCompleted.trackingButtonEnabled" class="form-group col-6">
                <label>
                    Button Link
                </label>
                <input
                    v-model="content.value.scanCompleted.buttonLink"
                    type="text"
                    class="form-control"
                />
            </div>

            <h5 class="col-12 text-uppercase bg-light p-2">
                Foot Scan Rejected
            </h5>
            <div class="col-12 form-group">
                <label for="scanRejectedTitle">Title</label>
                <input
                    id="scanRejectedTitle"
                    v-model="content.value.scanRejected.title"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="scanRejectedText">Text</label>
                <wysiwyg-editor v-model="content.value.scanRejected.text" />
            </div>
            <media-chooser
                v-model="content.value.scanRejected.image"
                add-buff
                label="Image"
                class="col-12"
            />
        </div>
    </form>
</template>

<script>
import ContentMixin from './content.mixin.js';
import ContentTabs from '@components/contents/ContentTabs';
import MediaChooser from '@components/media/MediaChooser';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import VideoContent from '@components/elements/contents-builder-elements/Video';

export default {
    components: {
        ContentTabs,
        MediaChooser,
        WysiwygEditor,
        VideoContent
    },

    mixins: [ContentMixin],

    data() {
        return {
            tabs: [
                {
                    value: 'general',
                    text: 'General'
                },
                {
                    value: 'success',
                    text: 'Success'
                },
                {
                    value: '3d-app',
                    text: '3D App'
                }
            ],
            activeTab: 'general'
        };
    },

    created() {
        this.contentKey = 'post-purchase';
        this.contentName = 'Post Purchase';
    }
};
</script>
