<template>
    <div v-if="headerContent.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <h5 class="text-uppercase bg-light p-2">
            General
        </h5>
        <div class="row">
            <media-chooser
                v-model="headerContent.value.primary_logo"
                add-buff
                label="Regular menu logo"
                class="col-12 form-group"
                type="image"
            />
            <div class="form-group col-12 col-md-6">
                <label for="headerPhone">Phone text</label>
                <input
                    id="headerPhone"
                    v-model="headerContent.value.phoneText"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="headerPhone">Phone</label>
                <input
                    id="headerPhone"
                    v-model="headerContent.value.phone"
                    :class="{
                        'is-invalid': $v.headerContent.value.phone.$error
                    }"
                    class="form-control"
                    @input="$v.headerContent.value.phone.$touch"
                    @blur="$v.headerContent.value.phone.$touch"
                />
                <div v-if="phoneErrors.length" class="invalid-feedback">
                    {{ phoneErrors[0] }}
                </div>
            </div>
            <div class="form-group col-12 mt-1">
                <b-form-checkbox
                    v-model="headerContent.value.phoneDisabled"
                    switch
                >
                    Hide phone options (Including footer)
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <label for="headerMail">E-mail</label>
                <input
                    id="headerMail"
                    v-model="headerContent.value.mail"
                    class="form-control"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import WereChangesAlert from '@components/alerts/WereChangesAlert';
import MediaChooser from '@components/media/MediaChooser';

export default {
    validations: {
        headerContent: {
            value: {
                phone: {
                    required
                }
            }
        }
    },

    components: {
        WereChangesAlert,
        MediaChooser
    },

    data() {
        return {
            initValueStringify: {},
            headerContent: {},
            showTopAlert: false
        };
    },

    computed: {
        phoneErrors() {
            const errors = [];

            if (!this.$v.headerContent.value.phone.$dirty) return errors;

            !this.$v.headerContent.value.phone.required &&
                errors.push('Phone is required!');

            return errors;
        },

        wereChanges: {
            get() {
                return (
                    JSON.stringify(this.headerContent.value) !==
                    this.initValueStringify
                );
            },
            set() {
                this.initValueStringify = JSON.stringify(
                    this.headerContent.value
                );
            }
        }
    },

    async mounted() {
        try {
            const response = await this.getContent({ key: 'header' });

            this.headerContent = { ...response };

            this.initValueStringify = JSON.stringify(this.headerContent.value);
        } catch (err) {
            console.error(err);

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            getContent: 'contents/show',
            updateContent: 'contents/update'
        }),

        async save() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.updateContent({
                    key: this.headerContent.key,
                    contentData: this.headerContent
                });

                this.initValueStringify = JSON.stringify(
                    this.headerContent.value
                );

                this.$toaster('Header has been updated!');
            } catch (err) {
                console.error(err);

                let message = null;

                if (err.response.data.errors) {
                    message = err.response.data.errors.join('. ');
                }

                this.$toasterError(message);
            }
        }
    }
};
</script>
