<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div class="form-group col-6">
                <label for="addressButtonText">Shipping Address Button</label>
                <input
                    id="addressButtonText"
                    v-model="content.value.addressButtonText"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-6">
                <label for="buttonText">Payment Button</label>
                <input
                    id="buttonText"
                    v-model="content.value.paymentButtonText"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-6">
                <label for="freeOrderButtonText">Free Order Button</label>
                <input
                    id="freeOrderButtonText"
                    v-model="content.value.freeOrderButtonText"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-6">
                <label for="freeInstallmentsText">Free Installments Text</label>
                <input
                    id="freeInstallmentsText"
                    v-model="content.value.freeInstallmentsText"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="col-12">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Free Installments Text' you can use variables
                    <b>{Number}</b> of installments and <b>{Value}</b>.
                </div>
            </div>

            <h5 class="col-12 text-uppercase bg-light p-2">
                SMS Newsletter
            </h5>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.newsletterEnabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.newsletterCheckboxMarked"
                    switch
                >
                    Checkbox Marked as Default
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <label for="newsletterCheckboxText">Checkbox Text</label>
                <input
                    id="newsletterCheckboxText"
                    v-model="content.value.newsletterCheckboxText"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Description
                </label>
                <wysiwyg-editor
                    v-model="content.value.newsletterDescription" 
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Coupon Field
            </h5>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.couponFieldEnabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Mini Cart
            </h5>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.miniCartEnabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.skipCart"
                    switch
                >
                    Skip Cart Page
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.miniCartOpen"
                    switch
                >
                    Open by Default (Desktop)
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.miniCartOpenMobile"
                    switch
                >
                    Open by Default (Mobile)
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.questionnaireEditingEnabled"
                    switch
                >
                    Enable option to edit questionnaire answers
                </b-form-checkbox>
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Notification Bar
            </h5>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.notificationBarEnabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <label for="notificationBarTitle">Title</label>
                <input
                    id="notificationBarTitle"
                    v-model="content.value.notificationBarTitle"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Text
                </label>
                <wysiwyg-editor
                    v-model="content.value.notificationBarText" 
                />
            </div>
            <div class="form-group col-12">
                <label>Background Color</label>
                <color-picker
                    v-model="content.value.notificationBarBackgroundColor"
                    :color="content.value.notificationBarBackgroundColor"
                />
            </div>
            <media-chooser
                v-model="content.value.notificationBarIcon"
                label="Icon"
                class="col-12"
            />
        </div>
    </form>
</template>

<script>
import ContentMixin from './content.mixin.js';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import ColorPicker from '@components/elements/ColorPicker';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        WysiwygEditor,
        ColorPicker,
        MediaChooser
    },

    mixins: [ContentMixin],

    created() {
        this.contentKey = 'checkout';
        this.contentName = 'Checkout';
    }
};
</script>
