<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div v-show="activeTab === 'general'" class="row">
            <div class="col-12 form-group">
                <label for="title">Title</label>
                <input
                    id="title"
                    v-model="content.value.title"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="orderNumberText">Text before order number</label>
                <input
                    id="orderNumberText"
                    v-model="content.value.orderNumberText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="emailText">Text before email</label>
                <input
                    id="emailText"
                    v-model="content.value.emailText"
                    type="text"
                    class="form-control"
                />
            </div>
        </div>

        <div
            v-if="activeTab === 'how-it-works'"
            :key="activeTab"
            class="row"
        >
            <h5 class="col-12 text-uppercase bg-light p-2">
                Steps
            </h5>
            <div class="form-group col-12">
                <how-it-works-steps
                    v-model="content.value.howItWorks.steps"
                    :steps="content.value.howItWorks.steps"
                    is-title
                />
            </div>
        </div>

        <div v-show="activeTab === 'post-purchase'" class="row">
            <div class="col-12 form-group">
                <label for="title">Title</label>
                <input
                    id="title"
                    v-model="content.value.postPurchase.title"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <wysiwyg-editor
                    v-model="content.value.postPurchase.text"
                    label="text"
                />
            </div>
            <div class="col-12 form-group">
                <label for="buttonText">Button text</label>
                <input
                    id="buttonText"
                    v-model="content.value.postPurchase.buttonText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.postPurchase.popupEnabled"
                    switch
                >
                    Pop-up Enabled
                </b-form-checkbox>
            </div>
            <div class="col-12 form-group">
                <label for="popupTitle">Pop-up Title</label>
                <input
                    id="popupTitle"
                    v-model="content.value.postPurchase.popupTitle"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label>Pop-up Text</label>
                <wysiwyg-editor
                    v-model="content.value.postPurchase.popupText"
                />
            </div>
            <media-chooser
                v-model="content.value.postPurchase.popupImage"
                add-buff
                label="Pop-up Image"
                class="col-12"
            />
        </div>

        <div v-show="activeTab === 'footer'" class="row">
            <div class="col-12 form-group">
                <label for="title">Title</label>
                <input
                    id="title"
                    v-model="content.value.footer.title"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="emailText">Text before email</label>
                <input
                    id="emailText"
                    v-model="content.value.footer.emailText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="email">Email address</label>
                <input
                    id="email"
                    v-model="content.value.footer.email"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="phoneText">Text before phone</label>
                <input
                    id="phoneText"
                    v-model="content.value.footer.phoneText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="phone">Phone number</label>
                <input
                    id="phone"
                    v-model="content.value.footer.phone"
                    type="text"
                    class="form-control"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ContentMixin from './content.mixin.js';
import ContentTabs from '@components/contents/ContentTabs';
import MediaChooser from '@components/media/MediaChooser';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import HowItWorksSteps from './how-it-works/HowItWorksSteps';

export default {
    components: {
        ContentTabs,
        MediaChooser,
        WysiwygEditor,
        HowItWorksSteps
    },

    mixins: [ContentMixin],

    data() {
        return {
            tabs: [
                {
                    value: 'general',
                    text: 'General'
                },
                {
                    value: 'how-it-works',
                    text: 'How It Works'
                },
                {
                    value: 'post-purchase',
                    text: 'Post Purchase Section'
                },
                {
                    value: 'footer',
                    text: 'Footer'
                }
            ],
            activeTab: 'general'
        };
    },

    created() {
        this.contentKey = 'thank-you';
        this.contentName = 'Thank you';
    }
};
</script>
