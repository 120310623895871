<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div v-show="activeTab === 'general'" class="row">
            <single-section
                v-model="content.value.doctor"
                :fields="{ image: true }"
                title="Top doctor"
            />

            <div class="col-12">
                <h5 class="text-uppercase mt-0 bg-light p-2">
                    Discount
                </h5>
            </div>
            <div class="col-12 form-group mt-2">
                <b-form-checkbox
                    v-model="content.value.showDiscountValue"
                    switch
                >
                    Show Discount Value
                </b-form-checkbox>
            </div>
            <div class="col-6 form-group">
                <label for="discountAmountTextBefore">
                    Text Before Content
                </label>
                <input
                    id="discountAmountTextBefore"
                    v-model="content.value.discountAmountTextBefore"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-6 form-group">
                <label for="discountAmountTextBeforeColor">
                    Text Before Color
                </label>
                <input
                    id="discountAmountTextBeforeColor"
                    v-model="content.value.discountAmountTextBeforeColor"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="priceColor">Price Color</label>
                <input
                    id="priceColor"
                    v-model="content.value.priceColor"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12 form-group">
                <label for="discountAmountText">Text After</label>
                <input
                    id="discountAmountText"
                    v-model="content.value.discountAmountText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-12">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Text After' you can use variable
                    <b>{Codes}</b>
                </div>
            </div>
            <div class="col-12">
                <h5 class="text-uppercase mt-0 bg-light p-2">
                    Quantity Selection
                </h5>
            </div>
            <div class="col-12 form-group mt-2 mb-3">
                <b-form-checkbox v-model="content.value.quantitySelectionEnabled" switch>
                    Show on Desktop
                </b-form-checkbox>
                <b-form-checkbox v-model="content.value.mobileQuantitySelectionEnabled" switch class="mt-2">
                    Show on Mobile
                </b-form-checkbox>
            </div>
            <div class="col-12">
                <h5 class="text-uppercase mt-0 bg-light p-2">
                    Buttons
                </h5>
            </div>
            <div class="col-6 form-group">
                <label for="text">Text</label>
                <input
                    id="text"
                    v-model="content.value.buttonText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="col-6 form-group">
                <label for="text-direct">Text (Direct link)</label>
                <input
                    id="text-direct"
                    v-model="content.value.buttonTextByDirectLink"
                    type="text"
                    class="form-control"
                />
            </div>

            <div class="col-12">
                <h5 class="text-uppercase mt-0 bg-light p-2">
                    Under the button benefit
                </h5>
            </div>
            <div class="col-12 form-group">
                <textarea
                    id="text"
                    v-model="content.value.underButtonBenefit"
                    rows="5"
                    class="form-control"
                />
            </div>

            <div class="col-12">
                <h5 class="text-uppercase mt-0 bg-light p-2">
                    Regards Payments Text
                </h5>
            </div>
            <div class="col-12 form-group mt-2">
                <b-form-checkbox
                    v-model="content.value.regardsPaymentsEnabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="col-12 form-group">
                <wysiwyg-editor
                    v-model="content.value.regardsPayments.content"
                />
                <label for="backgroundColor">Background color</label>
                <input
                    id="backgroundColor"
                    v-model="content.value.regardsPayments.backgroundColor"
                    class="form-control"
                />
                <media-chooser
                    v-model="content.value.regardsPayments.image"
                    add-buff
                    label="Image"
                />
            </div>

            <div class="col-12">
                <h5 class="text-uppercase mt-0 bg-light p-2">
                    Mobile Sticky Button
                </h5>
            </div>
            <div class="col-12 form-group mt-2 mb-3">
                <b-form-checkbox
                    v-model="content.value.mobileStickyButton"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="col-12">
                <h5 class="text-uppercase mt-0 bg-light p-2">
                    Image Benefit
                </h5>
            </div>
            <div class="col-12 form-group mt-2 mb-3">
                <b-form-checkbox
                    v-model="content.value.imageBenefitEnabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <div v-if="content.value.imageBenefitEnabled" class="col-12">
                <div class="col-12">
                    <label for="imageBenefitColor">Background Color</label>
                    <color-picker
                        id="imageBenefitColor"
                        v-model="content.value.imageBenefitColor"
                        :color="content.value.imageBenefitColor"
                    />
                </div>
                <single-section
                    v-model="content.value.imageBenefit"
                    :fields="{ content: true, image: true }"
                />
            </div>
            <div class="col-12">
                <h5 class="text-uppercase mt-0 bg-light p-2">
                    Klarna Placement
                </h5>
            </div>
            <div class="col-12 form-group mt-2 mb-3">
                <b-form-checkbox v-model="content.value.klarnaPlacement" switch>
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="col-12">
                <h5 class="text-uppercase mt-0 bg-light p-2">
                    FSA Banner
                </h5>
            </div>
            <div class="col-12 form-group mt-2 mb-3">
                <b-form-checkbox v-model="content.value.fsaBannerEnabled" switch>
                    Enabled
                </b-form-checkbox>
            </div>
        </div>

        <div v-show="activeTab === 'advantages'" class="row">
            <div class="col-12 row mt-2">
                <button
                    class="btn btn-success waves-effect waves-light ml-2"
                    type="button"
                    @click="addAdvantage"
                >
                    <i class="fas fa-plus" />
                    Add advantage
                </button>
            </div>
            <div
                v-for="(advantage, index) in content.value.advantages"
                :key="index"
                class="col-12 mt-3"
            >
                <h5 class="text-uppercase mt-0 bg-light p-2">
                    Adventage {{ index + 1 }}
                </h5>
                <div class="col-12 form-group">
                    <label for="text">Text</label>
                    <textarea
                        id="text"
                        v-model="content.value.advantages[index].content"
                        class="form-control"
                    />
                    <media-chooser
                        v-model="content.value.advantages[index].image"
                        add-buff
                        label="Image"
                    />
                </div>
                <div class="col-1 form-group d-flex align-items-center">
                    <button
                        class="btn btn-danger waves-effect waves-light"
                        type="button"
                        @click="deleteAdvantage(index)"
                    >
                        <i class="far fa-trash-alt" />
                    </button>
                </div>
            </div>
        </div>

        <div
            v-if="activeTab === 'how-it-works'"
            :key="activeTab"
            class="row"
        >
            <media-chooser
                v-model="content.value.howItWorks.backgroundImage"
                label="Background image"
                class="col-12"
            />
            <div class="form-group col-12">
                <wysiwyg-editor
                    v-model="content.value.howItWorks.title"
                    label="Title"
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Steps
            </h5>
            <div class="form-group col-12">
                <how-it-works-steps
                    v-model="content.value.howItWorks.steps"
                    :steps="content.value.howItWorks.steps"
                    is-title
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Button
            </h5>
            <div class="form-group col-12 col-md-6">
                <label for="watchVideoText">Button Text</label>
                <input
                    id="watchVideoText"
                    v-model="content.value.howItWorks.button.text"
                    class="form-control"
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Watch Video Button
            </h5>
            <div class="form-group col-12 col-md-6">
                <label for="watchVideoText">Button Text</label>
                <input
                    id="watchVideoText"
                    v-model="content.value.howItWorks.watchVideo.text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="watchVideoLink">Button Link</label>
                <input
                    id="watchVideoLink"
                    v-model="content.value.howItWorks.watchVideo.link"
                    class="form-control"
                />
            </div>
        </div>

        <div v-show="activeTab === 'video-carousel'" class="row">
            <div class="col-12 mt-2">
                <div class="col-12 form-group mt-2">
                    <b-form-checkbox v-model="content.value.videoCarousel.enabled" switch>
                        Show on Desktop
                    </b-form-checkbox>
                    <b-form-checkbox v-model="content.value.videoCarousel.mobileEnabled" switch class="mt-2">
                        Show on Mobile
                    </b-form-checkbox>
                </div>
                <div class="col-12 form-group mt-2">
                    <label for="title">Title</label>
                    <input
                        id="title"
                        v-model="content.value.videoCarousel.title"
                        type="text"
                        class="form-control"
                    />
                </div>

                <div class="d-flex flex-wrap align-items-start mb-3">
                    <div
                        v-for="(item, index) in content.value.videoCarousel.items"
                        :key="index"
                        class="col-12 col-lg-6 row m-0"
                    >
                        <h5
                            class="col-12 d-flex align-items-center justify-content-between text-uppercase bg-light p-2"
                        >
                            <div> Video {{ index + 1 }} </div>
                            <button
                                class="btn btn-danger waves-effect waves-light"
                                type="button"
                                @click="deleteVideo(index)"
                            >
                                <i class="far fa-trash-alt" />
                            </button>
                        </h5>
                        <div class="col-12 px-0 mt-n2">
                            <video-content v-model="item.video" />
                        </div>
                        <div class="form-group col-12">
                            <label :for="`thumbnailText${index}`">Thumbnail Text</label>
                            <input
                                :id="`thumbnailText${index}`"
                                v-model="item.thumbnailText"
                                class="form-control"
                                type="text"
                            />
                        </div>
                        <div class="form-group col-12">
                            <label :for="`title${index}`">Title (Mobile Only)</label>
                            <input
                                :id="`title${index}`"
                                v-model="item.title"
                                class="form-control"
                                type="text"
                            />
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <button
                            class="btn btn-info waves-effect waves-light"
                            type="button"
                            @click="addVideo"
                        >
                            <i class="fas fa-plus" />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="activeTab === 'sections'" class="row">
            <div class="col-12 mt-3">
                <h5 class="text-uppercase mt-0 bg-light p-2">
                    Social Proof Video
                </h5>
                <b-form-checkbox
                    v-model="content.value.socialProof.enabled"
                    switch
                    class="ml-2 mt-2"
                >
                    Enabled
                </b-form-checkbox>
                <div class="col-12 form-group mt-2">
                    <label for="title">Title</label>
                    <input
                        id="title"
                        v-model="content.value.socialProof.title"
                        type="text"
                        class="form-control"
                    />
                </div>
                <div class="col-12 px-0 mt-n2">
                    <video-content v-model="content.value.socialProof.video" />
                </div>
            </div>

            <div class="col-12 mt-3">
                <h5 class="text-uppercase mt-0 bg-light p-2">
                    Top Reviews
                </h5>
                <div class="col-12 form-group mt-2">
                    <label for="topReviewsTitle">Title</label>
                    <input
                        id="topReviewsTitle"
                        v-model="content.value.topReviews.title"
                        type="text"
                        class="form-control"
                    />
                </div>
                <div class="col-12 form-group">
                    <label for="topReviewsButtonText">Button Text</label>
                    <input
                        id="topReviewsButtonText"
                        v-model="content.value.topReviews.buttonText"
                        type="text"
                        class="form-control"
                    />
                </div>
            </div>
            <single-section
                v-model="content.value.materials"
                :fields="{ content: true, image: true, title: true }"
                title="Materials"
            />
            <single-section
                v-model="content.value.designed"
                :fields="{ content: true, image: true, title: true }"
                title="Designed"
            />
            <single-section
                v-model="content.value.singleContent"
                :fields="{ content: true, image: true, title: true }"
                title="Single content"
            />
        </div>

        <div v-show="activeTab === 'topImage'" class="row">
            <div class="col-12 mb-2">
                <b-form-checkbox
                    v-model="content.value.topImage.enabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <media-chooser
                v-model="content.value.topImage.small"
                add-buff
                label="Small"
                recommended-size="500x75"
                class="col-12"
            />
            <media-chooser
                v-model="content.value.topImage.medium"
                add-buff
                label="Medium"
                recommended-size="1200x75"
                class="col-12"
            />
            <media-chooser
                v-model="content.value.topImage.big"
                add-buff
                label="Big"
                recommended-size="1700x75"
                class="col-12"
            />
        </div>

        <div v-show="activeTab === 'faq'">
            <manage-faq-content content-key="product-page-faq" />
        </div>
    </div>
</template>

<script>
import SingleSection from './SingleSection';
import ContentTabs from '@components/contents/ContentTabs';
import MediaChooser from '@components/media/MediaChooser';
import ColorPicker from '@components/elements/ColorPicker';
import ContentMixin from '@views/contents/content.mixin.js';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import ManageFaqContent from '@components/contents/ManageFaqContent';
import VideoContent from '@components/elements/contents-builder-elements/Video';
import HowItWorksSteps from '../how-it-works/HowItWorksSteps';

export default {
    components: {
        ContentTabs,
        ColorPicker,
        MediaChooser,
        SingleSection,
        WysiwygEditor,
        ManageFaqContent,
        VideoContent,
        HowItWorksSteps
    },

    mixins: [ContentMixin],

    data() {
        return {
            tabs: [
                {
                    value: 'general',
                    text: 'General'
                },
                {
                    value: 'advantages',
                    text: 'Advantages'
                },
                {
                    value: 'how-it-works',
                    text: 'How It Works'
                },
                {
                    value: 'video-carousel',
                    text: 'Video Carousel'
                },
                {
                    value: 'sections',
                    text: 'Sections'
                },
                {
                    value: 'topImage',
                    text: 'Top Image'
                },
                {
                    value: 'faq',
                    text: 'FAQ'
                }
            ],
            activeTab: 'general'
        };
    },

    created() {
        this.contentKey = 'product-page-v3';
        this.contentName = 'Product page';
    },

    methods: {
        addAdvantage() {
            const { advantages } = this.content.value;

            if (![...advantages].pop()) {
                this.$toasterWarning('Please fill the previous item!');

                return;
            }

            this.content.value.advantages.push('');
        },

        deleteAdvantage(index) {
            this.content.value.advantages.splice(index, 1);
        },

        addVideo() {
            this.content.value.videoCarousel.items = [
                ...this.content.value.videoCarousel.items,
                {
                    video: {},
                    thumbnailText: ''
                }
            ]
        },

        deleteVideo(index) {
            this.content.value.videoCarousel.items.splice(index, 1);
        }
    }
};
</script>
