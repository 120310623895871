<template>
    <div class="row">
        <div
            v-for="type in ['homepageV2Mobile', 'homepageV2']"
            :key="type"
            class="col-6"
        >
            <h5 class="col-12 text-uppercase bg-light p-2">
                {{ type === 'homepageV2' ? 'Desktop' : 'Mobile' }} Banner
            </h5>
            <div class="form-gorup col-12 mb-2">
                <wysiwyg-editor
                    v-model="version[type].smallHeader"
                    label="Small header"
                />
            </div>
            <div class="form-gorup col-12 mb-2">
                <wysiwyg-editor
                    v-model="version[type].mainHeader"
                    label="Main header"
                />
            </div>
            <div class="form-gorup col-12 mb-2">
                <wysiwyg-editor
                    v-model="version[type].couponText"
                    label="Coupon text"
                />
            </div>
            <div class="form-group col-12">
                <label>Background Color</label>
                <color-picker
                    v-model="version[type].backgroundColor"
                    :color="version[type].backgroundColor"
                />
            </div>
        </div>

        <div
            v-for="type in ['homepageV2Mobile', 'homepageV2']"
            :key="`${type}_products`"
            class="col-12"
        >
            <h5 class="text-uppercase bg-light p-2">
                {{ type === 'homepageV2' ? 'Desktop' : 'Mobile' }} Banner Products
            </h5>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="version[type].showColorPicker"
                    switch
                >
                    Show Color Picker
                </b-form-checkbox>
            </div>
            <div
                v-if="version[type].showColorPicker"
                class="form-group col-12"
            >
                <b-form-checkbox
                    v-model="version[type].showColorPickerForReturning"
                    switch
                >
                    Show Only For Returning Visitors
                </b-form-checkbox>
            </div>

            <version-banner-products
                v-if="!version[type].showColorPicker"
                v-model="version[type].products"
            />

            <list-products
                v-else
                :list-name="
                    type === 'homepageV2Mobile'
                        ? 'homepage-banner-mobile'
                        : 'homepage-banner-desktop'
                "
                :fields="{
                    text: true,
                    buttonText: true
                }"
            />
        </div>
    </div>
</template>

<script>
import WysiwygEditor from '@components/elements/WysiwygEditor';
import ColorPicker from '@components/elements/ColorPicker';
import VersionBannerProducts from '@components/contents/VersionBannerProducts';
import ListProducts from '@components/contents/ListProducts';

export default {
    components: {
        WysiwygEditor,
        ColorPicker,
        VersionBannerProducts,
        ListProducts
    },

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            version: {}
        };
    },

    watch: {
        version: {
            handler() {
                this.$emit('input', this.version);
            },
            deep: true
        }
    },

    created() {
        this.version = { ...this.value};
    }
};
</script>
