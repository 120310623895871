<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div v-show="activeTab === 'mainItems'" class="row">
            <nested-draggable
                :key="dragKey"
                :items="content.value.mainItems"
                sub-items-property="subItems"
                :index="0"
                :level="1"
                :max-level="1"
                @add="addByProperty"
                @remove="removeByProperty"
                @edit="editByProperty"
            />

            <edit-modal
                v-if="itemToEdit && activeTab === 'mainItems'"
                is-main-menu
                :value="itemToEdit"
                @save-menu-item="editMenuItemByProperty"
            />
        </div>
        <div v-show="activeTab === 'bottomItems'" class="row">
            <nested-draggable
                :key="dragKey"
                :items="content.value.bottomItems"
                sub-items-property="subItems"
                :index="0"
                :level="1"
                :max-level="1"
                @add="addByProperty"
                @remove="removeByProperty"
                @edit="editByProperty"
            />

            <edit-modal
                v-if="itemToEdit && activeTab === 'bottomItems'"
                :value="itemToEdit"
                @save-menu-item="editMenuItemByProperty"
            />
        </div>
    </form>
</template>

<script>
import ContentMixin from '@views/contents/content.mixin.js';
import MenusMixin from './menus.mixin';
import ContentTabs from '@components/contents/ContentTabs';
import NestedDraggable from '@components/elements/NestedDraggable';
import EditModal from './EditModal';

export default {
    components: {
        ContentTabs,
        NestedDraggable,
        EditModal
    },

    mixins: [ContentMixin, MenusMixin],

    data() {
        return {
            tabs: [
                {
                    value: 'mainItems',
                    text: 'Categories Menu'
                },
                {
                    value: 'bottomItems',
                    text: 'Bottom Menu'
                },
            ],
            activeTab: 'mainItems'
        };
    },

    created() {
        this.contentKey = 'menu-mobile';
        this.contentName = 'Menu Mobile';
    },

    methods: {
        addByProperty() {
            const defaultItem = {
                title: '',
                link: '',
                submenuEnabled: false,
                submenu: {
                    columns: []
                }
            }

            if (this.activeTab === 'mainItems') {
                defaultItem.submenu.featuredProduct = {};
            }

            this.onAdd(this.activeTab, defaultItem)
        },

        editByProperty(indexes) {
            this.onEdit(indexes, this.activeTab);
        },

        removeByProperty(indexes) {
            this.onRemove(indexes, this.activeTab);
        },

        editMenuItemByProperty(item) {
            this.onEditMenuItem(item, this.activeTab)
        }
    }
};
</script>
