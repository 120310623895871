<template>
    <div class="row">
        <h5 class="col-12 text-uppercase bg-light p-2">
            Show on pages
        </h5>
        <div
            v-for="(pageKey, index) in pagesKeys"
            :key="index"
            class="form-group col-12"
        >
            <b-form-checkbox v-model="pages[pageKey]" switch>
                {{ getText(pageKey) }}
            </b-form-checkbox>
        </div>
    </div>
</template>

<script>
import startCase from 'lodash/startCase';

export default {
    props: {
        value: {
            type: Object,
            required: false,
            default: () => {}
        },
        pagesKeys: {
            type: Array,
            required: false,
            default: () => [
                'about',
                'articles',
                'bundles',
                'cart',
                'checkout',
                'exercises',
                'giftCard',
                'help',
                'homePage',
                'howItWorks',
                'login',
                'orderHistory',
                'orderTracking',
                'products',
                'productsQuestionnaire',
                'questionnaire',
                'questionnaireEveryday',
                'questionnaireReduced',
                'questionnaireSport',
                'reviews',
                'shop'
            ]
        }
    },

    data() {
        return {
            pages: {}
        };
    },

    watch: {
        pages: {
            handler() {
                this.$emit('input', this.pages);
            },
            deep: true
        }
    },

    created() {
        this.pages = { ...this.value };
    },

    methods: {
        getText(value) {
            return startCase(value);
        }
    }
};
</script>
