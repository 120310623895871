<template>
    <div class="my-2">
        <label v-if="label">
            {{ label }}
        </label>
        <p v-if="recommendedSize">
            <small> Recommended size: {{ recommendedSize }} </small>
        </p>
        <div
            class="dropbox"
            :class="{ 'error-border': errorBorder }"
            @click="openChooserModal"
        >
            <div v-if="isAnyMediaAdded" class="image-container">
                <div class="media-item-remove">
                    <button
                        type="button"
                        class="btn btn-success rounded"
                        @click.stop="openChooserModal"
                    >
                        {{
                            `SELECT ${type ? type.toUpperCase() : 'FILE'}${
                                multipleMode ? 'S' : ''
                            }`
                        }}
                    </button>
                    <button
                        v-if="multipleMode && media.length >= 2"
                        type="button"
                        class="btn btn-primary rounded ml-1"
                        @click.stop="openChangeOrderModal"
                    >
                        CHANGE ORDER
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger rounded ml-1"
                        @click.stop="removeMedia"
                    >
                        {{
                            `REMOVE ${type ? type.toUpperCase() : 'FILE'}${
                                multipleMode ? 'S' : ''
                            }`
                        }}
                    </button>
                </div>

                <div
                    v-for="(singleMedia, index) in media"
                    :key="`${index}_${helperKey}`"
                    class="single-image-container"
                >
                    <button
                        v-if="multipleMode"
                        type="button"
                        class="btn btn-danger rounded"
                        @click.stop="removeSingleMedia(index)"
                    >
                        <i class="far fa-trash-alt" />
                    </button>
                    <div v-media-url="singleMedia" />
                </div>
            </div>

            <span v-else>
                Choose New File
                <i class="fe-upload" />
            </span>

            <chooser-modal
                :value="value"
                :modal-id="chooserModalId"
                :type="type"
                :multiple-mode="multipleMode"
                @update="updateMedia"
            />

            <change-order-modal
                :media="media"
                :modal-id="orderModalId"
                @orderChanged="onOrderChanged"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ChooserModal from './ChooserModal';
import ChangeOrderModal from './ChangeOrderModal';

export default {
    components: {
        ChooserModal,
        ChangeOrderModal
    },

    props: {
        value: {
            type: [Array, Object, String],
            required: false,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: 'image'
        },
        errorBorder: {
            type: Boolean,
            required: false,
            default: false
        },
        idOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        multipleMode: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        recommendedSize: {
            type: String,
            default: '',
            required: false
        },
        addBuff: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    data() {
        return {
            media: [],
            helperKey: 0
        };
    },

    computed: {
        chooserModalId() {
            return (
                'mediaChooserModal_' +
                Math.random()
                    .toString(36)
                    .substring(2, 15)
                    .match(/[a-zA-Z]+/g)
                    .join('')
            );
        },

        orderModalId() {
            return (
                'mediaOrderModal_' +
                Math.random()
                    .toString(36)
                    .substring(2, 15)
                    .match(/[a-zA-Z]+/g)
                    .join('')
            );
        },

        isAnyMediaAdded() {
            if (this.multipleMode && this.value.length) {
                return true;
            }

            if (this.idOnly) {
                return this.value;
            }

            return this.value && this.value.id;
        }
    },

    watch: {
        value: {
            async handler() {
                let media = [];

                if (Array.isArray(this.value)) {
                    media = [...this.value];
                } else if (
                    (this.value && this.value.id) ||
                    (typeof this.value === 'string' && this.value)
                ) {
                    media = [this.value];
                }

                this.media = await Promise.all(
                    media.map(async item => {
                        return item.id ? item : await this.getMedia(item);
                    })
                );

                this.helperKey++;
            },
            immediate: true
        }
    },

    methods: {
        ...mapActions({
            getSingleMedia: 'media/show'
        }),

        async getMedia(id) {
            try {
                return this.getSingleMedia(id);
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        openChooserModal() {
            this.$bvModal.show(this.chooserModalId);
        },

        updateMedia(selectedMedia) {
            if (!this.multipleMode) {
                if (this.idOnly) {
                    this.$emit('input', selectedMedia[0].id);

                    return;
                }

                const {
                    user,
                    created_at,
                    updated_at,
                    user_id,
                    hide_in_management,
                    ...updatedMedia
                } = selectedMedia[0];

                if (!this.addBuff) {
                    updatedMedia.meta.buff = '';
                }

                this.$emit('input', updatedMedia);

                return;
            }

            if (this.idOnly) {
                selectedMedia = selectedMedia.map(media => media.id);

                this.$emit('input', [...selectedMedia]);

                return;
            }

            selectedMedia = selectedMedia.map(media => {
                const {
                    user,
                    created_at,
                    updated_at,
                    user_id,
                    hide_in_management,
                    ...updatedMedia
                } = media;

                if (!this.addBuff && updatedMedia.meta) {
                    updatedMedia.meta.buff = '';
                }

                return updatedMedia;
            });

            this.$emit('input', [...selectedMedia]);
        },

        removeMedia() {
            if (this.multipleMode) {
                this.$emit('input', []);

                return;
            }

            if (this.idOnly) {
                this.$emit('input', null);

                return;
            }

            this.$emit('input', {});
        },

        removeSingleMedia(index) {
            const media = this.value.filter((item, i) => index !== i);

            this.$emit('input', media);
        },

        openChangeOrderModal() {
            this.$bvModal.show(this.orderModalId);
        },

        onOrderChanged(media) {
            this.$emit('input', media);
        }
    }
};
</script>
