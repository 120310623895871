<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div class="form-group col-12">
                <label for="reviewsTitle">Title</label>
                <textarea
                    id="reviewsTitle"
                    v-model="content.value.title"
                    :class="{
                        'is-invalid': $v.content.value.title.$error
                    }"
                    class="form-control"
                    rows="2"
                    @input="$v.content.value.title.$touch"
                    @blur="$v.content.value.title.$touch"
                />
                <div v-if="titleErrors.length" class="invalid-feedback">
                    {{ titleErrors[0] }}
                </div>
            </div>
            <div class="form-group col-12">
                <label for="carouselTitle">Carousel Title</label>
                <textarea
                    id="carouselTitle"
                    v-model="content.value.carouselTitle"
                    class="form-control"
                    rows="3"
                />
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Homepage V2
            </h5>
            <div class="form-group col-12">
                <wysiwyg-editor
                    v-model="content.value.titleMobile"
                    label="Title"
                />
            </div>
            <div class="form-group col-12">
                <label>Text</label>
                <textarea
                    v-model="content.value.textMobile"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-6">
                <label for="buttonText">Button Text</label>
                <input
                    id="buttonText"
                    v-model="content.value.buttonMobile.text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-6">
                <label for="buttonLink">Button Link</label>
                <input
                    id="buttonLink"
                    v-model="content.value.buttonMobile.link"
                    class="form-control"
                />
            </div>

            <h5 class="col-12 text-uppercase bg-light p-2">
                Reviews
            </h5>

            <div class="form-group col-12 mt-2">
                <button
                    type="button"
                    class="btn btn-success"
                    @click="addItem"
                >
                    <i class="fas fa-plus mr-1" />
                    Add Review
                </button>
            </div>

            <div class="table-responsive p-1">
                <table
                    id="faq-datatable"
                    class="table table-bordered table-striped"
                >
                    <thead>
                        <tr class="text-center">
                            <th>#</th>
                            <th>Headline</th>
                            <th>Content</th>
                            <th>Customer name</th>
                            <th>Date</th>
                            <th>Image</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <draggable
                        v-model="content.value.reviews"
                        tag="tbody"
                        ghost-class="ghost"
                        @end="onMoved"
                    >
                        <tr
                            v-for="(review, index) in content.value.reviews"
                            :key="index"
                            style="cursor: move"
                        >
                            <td class="text-center align-middle">
                                {{ index + 1 }}
                            </td>
                            <td class="text-center align-middle">
                                <textarea
                                    v-model="review.title"
                                    class="form-control"
                                    rows="4"
                                    @click.stop.prevent
                                />
                            </td>
                            <td class="text-center align-middle">
                                <textarea
                                    v-model="review.text"
                                    class="form-control"
                                    rows="4"
                                />
                            </td>
                            <td class="text-center align-middle">
                                <textarea
                                    v-model="review.author"
                                    class="form-control"
                                    rows="4"
                                />
                            </td>
                            <td class="text-center align-middle">
                                <textarea
                                    v-model="review.date"
                                    class="form-control"
                                    rows="4"
                                />
                            </td>
                            <td>
                                <div class="form-group">
                                    <media-chooser
                                        v-model="review.image"
                                        @input="onMediaInput(index)"
                                    />
                                </div>
                            </td>
                            <td class="text-center align-middle">
                                <button
                                    class="btn btn-sm btn-danger ml-1"
                                    @click="deleteItem(index)"
                                >
                                    <i class="far fa-trash-alt" />
                                </button>
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Draggable from 'vuedraggable';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import MediaChooser from '@components/media/MediaChooser';
import ContentMixin from './content.mixin.js';

export default {
    components: {
        Draggable,
        WysiwygEditor,
        MediaChooser
    },

    mixins: [ContentMixin],

    validations: {
        content: {
            value: {
                title: {
                    required
                }
            }
        }
    },

    computed: {
        titleErrors() {
            const errors = [];

            if (!this.$v.content.value.title.$dirty) return errors;

            !this.$v.content.value.title.required &&
                errors.push('This field is required!');

            return errors;
        }
    },

    created() {
        this.contentKey = 'reviews';
        this.contentName = 'Reviews';
    },

    methods: {
        addItem() {
            this.content.value.reviews = [
                ...this.content.value.reviews,
                {
                    title: '',
                    text: '',
                    author: '',
                    date: '',
                    image: {}
                }
            ];
        },

        async deleteItem(index) {
            this.content.value.reviews.splice(index, 1);
        },

        async onMoved() {
            this.drag = false;
            this.contentKey += 1;
        },
    }
};
</script>
