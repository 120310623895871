<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
            <a class="btn btn-info ml-2" :href="url" target="_blank">
                <i class="far fa-eye mr-1" />
                Preview
            </a>
        </div>

        <div class="row">
            <div class="form-group col-12 mt-2">
                <b-form-checkbox v-model="content.value.enabled" switch>
                    Enabled
                </b-form-checkbox>
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Style
            </h5>
            <div class="form-group col-4">
                <label>
                    Time to change slide (ms)
                </label>
                <input
                    v-model.number="content.value.autoplaySpeed"
                    type="number"
                    class="form-control"
                />
            </div>
            <div class="form-group col-4">
                <label>
                    Slide speed - Desktop (ms)
                </label>
                <input
                    v-model.number="content.value.speed"
                    type="number"
                    class="form-control"
                />
            </div>
            <div class="form-group col-4">
                <label>
                    Slide speed - Mobile (ms)
                </label>
                <input
                    v-model.number="content.value.speedMobile"
                    type="number"
                    class="form-control"
                />
            </div>
        </div>

        <div class="row">
            <div class="form-group col-4">
                <div style="display:flex; justify-content: space-between">
                    <label>
                        Background Color
                    </label>
                    <b-form-checkbox v-model="content.value.backgroundColorGradientEnabled" switch>
                        Gradient
                    </b-form-checkbox>
                </div>
                <color-picker
                    v-if="!content.value.backgroundColorGradientEnabled"
                    v-model="content.value.backgroundColor"
                    :color="content.value.backgroundColor"
                />
                <gradient-picker
                    v-else
                    v-model="content.value.backgroundColorGradient"
                />
            </div>
        </div>
        
        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Variables
            </h5>
            <div class="col-12 mt-1">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Desktop Slides' and 'Mobile Slides' you can use variable
                    <b>{Codes}</b>.
                </div>
            </div>
            <div class="form-group col-4">
                <label>
                    {Codes} - Default Value
                </label>
                <input
                    v-model="content.value.variables.codes"
                    class="form-control"
                />
            </div>
        </div>

        <div v-for="(section, index) in items" :key="index" class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                {{ section.text }}
            </h5>
            <button
                class="btn btn-danger waves-effect waves-light ml-2 mb-2"
                type="button"
                @click="addItem(section.property)"
            >
                <i class="fas fa-plus" />
            </button>
            <div class="col-12 table-responsive">
                <table
                    class="table table-centered table-striped text-center table-bordered"
                >
                    <thead>
                        <tr>
                            <th>Icon</th>
                            <th>Text</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, itemIndex) in content.value[
                                section.property
                            ]"
                            :key="itemIndex"
                        >
                            <td>
                                <media-chooser v-model="item.icon" label="" />
                            </td>
                            <td>
                                <wysiwyg-editor
                                    id="text"
                                    v-model="item.text"
                                />
                            </td>
                            <td>
                                <a
                                    href="#"
                                    class="action-icon"
                                    @click.prevent="
                                        deleteItem(section.property, itemIndex)
                                    "
                                >
                                    <i class="far fa-trash-alt" />
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                Dropdown Menu
            </h5>
            <button
                class="btn btn-danger waves-effect waves-light ml-2 mb-2"
                type="button"
                @click="addDropdownMenuItem"
            >
                <i class="fas fa-plus" />
            </button>
            <div class="col-12 table-responsive">
                <table
                    class="table table-centered table-striped text-center table-bordered"
                >
                    <thead>
                        <tr>
                            <th>Icon</th>
                            <th>Title</th>
                            <th>Text</th>
                            <th>Copy Code</th>
                            <th>Background Color</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, itemIndex) in content.value.dropdownMenuItems"
                            :key="itemIndex"
                        >
                            <td>
                                <media-chooser v-model="item.icon" label="" />
                            </td>
                            <td>
                                <wysiwyg-editor
                                    id="text"
                                    v-model="item.title"
                                />
                            </td>
                            <td>
                                <wysiwyg-editor
                                    id="text"
                                    v-model="item.text"
                                />
                            </td>
                            <td>
                                <b-form-checkbox v-model="item.copyTextEnabled" switch>
                                    Enabled
                                </b-form-checkbox>
                                <input
                                    v-model="item.copyText"
                                    type="text"
                                    class="form-control mt-2"
                                />
                            </td>
                            <td>
                                <color-picker
                                    v-model="item.backgroundColor"
                                    :color="item.backgroundColor"
                                />
                            </td>
                            <td>
                                <a
                                    href="#"
                                    class="action-icon"
                                    @click.prevent="
                                        deleteDropdownMenuItem(itemIndex)
                                    "
                                >
                                    <i class="far fa-trash-alt" />
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <pages-selector v-model="content.value.pages" />
    </div>
</template>

<script>
import config from '@config';
import ContentMixin from './content.mixin.js';
import ColorPicker from '@components/elements/ColorPicker';
import GradientPicker from '@components/elements/GradientPicker';
import MediaChooser from '@components/media/MediaChooser';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import PagesSelector from '@components/contents/PagesSelector';

export default {
    components: {
        ColorPicker,
        GradientPicker,
        MediaChooser,
        WysiwygEditor,
        PagesSelector
    },

    mixins: [ContentMixin],

    data() {
        return {
            url: config.frontendUrl,
            items: [
                {
                    text: 'Desktop Slides',
                    property: 'items'
                },
                {
                    text: 'Mobile Slides',
                    property: 'itemsMobile'
                }
            ]
        };
    },

    created() {
        this.contentKey = 'promotion-slider';
        this.contentName = 'Promotion Slider';
    },

    methods: {
        addItem(property) {
            this.content.value[property] = [
                ...this.content.value[property],
                {
                    text: '',
                    icon: {}
                }
            ];
        },

        deleteItem(property, index) {
            this.content.value[property].splice(index, 1);
        },

        addDropdownMenuItem() {
            this.content.value.dropdownMenuItems = [
                ...this.content.value.dropdownMenuItems,
                {}
            ]
        },

        deleteDropdownMenuItem(index) {
            this.content.value.dropdownMenuItems.splice(index, 1);
        }
    }
};
</script>
