import { render, staticRenderFns } from "./ContentProductPage.vue?vue&type=template&id=8986dca4&"
import script from "./ContentProductPage.vue?vue&type=script&lang=js&"
export * from "./ContentProductPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports