<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>
        <div class="row">
            <div class="form-group">
                <b-form-checkbox
                    v-model="content.value.enabled"
                    name="enabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
        </div>
        <div v-for="(section, index) in items" :key="index" class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                {{ section.text }}
            </h5>
            <button
                class="btn btn-danger waves-effect waves-light ml-1 mb-1"
                type="button"
                @click="addItem(section.property)"
            >
                <i class="fas fa-plus" />
            </button>
            <div class="col-12 table-responsive">
                <table
                    class="table table-centered table-striped text-center table-bordered"
                >
                    <thead>
                        <tr>
                            <th>Icon</th>
                            <th>Text</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, itemIndex) in content.value[
                                section.property
                            ]"
                            :key="itemIndex"
                        >
                            <td>
                                <media-chooser v-model="item.icon" label="" />
                            </td>
                            <td>
                                <input
                                    v-model="item.text"
                                    type="text"
                                    class="form-control"
                                />
                            </td>
                            <td>
                                <a
                                    href="#"
                                    class="action-icon"
                                    @click.prevent="
                                        deleteItem(section.property, itemIndex)
                                    "
                                >
                                    <i class="far fa-trash-alt" />
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12 col-sm-4">
                <label>Background Color</label>
                <color-picker
                    v-model="content.value.backgroundColor"
                    :error-border="!!$v.content.value.backgroundColor.$error"
                    :color="content.value.backgroundColor"
                />
            </div>
            <div class="form-group col-12 col-sm-4">
                <label>Text Color</label>
                <color-picker
                    v-model="content.value.textColor"
                    :color="content.value.textColor"
                />
            </div>
            <div class="form-group col-12 col-sm-4">
                <label for="height">Height (in px)</label>
                <input
                    id="height"
                    v-model="content.value.height"
                    type="number"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.sticky"
                    name="sticky"
                    switch
                >
                    Pin to top
                </b-form-checkbox>
            </div>
        </div>

        <pages-selector v-model="content.value.pages" />
    </form>
</template>

<script>
import { required, helpers } from 'vuelidate/lib/validators';
import ColorPicker from '@components/elements/ColorPicker';
import MediaChooser from '@components/media/MediaChooser';
import PagesSelector from '@components/contents/PagesSelector';
import ContentMixin from './content.mixin.js';

export default {
    components: {
        ColorPicker,
        MediaChooser,
        PagesSelector
    },

    mixins: [ContentMixin],

    validations: {
        content: {
            value: {
                backgroundColor: {
                    required,
                    length: val =>
                        helpers.len(val) === 7 ||
                        helpers.len(val) === 4 ||
                        helpers.len(val) === 9
                }
            }
        }
    },

    data() {
        return {
            items: [
                {
                    text: 'Desktop',
                    property: 'items'
                },
                {
                    text: 'Mobile',
                    property: 'mobileItems'
                }
            ]
        };
    },

    created() {
        this.contentKey = 'notice';
        this.contentName = 'Notice';
    },

    methods: {
        deleteItem(property, index) {
            this.content.value[property].splice(index, 1);
        },
        addItem(property) {
            this.content.value[property] = [
                ...this.content.value[property],
                {}
            ];
        }
    }
};
</script>
