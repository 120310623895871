<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div class="form-group col-12 mt-1">
                <b-form-checkbox
                    v-model="content.value.takePhotosDuringScan"
                    switch
                    :disabled="content.value.cameraStageEnabled"
                >
                    Take Photos During Scan
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <b-form-checkbox
                    v-model="content.value.cameraStageEnabled"
                    switch
                    :disabled="content.value.takePhotosDuringScan"
                >
                    Photo Stage Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <label for="contentTitle">Title</label>
                <textarea
                    id="contentTitle"
                    v-model="content.value.title"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="contentSubtitle">Subtitle</label>
                <textarea
                    id="contentSubtitle"
                    v-model="content.value.subtitle"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="col-12">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Title' and 'Subtitle' you can use variables. There are
                    predefined variables for first name -
                    <b>{Name.firstName}</b> and last name -
                    <b>{Name.lastName}</b>.
                </div>
            </div>
            <div class="form-group col-12 col-lg-4">
                <label>
                    Retake Button Text
                </label>
                <input
                    v-model="content.value.retakeButtonText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-lg-4">
                <label>
                    Approve Button Text
                </label>
                <input
                    v-model="content.value.approveButtonText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12 col-lg-4">
                <label>
                    Continue Button Text
                </label>
                <input
                    v-model="content.value.mainButtonText"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label for="previewDescription">Preview Description</label>
                <textarea
                    id="previewDescription"
                    v-model="content.value.previewDescription"
                    class="form-control"
                    rows="2"
                />
            </div>

            <div class="col-12 col-lg-6">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Left Foot Video
                </h5>
                <video-content v-model="content.value.leftFootVideo" />
            </div>

            <div class="col-12 col-lg-6">
                <h5 class="col-12 text-uppercase bg-light p-2">
                    Right Foot Video
                </h5>
                <video-content v-model="content.value.rightFootVideo" />
            </div>
        </div>
    </div>
</template>

<script>
import ContentMixin from '../content.mixin.js';
import VideoContent from '@components/elements/contents-builder-elements/Video';

export default {
    components: {
        VideoContent
    },

    mixins: [ContentMixin],

    created() {
        this.contentKey = 'mobile-3d-photo-stage';
        this.contentName = 'Mobile 3D Photo Stage';
    }
};
</script>
