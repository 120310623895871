<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="onButtonClick" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="onButtonClick"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>
        <hr>
        <div class="row">
            <div class="col-3 p-2">
                <button
                    v-b-modal.add-version-modal
                    class="btn btn-success"
                    type="button"
                >
                    <i class="fas fa-plus-circle" />
                    Add version
                </button>
            </div>

            <div class="col-9 d-flex justify-content-end p-2">
                <a class="btn btn-info mr-1" :href="url" target="_blank">
                    Preview - {{ url }}
                </a>
                <button
                    class="btn btn-success mr-1"
                    type="button"
                    :disabled="isCurrentDefault"
                    @click.prevent="markAsDefaultCurrentVersion"
                >
                    {{
                        isCurrentDefault ? 'Default version' : 'Mark as default'
                    }}
                </button>
                <button
                    class="btn btn-danger"
                    type="button"
                    @click="removeCurrentVersion"
                >
                    Remove this version
                </button>
            </div>

            <div class="col-12 card mb-0">
                <ul class="nav nav-pills navtab-bg nav-justified my-2">
                    <li
                        v-for="(version, index) in content.value.versions"
                        :key="index"
                        class="nav-item"
                    >
                        <a
                            href="#"
                            data-toggle="tab"
                            aria-expanded="false"
                            class="nav-link"
                            :class="{
                                active: selectedVersionIndex === index
                            }"
                            @click.prevent="changeVersion(index)"
                        >
                            {{ version.config.name }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <version-homepage-v2
            v-if="content.value.versions[selectedVersionIndex].config.homepageV2"
            v-model="content.value.versions[selectedVersionIndex]"
        />

        <version-single
            v-else
            :key="`${selectedVersionIndex}_single`"
            v-model="content.value.versions[selectedVersionIndex]"
        />

        <version-buttons
            :key="`${selectedVersionIndex}_buttons`"
            v-model="content.value.versions[selectedVersionIndex].buttons"
            :two-buttons="content.value.versions[selectedVersionIndex].config.twoButtons"
            :homepage-v2="content.value.versions[selectedVersionIndex].config.homepageV2"
        />

        <add-version-modal
            :versions="content.value.versions"
            @save="addVersion"
        />
    </div>
</template>

<script>
import AddVersionModal from '@components/contents/AddVersionModal';
import VersionSingle from '@components/contents/VersionSingle';
import VersionHomepageV2 from '@components/contents/VersionHomepageV2';
import VersionButtons from '@components/contents/VersionButtons';
import ContentMixin from './content.mixin.js';
import config from '@config';

export default {
    components: {
        VersionSingle,
        VersionHomepageV2,
        VersionButtons,
        AddVersionModal
    },
    mixins: [ContentMixin],

    data() {
        return {
            selectedVersionIndex: 0
        };
    },

    computed: {
        isCurrentDefault() {
            return this.content.value.versions[this.selectedVersionIndex].config
                .default;
        },
        url() {
            if (this.isCurrentDefault) {
                return config.frontendUrl;
            }

            return `${config.frontendUrl}/${
                this.content.value.versions[this.selectedVersionIndex].config.id
            }`;
        }
    },

    created() {
        this.contentKey = 'versions';
        this.contentName = 'Versions';
    },

    methods: {
        changeVersion(index) {
            this.selectedVersionIndex = index;
        },

        onButtonClick() {
            this.changeVersion(this.selectedVersionIndex);
            this.save();
        },

        async removeCurrentVersion() {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this version?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            if (this.isCurrentDefault) {
                this.$toasterWarning('You can not remove default version!');

                return;
            }

            this.content.value.versions.splice(this.selectedVersionIndex, 1);
            this.selectedVersionIndex = 0;
        },

        markAsDefaultCurrentVersion() {
            this.content.value.versions = this.content.value.versions.map(
                version => {
                    version.config.default = false;

                    return version;
                }
            );

            this.content.value.versions[
                this.selectedVersionIndex
            ].config.default = true;
        },

        addVersion(version) {
            this.content.value.versions = [
                ...this.content.value.versions,
                version
            ];
        }
    }
};
</script>
