<template>
    <input
        v-model="displayValue"
        type="text"
        @blur="isInputActive = false"
        @focus="isInputActive = true"
    />
</template>

<script>
export default {
    props: ['value'],

    data() {
        return {
            isInputActive: false
        };
    },

    computed: {
        displayValue: {
            get() {
                if (!this.value && this.value !== 0) {
                    return '';
                }

                const numberValue = +this.value;

                if (this.isInputActive) {
                    return numberValue.toString();
                } else {
                    return (
                        '$ ' +
                        numberValue
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                    );
                }
            },
            set(modifiedValue) {
                let newValue = parseFloat(
                    modifiedValue.replace(/[^\d.]/g, '')
                );

                if (isNaN(newValue)) {
                    newValue = 0;
                }

                this.$emit('input', newValue);
            }
        }
    }
};
</script>
