<template>
    <manage-faq-content content-key="homepage-faq" />
</template>

<script>
import ManageFaqContent from '@components/contents/ManageFaqContent';

export default {
    components: {
        ManageFaqContent
    }
};
</script>
