<template>
    <b-modal
        id="editMenuModal"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="$emit('hide')"
    >
        <template slot="modal-title">
            Edit menu item
        </template>
        <div class="custom-modal-text row">
            <div class="form-group col-6">
                <label for="text">Text</label>
                <input
                    id="text"
                    v-model="item.text"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-6">
                <label for="link">Link</label>
                <input
                    id="link"
                    v-model="item.link"
                    type="text"
                    class="form-control"
                />
            </div>
            <div v-if="isMainMenu" class="form-group col-6">
                <label>Color</label>
                <color-picker
                    :key="item.textColor"
                    v-model="item.textColor"
                    :color="item.textColor"
                />
            </div>
            <div v-if="isMainMenu" class="form-group col-6">
                <label for="link">Bold Text</label>
                <b-form-checkbox v-model="item.textBold" switch>
                    Enabled
                </b-form-checkbox>
            </div>
            <div v-if="isMainMenu" class="col-12">
                <b-form-checkbox
                    v-model="item.hideOnDesktop"
                    class="mb-2"
                    switch
                >
                    Hide on desktop
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="item.hideOnMobile"
                    class="mb-2"
                    switch
                >
                    Hide on mobile
                </b-form-checkbox>
            </div>
            <div class="col-12">
                <b-form-checkbox v-model="item.submenuEnabled" switch>
                    Submenu Enabled
                </b-form-checkbox>
            </div>
        </div>

        <div v-if="item.submenuEnabled">
            <div v-if="isMainMenu" class="custom-modal-text row">
                <div class="form-group col-12">
                    <label for="link">Button text</label>
                    <input
                        id="link"
                        v-model="item.submenu.buttonText"
                        type="text"
                        class="form-control"
                    />
                </div>

                <h5
                    class="col-12 d-flex align-items-center justify-content-between text-uppercase bg-light p-2"
                >
                    Featured Product
                </h5>

                <div class="form-group col-6">
                    <label for="text">Name</label>
                    <input
                        id="text"
                        v-model="item.submenu.featuredProduct.name"
                        type="text"
                        class="form-control"
                    />
                </div>
                <div class="form-group col-6">
                    <label for="link">Link</label>
                    <input
                        id="link"
                        v-model="item.submenu.featuredProduct.link"
                        type="text"
                        class="form-control"
                    />
                </div>
                <div class="form-group col-12">
                    <label for="link">Subtext</label>
                    <input
                        id="link"
                        v-model="item.submenu.featuredProduct.subtext"
                        type="text"
                        class="form-control"
                    />
                </div>
                <media-chooser
                    v-model="item.submenu.featuredProduct.image"
                    label="Image"
                    class="col-12"
                />

                <h5
                    class="col-12 d-flex align-items-center justify-content-between text-uppercase bg-light p-2 mt-3 mb-0"
                >
                    Columns
                </h5>
            </div>

            <div class="row">
                <div
                    v-for="(column, columnIndex) in item.submenu.columns"
                    :key="columnIndex"
                    class="col-12"
                >
                    <h5
                        class="col-12 d-flex align-items-center justify-content-between text-uppercase bg-light p-2"
                    >
                        <input
                            id="text"
                            v-model="column.title"
                            type="text"
                            class="form-control"
                        />
                        <button
                            class="btn btn-danger waves-effect waves-light ml-2"
                            type="button"
                            @click="deleteColumn(columnIndex)"
                        >
                            <i class="far fa-trash-alt" />
                        </button>
                    </h5>

                    <div
                        v-for="(subItem, subItemIndex) in column.subItems"
                        :key="subItemIndex"
                        class="col-12 row"
                    >
                        <div class="form-group col-6">
                            <label for="text">Text</label>
                            <input
                                id="text"
                                v-model="subItem.text"
                                type="text"
                                class="form-control"
                            />
                        </div>
                        <div class="form-group col-5">
                            <label for="link">Link</label>
                            <input
                                id="link"
                                v-model="subItem.link"
                                type="text"
                                class="form-control"
                            />
                        </div>
                        <div class="form-group col-1">
                            <button
                                class="btn btn-danger waves-effect waves-light mt-3"
                                type="button"
                                @click="
                                    deleteSubItem(columnIndex, subItemIndex)
                                "
                            >
                                <i class="far fa-trash-alt" />
                            </button>
                        </div>
                    </div>

                    <div class="col-12 mb-3">
                        <button
                            class="btn btn-info waves-effect waves-light"
                            type="button"
                            @click="addSubItem(columnIndex)"
                        >
                            <i class="fas fa-plus" />
                        </button>
                    </div>
                </div>

                <div class="col-12 mt-3">
                    <button
                        class="btn btn-info waves-effect waves-light"
                        type="button"
                        @click="addColumn"
                    >
                        <i class="fas fa-plus" />
                        Add Column
                    </button>
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="saveMenuItem"
                >
                    Save
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import ColorPicker from '@components/elements/ColorPicker';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        ColorPicker,
        MediaChooser
    },

    props: {
        value: {
            type: Object,
            required: false,
            default: null
        },
        isMainMenu: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            item: {
                submenu: {
                    columns: []
                }
            }
        };
    },

    methods: {
        hideModal() {
            this.$bvModal.hide('editMenuModal');
        },

        async openModalHandler() {
            await this.$nextTick();

            this.item = cloneDeep(this.value);
        },

        saveMenuItem() {
            this.$emit('save-menu-item', this.item);
            this.hideModal();
        },

        addColumn() {
            this.item.submenu.columns.push({
                title: '',
                subItems: []
            });
        },

        deleteColumn(index) {
            this.item.submenu.columns.splice(index, 1);
        },

        addSubItem(index) {
            this.item.submenu.columns[index].subItems.push({
                text: '',
                link: ''
            });
        },

        deleteSubItem(columnIndex, subItemIndex) {
            this.item.submenu.columns[columnIndex].subItems.splice(
                subItemIndex,
                1
            );
        }
    }
};
</script>
