<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div class="form-group col-12 mt-1">
                <b-form-checkbox
                    v-model="content.value.enabled"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <label for="contentTitle">Title</label>
                <textarea
                    id="contentTitle"
                    v-model="content.value.title"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="contentSubtitle">Subtitle</label>
                <textarea
                    id="contentSubtitle"
                    v-model="content.value.subtitle"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="col-12">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Title' and 'Subtitle' you can use variables. There are predefined variables for first name -
                    <b>{Name.firstName}</b> and last name - <b>{Name.lastName}</b>.
                </div>
            </div>
            <div class="form-group col-12">
                <label for="contentTitle">Retake Button Text</label>
                <input
                    id="contentTitle"
                    v-model="content.value.retakeButtonText"
                    class="form-control"
                />
            </div>

            <h5 class="col-12 text-uppercase bg-light p-2">
                Slides
            </h5>
            <div
                v-for="(card, index) in content.value.cards"
                :key="index"
                class="col-4"
            >
                <div class="form-group col-12">
                    <label :for="`benefit_description_${index}`">Description</label>
                    <textarea
                        :id="`benefit_description_${index}`"
                        v-model="card.description"
                        class="form-control"
                        rows="2"
                    />
                </div>

                <div class="col-12 ml-1 mt-1">
                    <div
                        v-for="(mediaOption, mIndex) in mediaOptions"
                        :key="mIndex"
                        class="radio radio-primary"
                    >
                        <input
                            :id="`${mediaOption}_${index}`"
                            v-model="card.selectedMedia"
                            type="radio"
                            :name="`customRadio_${index}`"
                            :value="mediaOption"
                        />
                        <label :for="`${mediaOption}_${index}`">
                            {{ mediaOption | startCase }}
                        </label>
                    </div>
                </div>

                <video-content
                    v-if="card.selectedMedia === 'video'"
                    v-model="card.video"
                />
                <media-chooser
                    v-else
                    v-model="card.image"
                    label="Image"
                    class="col-12"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ContentMixin from '../content.mixin.js';
import MediaChooser from '@components/media/MediaChooser';
import VideoContent from '@components/elements/contents-builder-elements/Video';

export default {
    components: {
        MediaChooser,
        VideoContent
    },

    mixins: [ContentMixin],

    data() {
        return {
            mediaOptions: [
                'image',
                'video'
            ],
        }
    },

    created() {
        this.contentKey = 'mobile-3d-scanning-tips';
        this.contentName = 'Mobile 3D Scanning Tips';
    }
};
</script>
