<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="d-flex flex-wrap">
            <div class="form-group col-12">
                <label>Content</label>
                <wysiwyg-editor v-model="content.value.content" height="800" />
            </div>
        </div>
    </form>
</template>

<script>
import WysiwygEditor from '@components/elements/WysiwygEditor';
import ContentMixin from './content.mixin.js';

export default {
    components: { WysiwygEditor },

    mixins: [ContentMixin],

    created() {
        this.contentKey = 'terms-and-conditions';
        this.contentName = 'Terms and Conditions';
    }
};
</script>
