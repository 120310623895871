<template>
    <div v-if="faqContent.value">
        <div
            v-if="contentKey !== 'product-page-faq'"
            class="form-group col-12 d-flex justify-content-start"
        >
            <button
                class="btn btn-info waves-effect waves-light mx-1"
                type="button"
                @click="addNewSubject"
            >
                <i class="fas fa-plus mr-1" />
                Add new subject
            </button>
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <were-changes-alert :were-changes="showTopAlert" @save="save" />

        <div>
            <div
                v-if="['homepage-faq', 'product-page-faq'].includes(contentKey)"
                class="form-group col-12 mt-4"
            >
                <b-form-checkbox v-model="faqContent.value.enabled" switch>
                    Show FAQ
                </b-form-checkbox>
            </div>
            <div v-if="contentKey === 'homepage-faq'" class="form-group col-12">
                <b-form-checkbox v-model="faqContent.value.showSearch" switch>
                    Show Search
                </b-form-checkbox>
            </div>
        </div>

        <draggable
            :list="subjects"
            tag="div"
            ghost-class="ghost"
            class="d-flex flex-wrap"
            @end="onFinishDraggingSubject"
        >
            <div
                v-for="(subject, subjectIndex) in subjects"
                :key="subjectIndex"
                class="form-group col-12"
            >
                <hr />
                <faq-questions-list
                    v-if="subject.questions"
                    :key="`question_list_${subjects.length}_${subjectIndex}`"
                    v-model="subject.questions"
                >
                    <template #list-header="scope">
                        <h5
                            class="d-flex justify-content-between text-uppercase bg-light p-2 mt-0 mb-0"
                        >
                            <div class="px-3">
                                <b-form-text id="`subject_${subjectIndex}`">
                                    subject :
                                </b-form-text>
                                <b-form-input
                                    v-model="subject.subject"
                                    class="form-control"
                                    placeholder="Enter subject"
                                    aria-describedby="`subject_${subjectIndex}`"
                                />
                            </div>
                            <div class="btn-group btn-group-xs">
                                <button
                                    type="button"
                                    style="max-height: 35px;"
                                    class="tabledit-edit-button btn btn-success btn-small btn-sm"
                                    :disabled="subject.default"
                                    @click="markAsDefault(subjectIndex)"
                                >
                                    <span
                                        v-if="subject.default"
                                        class="mdi mdi-checkbox-marked-circle"
                                    >
                                        Default
                                    </span>
                                    <span v-else>
                                        Mark as default
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    style="max-height: 35px;"
                                    class="tabledit-edit-button btn btn-success btn-small btn-sm mx-1"
                                    @click="scope.addItem"
                                >
                                    <span class="mdi mdi-plus">
                                        Add new question
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    style="max-height: 35px;"
                                    class="tabledit-edit-button btn btn-danger"
                                    @click="
                                        onClickDeleteSubject(subject.subject)
                                    "
                                >
                                    <i class="fas fa-trash-alt mr-1" />
                                    Delete Subject
                                </button>
                            </div>
                        </h5>
                    </template>
                </faq-questions-list>
            </div>
        </draggable>
    </div>
</template>

<script>
import FaqQuestionsList from '@components/contents/FaqQuestionsList';
import Draggable from 'vuedraggable';
import { mapActions } from 'vuex';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    components: {
        FaqQuestionsList,
        Draggable,
        WereChangesAlert
    },

    props: {
        contentKey: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            initValueStringify: {},
            faqContent: {},
            questions: [],
            subjects: [],
            showTopAlert: false
        };
    },

    watch: {
        faqContent: {
            handler: function() {
                this.showTopAlert =
                    JSON.stringify(this.faqContent) !== this.initValueStringify;
            },
            deep: true
        }
    },

    async mounted() {
        try {
            this.faqContent = await this.getContent({
                key: this.contentKey
            });

            this.subjects = [...this.faqContent.value.subjects];

            this.initValueStringify = JSON.stringify(this.faqContent);
        } catch (err) {
            console.error(err);

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            getContent: 'contents/show',
            updateContent: 'contents/update'
        }),

        addNewSubject() {
            this.subjects = [
                ...this.subjects,
                {
                    subject: '',
                    questions: []
                }
            ];
        },

        orderQuestions(questions) {
            if (!questions || !questions.length) {
                return;
            }

            return questions.map((item, index) => ({
                ...item,
                order: index + 1
            }));
        },

        async onClickDeleteSubject(subjectTitle) {
            const confirmed = await this.$confirmationModal(
                'Every question and answer will be deleted with subject. Are you sure?'
            );

            if (confirmed) {
                this.subjects = this.subjects.filter(item => {
                    return item.subject !== subjectTitle;
                });
            }
        },

        async onFinishDraggingSubject() {
            let subjectsCopy = [
                ...this.subjects.map(item => ({
                    ...item,
                    questions: [...item.questions]
                }))
            ];

            this.subjects = [];

            await this.$nextTick();

            this.subjects = subjectsCopy;
        },

        markAsDefault(subjectIndex) {
            this.subjects = this.subjects.map(
                subject => {
                    subject.default = false;

                    return subject;
                }
            );

            this.subjects[subjectIndex].default = true;
        },

        async save() {
            try {
                this.faqContent.value.subjects = this.subjects.map(
                    (singleSubject, subjectIndex) => ({
                        subject: singleSubject.subject,
                        order: subjectIndex + 1,
                        questions: this.orderQuestions(singleSubject.questions),
                        default: singleSubject.default
                    })
                );

                await this.updateContent({
                    key: this.faqContent.key,
                    contentData: this.faqContent
                });

                this.initValueStringify = JSON.stringify(this.faqContent);
                this.showTopAlert = false;

                this.$toaster('FAQ has been updated');
            } catch (err) {
                console.error(err);

                let message = null;

                if (err.response.data.errors) {
                    message = err.response.data.errors.join('. ');
                }

                this.$toasterError(message);
            }
        }
    }
};
</script>
