<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <one-time-offer-navigation />

        <div class="row">
            <div class="form-group col-12 mt-2">
                <b-form-checkbox v-model="content.value.enabled" switch>
                    Enabled
                </b-form-checkbox>
                <span v-if="$v.content.value.enabled.$error" style="color: red">
                    Please add at least one color for enable one time offer.
                </span>
            </div>
            <div class="col-12 my-2">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Title', 'Support Text', 'Description', 'Timer' and
                    'Tooltip' you can use variables from questionnaire e.g.
                    <b>{Name}</b> and predefined <b>{Timer}</b>, <b>{Price}</b>,
                    <b>{TaxedPrice}</b> and <b>{QtyPrice}</b>.
                </div>
            </div>
            <div class="form-group col-12">
                <label for="title">Title</label>
                <textarea
                    id="title"
                    v-model="content.value.title"
                    class="form-control"
                    rows="2"
                />
            </div>
            <media-chooser
                v-model="content.value.supportAvatar"
                label="Support Avatar"
                class="col-12"
                recommended-size="80x80"
            />
            <div class="form-group col-6 my-2">
                <label for="support-name">Support Name</label>
                <textarea
                    id="support-name"
                    v-model="content.value.supportName"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-6 my-2">
                <label for="support-text">Support Text</label>
                <textarea
                    id="support-text"
                    v-model="content.value.supportText"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="subtitle">Description</label>
                <textarea
                    id="subtitle"
                    v-model="content.value.subtitle"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="timer">Timer</label>
                <textarea
                    id="timer"
                    v-model="content.value.timer"
                    class="form-control"
                    rows="2"
                />
            </div>
            <div class="form-group col-12">
                <label for="time">Time (in minutes)</label>
                <input
                    id="time"
                    v-model="content.value.time"
                    class="form-control"
                    type="number"
                    :class="{ 'is-invalid': $v.content.value.time.$error }"
                />
                <span class="invalid-feedback">
                    Time can not empty and greater than 30.
                </span>
            </div>
            <div class="form-group col-6">
                <label for="confirmButton">Confirm button text</label>
                <input
                    id="confirmButton"
                    v-model="content.value.confirmButton"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-6">
                <label for="skipButton">Skip button text</label>
                <input
                    id="skipButton"
                    v-model="content.value.skipButton"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="form-group col-12">
                <label for="tooltip">Tooltip</label>
                <textarea
                    id="tooltip"
                    v-model="content.value.tooltip"
                    class="form-control"
                    rows="2"
                />
            </div>
        </div>
        <h5 class="col-12 text-uppercase bg-light p-2">
            Product
        </h5>
        <div class="row">
            <div class="col-6 form-group mb-3">
                <div
                    v-for="(price, index) in content.value.prices"
                    :key="index"
                >
                    <label for="price">
                        Price ({{
                            `${price.forProductsQuantity} product${
                                price.forProductsQuantity === 1 ? '' : 's'
                            }`
                        }})
                    </label>
                    <currency-input
                        v-model.number="price.price"
                        type="text"
                        class="form-control mb-2"
                        placeholder="Enter amount"
                    />
                </div>
            </div>
            <div class="col-6 form-group mb-3">
                <label for="quantity">
                    Quantity
                </label>
                <input
                    id="quantity"
                    v-model.number="content.value.quantity"
                    class="form-control"
                    min="1"
                    placeholder="Enter quantity"
                    type="number"
                />
            </div>
        </div>
        <h5 class="text-uppercase bg-light p-2 mb-3">
            Colors
        </h5>
        <awesome-table
            :labels="labels"
            :items="content.value.colors"
            :options="{
                pagination: false,
                searchInput: false,
                isCard: false,
                link: false
            }"
            @add-button-click="openModal"
            @row-click="onRowClick"
        >
            <template #[`items.name`]="{ value, item }">
                {{ value }}
                <span
                    v-if="item.default"
                    class="badge bg-soft-primary text-primary ml-1"
                >
                    Default
                </span>
            </template>
            <template #[`items.hex`]="{ value }">
                <span
                    :style="{
                        'background-color': value,
                        display: 'block',
                        margin: 'auto'
                    }"
                    class="colored-block"
                />
            </template>
            <template #[`items.actions`]="{ item, index }">
                <a
                    href="#"
                    class="action-icon"
                    @click.stop.prevent="markAsDefault(index)"
                >
                    <i v-if="item.default" class="fas fa-star" />
                    <i v-else class="far fa-star" />

                </a>
                <a
                    href="#"
                    class="action-icon"
                    @click.stop.prevent="removeColor(index)"
                >
                    <i class="far fa-trash-alt" />
                </a>
            </template>
        </awesome-table>

        <add-edit-one-time-offer-color
            :edited-item="editedItem"
            @save="saveColor"
        />
    </div>
</template>

<script>
import OneTimeOfferNavigation from './one-time-offer/OneTimeOfferNavigation';
import AddEditOneTimeOfferColor from '@components/contents/AddEditOneTimeOfferColor';
import MediaChooser from '@components/media/MediaChooser';
import CurrencyInput from '@components/elements/CurrencyInput';
import ContentMixin from './content.mixin.js';

export default {
    validations: {
        content: {
            value: {
                time: {
                    lessThan: v => {
                        return v && parseFloat(v) >= 5 && parseFloat(v) <= 30;
                    }
                },
                enabled: {
                    onlyIfColors: (v, obj) => {
                        if (!v) {
                            return true;
                        }

                        return !!obj.colors.length;
                    }
                }
            }
        }
    },

    components: {
        OneTimeOfferNavigation,
        AddEditOneTimeOfferColor,
        MediaChooser,
        CurrencyInput
    },

    mixins: [ContentMixin],

    data() {
        return {
            labels: [
                { value: 'name', width: '33%' },
                { value: 'hex', text: 'Color', width: '33%' },
                { value: 'actions', width: '34%' }
            ],
            indexToEdit: null
        };
    },

    computed: {
        editedItem() {
            if (this.indexToEdit === null) {
                return null;
            }

            return this.content.value.colors[this.indexToEdit];
        }
    },

    created() {
        this.contentKey = 'one-time-offer';
        this.contentName = 'One Time Offer';
    },

    methods: {
        afterDataFetch() {
            this.content.value.prices.forEach(price => (price.price /= 100));
        },

        beforeSave() {
            this.content.value.prices.forEach(price =>
                parseInt((price.price *= 100))
            );
        },

        afterSave() {
            this.content.value.prices.forEach(price => (price.price /= 100));
        },

        async onRowClick(item, index) {
            this.indexToEdit = index;

            await this.$nextTick();

            this.openModal();
        },

        openModal() {
            this.$bvModal.show('add-edit-one-time-offer-color');
        },

        saveColor(color) {
            if (this.indexToEdit !== null) {
                this.content.value.colors.splice(this.indexToEdit, 1, color);
                this.indexToEdit = null;

                return;
            }

            this.content.value.colors.push(color);
        },

        async removeColor(index) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this color?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            this.content.value.colors.splice(index, 1);
        },

        markAsDefault(index) {
            this.content.value.colors = this.content.value.colors.map(
                (color, i) => ({ ...color, default: i === index })
            );
        }
    }
};
</script>
