<template>
    <b-modal
        :id="id"
        title="Color"
        centered
        @show="openModalHandler"
    >
        <template slot="modal-title">
            Color
        </template>

        <div class="row">
            <div class="form-group col-12">
                <label for="name">
                    Name
                </label>
                <input
                    id="name"
                    v-model="color.name"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.color.name.$error }"
                />
            </div>

            <div class="form-group col-12">
                <label>Color Hex</label>
                <color-picker
                    :key="color.name"
                    v-model="color.hex"
                    :color="color.hex"
                    :error-border="$v.color.hex.$error"
                />
            </div>

            <div class="form-group col-12">
                <media-chooser v-model="color.image" />
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="close"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="save"
                >
                    Save
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import MediaChooser from '@components/media/MediaChooser';
import ColorPicker from '@components/elements/ColorPicker';

export default {
    validations: {
        color: {
            name: {
                required
            },
            hex: {
                required
            }
        }
    },

    components: {
        MediaChooser,
        ColorPicker
    },

    props: {
        editedItem: {
            type: Object,
            required: false,
            default: null
        }
    },

    data() {
        return {
            id: 'add-edit-one-time-offer-color',
            color: {},
            colors: {},
            productsCount: 0
        };
    },

    async created() {
        try {
            const products = await this.getProductColorNames();
            this.productsCount = products.length;

            products.forEach(product => {
                product.colors.forEach(color => {
                    const name = color.name.toLowerCase();

                    if (!this.colors[name]) {
                        this.colors[name] = [product.name];

                        return;
                    }

                    this.colors[name].push(product.name);
                });
            });
        } catch (error) {
            console.error(error);

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            getProductColorNames: 'products/getProductColorNames'
        }),

        openModalHandler() {
            if (this.editedItem) {
                this.color = { ...this.editedItem };
            }
        },

        close() {
            this.$bvModal.hide(this.id);
            this.color = {};
            this.$v.$reset();
        },

        save() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            const name = this.color.name.toLowerCase();

            if (!this.colors[name]) {
                this.$toasterError(
                    'Color with this name does not exist in any product!'
                );

                return;
            }

            if (this.colors[name].length !== this.productsCount) {
                this.$toasterError(
                    `Color with this name does not exist in all products! Products include this color: ${this.colors[
                        name
                    ].join(
                        ', '
                    )}. Please add this color to the rest of the products`
                );

                return;
            }

            this.$emit('save', this.color);
            this.close();
        }
    }
};
</script>
