<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>
        <div class="row">
            <media-chooser v-model="content.value.modalImage" class="col-12" />
            <div class="form-group col-12 col-md-6">
                <label for="modalTitle">Title</label>
                <textarea
                    id="modalTitle"
                    v-model="content.value.modalTitle"
                    :class="{
                        'is-invalid': $v.content.value.modalTitle.$error
                    }"
                    class="form-control"
                    rows="3"
                />
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="modalSubtitle">Subtitle</label>
                <textarea
                    id="modalSubtitle"
                    v-model="content.value.modalSubtitle"
                    :class="{
                        'is-invalid': $v.content.value.modalSubtitle.$error
                    }"
                    class="form-control"
                    rows="3"
                />
            </div>
        </div>
    </form>
</template>

<script>
import ContentMixin from './content.mixin.js';
import { required } from 'vuelidate/lib/validators';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        MediaChooser
    },

    mixins: [ContentMixin],

    validations: {
        content: {
            value: {
                modalTitle: {
                    required
                },
                modalSubtitle: {
                    required
                }
            }
        }
    },

    created() {
        this.contentKey = 'newsletter-modal';
        this.contentName = 'Newsletter Modal';
    }
};
</script>
