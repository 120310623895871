<template>
    <div :key="products.length" class="d-flex flex-wrap mb-3">
        <div
            v-for="(product, index) in products"
            :key="index"
            class="col-6 row m-0"
        >
            <h5
                class="col-12 d-flex align-items-center justify-content-between text-uppercase bg-light p-2"
            >
                <div> Product {{ index + 1 }} </div>
                <button
                    class="btn btn-danger waves-effect waves-light"
                    type="button"
                    @click="deleteItem(index)"
                >
                    <i class="far fa-trash-alt" />
                </button>
            </h5>
            <media-chooser
                v-model="product.image"
                label="Image"
                class="col-12"
            />
            <div class="form-group col-12">
                <label :for="`name${index}`">Name</label>
                <input
                    :id="`name${index}`"
                    v-model="product.name"
                    class="form-control"
                    type="text"
                    @input="onChange"
                />
            </div>
            <div class="form-group col-12">
                <label :for="`text${index}`">Text</label>
                <textarea
                    :id="`text${index}`"
                    v-model="product.text"
                    class="form-control"
                    rows="2"
                    @input="onChange"
                />
            </div>
            <div class="form-group col-6">
                <label for="buttonText">Button Text</label>
                <input
                    id="buttonText"
                    v-model="product.button.text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-6">
                <label for="buttonLink">Button Link</label>
                <input
                    id="buttonLink"
                    v-model="product.button.link"
                    class="form-control"
                />
            </div>
        </div>

        <div class="col-12 mt-3">
            <button
                class="btn btn-info waves-effect waves-light"
                type="button"
                @click="addItem"
            >
                <i class="fas fa-plus" />
            </button>
        </div>
    </div>
</template>

<script>
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        MediaChooser
    },

    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data() {
        return {
            products: []
        };
    },

    created() {
        this.products = [...this.value];
    },

    methods: {
        onChange() {
            this.$emit('input', this.products);
        },

        addItem() {
            if (this.products.length) {
                const last = [...this.products].pop();

                if (!last.name || !last.text) {
                    this.$toasterWarning(
                        'Please fill the previous product before adding a new one!'
                    );

                    return;
                }
            }

            this.products = [
                ...this.products,
                {
                    name: '',
                    text: '',
                    button: {
                        text: '',
                        link: '/'
                    }
                }
            ];
        },

        deleteItem(index) {
            this.products.splice(index, 1);

            this.$emit('input', this.products);
        }
    }
};
</script>
