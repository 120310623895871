<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div
                v-for="(section, sectionIndex) in content.value.sections"
                :key="sectionIndex"
                class="col-12"
            >
                <h5
                    class="col-12 d-flex align-items-center justify-content-between text-uppercase bg-light p-2"
                >
                    <input
                        id="text"
                        v-model="section.title"
                        type="text"
                        class="form-control"
                    />
                    <button
                        class="btn btn-danger waves-effect waves-light ml-2"
                        type="button"
                        @click="deleteSection(sectionIndex)"
                    >
                        <i class="far fa-trash-alt" />
                    </button>
                </h5>

                <div
                    v-for="(item, itemIndex) in section.items"
                    :key="itemIndex"
                    class="col-12 row"
                >
                    <div class="form-group col-6">
                        <label for="text">Text</label>
                        <input
                            id="text"
                            v-model="item.text"
                            type="text"
                            class="form-control"
                        />
                    </div>
                    <div class="form-group col-5">
                        <label for="link">Link</label>
                        <input
                            id="link"
                            v-model="item.link"
                            type="text"
                            class="form-control"
                        />
                    </div>
                    <div class="form-group col-1">
                        <button
                            class="btn btn-danger waves-effect waves-light mt-3"
                            type="button"
                            @click="
                                deleteItem(sectionIndex, itemIndex)
                            "
                        >
                            <i class="far fa-trash-alt" />
                        </button>
                    </div>
                </div>

                <div class="col-12 mb-3">
                    <button
                        class="btn btn-info waves-effect waves-light"
                        type="button"
                        @click="addItem(sectionIndex)"
                    >
                        <i class="fas fa-plus" />
                    </button>
                </div>
            </div>

            <div class="col-12 mt-3">
                <button
                    class="btn btn-info waves-effect waves-light"
                    type="button"
                    @click="addSection"
                >
                    <i class="fas fa-plus" />
                    Add Column
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import ContentMixin from './content.mixin.js';

export default {
    mixins: [ContentMixin],

    created() {
        this.contentKey = 'footer';
        this.contentName = 'Footer';
    },

    methods: {
        addSection() {
            this.content.value.sections.push({
                title: '',
                items: []
            });
        },

        deleteSection(sectionIndex) {
            this.content.value.sections.splice(sectionIndex, 1);
        },

        addItem(index) {
            this.content.value.sections[index].items.push({
                text: '',
                link: ''
            });
        },

        deleteItem(sectionIndex, itemIndex) {
            this.content.value.sections[sectionIndex].items.splice(
                itemIndex,
                1
            );
        }
    }
};
</script>
