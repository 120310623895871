var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.helpContent.value)?_c('div',[_c('were-changes-alert',{attrs:{"were-changes":_vm.wereChanges},on:{"save":_vm.save}}),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-success waves-effect waves-light",attrs:{"type":"button"},on:{"click":_vm.save}},[_c('i',{staticClass:"fas fa-save mr-1"}),_vm._v("\n            Save updates\n        ")])]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('h5',{staticClass:"col-12 text-uppercase bg-light p-2"},[_vm._v("\n            General\n        ")]),_c('div',{staticClass:"form-group col-12"},[_c('label',{attrs:{"for":"contentTitle"}},[_vm._v("Title")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.helpContent.value.title),expression:"helpContent.value.title"}],staticClass:"form-control",class:{ 'is-invalid': _vm.$v.helpContent.value.title.$error },attrs:{"id":"contentTitle","rows":"3"},domProps:{"value":(_vm.helpContent.value.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.helpContent.value, "title", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12"},[_c('label',{attrs:{"for":"contactTitle"}},[_vm._v("Contact Title")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.helpContent.value.contact.title),expression:"helpContent.value.contact.title"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.helpContent.value.contact.title.$error
                },attrs:{"id":"contactTitle","rows":"3"},domProps:{"value":(_vm.helpContent.value.contact.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.helpContent.value.contact, "title", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12"},[_c('label',{attrs:{"for":"help_email"}},[_vm._v("Help Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.helpContent.value.contact.email),expression:"helpContent.value.contact.email"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.helpContent.value.contact.email.$error
                },attrs:{"id":"help_email"},domProps:{"value":(_vm.helpContent.value.contact.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.helpContent.value.contact, "email", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Contact Info")]),_c('wysiwyg-editor',{model:{value:(_vm.helpContent.value.contact.info),callback:function ($$v) {_vm.$set(_vm.helpContent.value.contact, "info", $$v)},expression:"helpContent.value.contact.info"}})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Contact Hours")]),_c('wysiwyg-editor',{staticClass:"is-invalid",model:{value:(_vm.helpContent.value.contact.hours),callback:function ($$v) {_vm.$set(_vm.helpContent.value.contact, "hours", $$v)},expression:"helpContent.value.contact.hours"}})],1),_c('h5',{staticClass:"col-12 text-uppercase bg-light p-2"},[_vm._v("\n            \"Thanks for contact\" modal\n        ")]),_c('media-chooser',{staticClass:"col-12",model:{value:(_vm.helpContent.value.contact.modalImage),callback:function ($$v) {_vm.$set(_vm.helpContent.value.contact, "modalImage", $$v)},expression:"helpContent.value.contact.modalImage"}}),_c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{attrs:{"for":"modalTitle"}},[_vm._v("Title")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.helpContent.value.contact.modalTitle),expression:"helpContent.value.contact.modalTitle"}],staticClass:"form-control",class:{
                    'is-invalid':
                        _vm.$v.helpContent.value.contact.modalTitle.$error
                },attrs:{"id":"modalTitle","rows":"3"},domProps:{"value":(_vm.helpContent.value.contact.modalTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.helpContent.value.contact, "modalTitle", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{attrs:{"for":"modalSubtitle"}},[_vm._v("Subtitle")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.helpContent.value.contact.modalSubtitle),expression:"helpContent.value.contact.modalSubtitle"}],staticClass:"form-control",class:{
                    'is-invalid':
                        _vm.$v.helpContent.value.contact.modalSubtitle.$error
                },attrs:{"id":"modalSubtitle","rows":"3"},domProps:{"value":(_vm.helpContent.value.contact.modalSubtitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.helpContent.value.contact, "modalSubtitle", $event.target.value)}}})])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }