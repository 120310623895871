<template>
    <div ref="gradientpicker" class="input-group color-picker">
        <input
            v-model="gradient"
            type="text"
            class="form-control"
        />
        <span class="input-group-addon color-picker-container">
            <span
                class="current-color"
                :style="'height: 100%; background-image: ' + gradient"
            />
        </span>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            gradient: ''
        };
    },

    watch: {
        gradient() {
            this.$emit('input', this.gradient);
        }
    },

    mounted() {
        this.gradient = this.value;
    }
};
</script>