<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="d-flex flex-wrap">
            <div class="form-group col-12">
                <label for="banner_button">Video</label>
                <input
                    v-model="content.value.video"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.content.value.video.$error }"
                />
            </div>
        </div>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ContentMixin from '../content.mixin.js';

export default {
    validations: {
        content: {
            value: {
                video: {
                    required
                }
            }
        }
    },

    mixins: [ContentMixin],

    created() {
        this.contentKey = 'tutorial-page';
        this.contentName = 'Tutorial Page';
    }
};
</script>
