import { mapActions } from 'vuex';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    validations: {},

    components: { WereChangesAlert },

    data() {
        return {
            contentName: '',
            contentKey: '',
            initValueStringify: '',
            content: {}
        };
    },

    computed: {
        wereChanges() {
            return (
                JSON.stringify(this.content.value) !== this.initValueStringify
            );
        }
    },

    async mounted() {
        try {
            const response = await this.getContent({
                key: this.contentKey
            });

            this.content = { ...response };

            this.afterDataFetch();

            this.initValueStringify = JSON.stringify(this.content.value);
        } catch (err) {
            console.error(err);

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            getContent: 'contents/show',
            updateContent: 'contents/update'
        }),

        async save() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.beforeSave();

            try {
                await this.updateContent({
                    key: this.content.key,
                    contentData: this.content
                });

                this.afterSave();

                this.initValueStringify = JSON.stringify(this.content.value);

                this.$toaster(`${this.contentName} has been updated!`);
            } catch (err) {
                console.error(err);

                this.afterSave();

                let message = null;

                if (err.response.data.errors) {
                    message = err.response.data.errors.join('. ');
                }

                this.$toasterError(message);
            }
        },

        beforeSave() {},
        afterSave() {},
        afterDataFetch() {}
    }
};
