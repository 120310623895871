<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <div class="row">
            <div class="form-group col-12 mt-1">
                <b-form-checkbox v-model="content.value.enabled" switch>
                    Enabled
                </b-form-checkbox>
            </div>
            <h5 class="col-12 text-uppercase bg-light p-2">
                Scans Scale
            </h5>
            <div class="form-group col-12">
                <label>
                    Distance between 32cm and 35cm
                </label>
                <input
                    v-model.number="content.value.farScan"
                    type="number"
                    class="form-control"
                    step="0.01"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Distance under 32cm and over 35cm
                </label>
                <input
                    v-model.number="content.value.closeScan"
                    type="number"
                    class="form-control"
                    step="0.01"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ContentMixin from '../content.mixin.js';

export default {
    mixins: [ContentMixin],

    created() {
        this.contentKey = 'mobile-3d-scans-scale';
        this.contentName = 'Mobile 3D Scans Scale';
    }
};
</script>
