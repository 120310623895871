<template>
    <div v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div v-for="({ value: footSide }, index) in tabs" :key="index">
            <div v-show="activeTab === footSide" class="row">
                <div class="form-group col-12">
                    <label for="contentTitle">Title</label>
                    <textarea
                        id="contentTitle"
                        v-model="content.value[footSide].title"
                        class="form-control"
                        rows="2"
                    />
                </div>
                <div class="form-group col-12">
                    <label for="contentBoldTitlePart">Bold Title Part</label>
                    <textarea
                        id="contentBoldTitlePart"
                        v-model="content.value[footSide].boldTitlePart"
                        class="form-control"
                        rows="2"
                    />
                </div>
                <div class="form-group col-12">
                    <label for="contentSubtitle">Subtitle</label>
                    <textarea
                        id="contentSubtitle"
                        v-model="content.value[footSide].subtitle"
                        class="form-control"
                        rows="2"
                    />
                </div>
                <div class="form-group col-6">
                    <label>
                        Retake Button Text
                    </label>
                    <input
                        v-model="content.value[footSide].retakeButtonText"
                        type="text"
                        class="form-control"
                    />
                </div>
                <div class="form-group col-6">
                    <label>
                        Approve Button Text
                    </label>
                    <input
                        v-model="content.value[footSide].approveButtonText"
                        type="text"
                        class="form-control"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContentMixin from '../content.mixin.js';
import ContentTabs from '@components/contents/ContentTabs';

export default {
    components: {
        ContentTabs
    },

    mixins: [ContentMixin],

    data() {
        return {
            tabs: [
                {
                    value: 'leftFoot',
                    text: 'Left Foot'
                },
                {
                    value: 'rightFoot',
                    text: 'Right Foot'
                }
            ],
            activeTab: 'leftFoot'
        };
    },

    created() {
        this.contentKey = 'mobile-3d-preview';
        this.contentName = 'Mobile 3D Preview';
    }
};
</script>
