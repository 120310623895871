<template>
    <div class="form-group col-12">
        <button
            v-b-modal.modalElementCreateEdit
            class="btn btn-danger waves-effect waves-light"
            data-animation="fadein"
            data-plugin="custommodal"
            data-overlaycolor="#38414a"
        >
            <i class="mdi mdi-plus-circle mr-1" />
            Add Element
        </button>

        <ol class="list-group my-2">
            <a
                href="#"
                class="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-secondary text-white"
            >
                <div class="d-flex col-12 text-center font-weight-bold">
                    <div class="col-1">#</div>
                    <div class="col-3">Customer Name</div>
                    <div class="col-2">Type</div>
                    <div class="col-3">Title</div>
                    <div class="col-2">Status</div>
                    <div class="col-1">Remove</div>
                </div>
            </a>
            <draggable
                v-bind="dragOptions"
                :list="draggableElements"
                ghost-class="ghost"
                @end="onElementMoved"
                @start="drag = true"
            >
                <transition-group
                    type="transition"
                    :name="!drag ? 'flip-list' : null"
                >
                    <a
                        v-for="(element, index) in draggableElements"
                        :key="index + 0"
                        href="#"
                        class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    >
                        <div class="d-flex col-12 text-center">
                            <div class="col-1">{{ element.order }}</div>
                            <div class="col-3">{{ element.customerName }}</div>
                            <div class="col-2">{{ element.type }}</div>
                            <div class="col-3">{{ element.title }}</div>
                            <div class="col-2">
                                <span
                                    v-if="element.verified"
                                    class="badge bg-soft-success text-success"
                                >
                                    Verified
                                </span>
                                <span
                                    v-else
                                    class="badge bg-soft-danger text-danger"
                                >
                                    Not verified
                                </span>
                            </div>
                            <div class="col-1">
                                <a
                                    href="#"
                                    @click.prevent="openEdit(element, index)"
                                >
                                    <i class="fas fa-edit mr-2" />
                                </a>
                                <a
                                    href="#"
                                    @click.prevent="
                                        onDeleteElement(element, index)
                                    "
                                >
                                    <i class="far fa-trash-alt" />
                                </a>
                            </div>
                        </div>
                    </a>
                </transition-group>
            </draggable>
        </ol>

        <modal-element-create-edit
            :modal-title="elementToUpdate ? 'Edit Element' : 'Add New Element'"
            :button-text="elementToUpdate ? 'Update' : 'Save'"
            :updating-element="!!elementToUpdate"
            :element="elementToUpdate"
            :updating-index="updatingIndex"
            :is-homepage="isHomepage"
            @hidden="hiddenModalHandler"
            @createElement="createElementHandler"
            @updateElement="updateElementHandler"
        />
    </div>
</template>

<script>
import Draggable from 'vuedraggable';
import ModalElementCreateEdit from './ModalElementCreateEdit';

export default {
    components: {
        Draggable,
        ModalElementCreateEdit
    },

    props: {
        elements: {
            type: Array,
            required: true,
            default: () => []
        },
        isHomepage: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            draggableElements: null,
            drag: false,
            elementToUpdate: null,
            updatingIndex: null
        };
    },

    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            };
        }
    },

    mounted() {
        this.draggableElements = [...this.elements];
        this.draggableElements.sort((a, b) => {
            return a.order - b.order;
        });
    },

    methods: {
        onElementMoved() {
            this.drag = false;

            this.draggableElements = this.draggableElements.map((el, index) => {
                el.order = index + 1;

                return el;
            });

            this.$emit('input', this.draggableElements);
            this.$emit('save');
        },

        openEdit(item, index) {
            this.elementToUpdate = item;
            this.updatingIndex = index;
            this.$bvModal.show('modalElementCreateEdit');
        },

        hiddenModalHandler() {
            this.elementToUpdate = null;
            this.updatingIndex = null;
        },

        createElementHandler(element) {
            if (this.draggableElements.length) {
                const maxOrderEl = this.draggableElements.reduce(function(
                    prev,
                    current
                ) {
                    return prev.order > current.order ? prev : current;
                });

                element.order = maxOrderEl.order + 1;
            } else {
                element.order = 1;
            }

            this.draggableElements = [...this.draggableElements, element];

            this.$emit('input', this.draggableElements);
            this.$emit('save');
            this.hiddenModalHandler();
        },

        updateElementHandler(element, index) {
            this.draggableElements[index] = element;

            this.$emit('input', this.draggableElements);
            this.$emit('save');
            this.hiddenModalHandler();
        },

        async onDeleteElement(element, index) {
            const confirmed = await this.$confirmationModal(
                `Are you sure you want to delete this element - ${element.title}?`
            );

            if (!confirmed) {
                return;
            }

            this.draggableElements.splice(index, 1);

            this.draggableElements = this.draggableElements.map((el, i) => {
                el.order = i + 1;

                return el;
            });

            this.$emit('input', this.draggableElements);
            this.$emit('save');
        }
    }
};
</script>

<style scoped>
.ghost {
    cursor: pointer;
    opacity: 0.5;
    background: #c8ebfb;
}

.button {
    margin-top: 35px;
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.list-group {
    min-height: 20px;
}

.list-group-item {
    cursor: move;
}

.list-group-item i {
    cursor: pointer;
}
</style>
