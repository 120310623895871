<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>
        <div class="d-flex flex-wrap">
            <div
                v-for="(page, index) in pages"
                :key="index"
                class="form-group col-12 col-sm-6 col-md-4 col-lg-3"
            >
                <label :for="page.property">{{ page.text }}</label>
                <input
                    :id="page.property"
                    v-model="content.value[page.property]"
                    type="text"
                    class="form-control"
                />
            </div>
        </div>
    </form>
</template>

<script>
import ContentMixin from './content.mixin.js';

export default {
    mixins: [ContentMixin],

    data() {
        return {
            pages: [
                {
                    property: 'about',
                    text: 'About'
                },
                {
                    property: 'cart',
                    text: 'Cart'
                },
                {
                    property: 'checkout',
                    text: 'Checkout'
                },
                {
                    property: 'gift-card',
                    text: 'Gift Card'
                },
                {
                    property: 'help',
                    text: 'Help'
                },
                {
                    property: 'home',
                    text: 'Home Page'
                },
                {
                    property: 'howItWorks',
                    text: 'How it works'
                },
                {
                    property: 'impressionKit',
                    text: 'Impression Kit'
                },
                {
                    property: 'orderTracking',
                    text: 'Order Tracking'
                },
                {
                    property: 'privacyPolicy',
                    text: 'Privacy Policy'
                },
                {
                    property: 'reorder',
                    text: 'Reorder'
                },
                {
                    property: 'reviews',
                    text: 'Reviews'
                },
                {
                    property: 'termsConditions',
                    text: 'Terms and Conditions'
                },
                {
                    property: 'thankYou',
                    text: 'Thank You'
                },
                {
                    property: 'error',
                    text: 'Error Page'
                },
                {
                    property: 'hover',
                    text: 'Hover text'
                }
            ]
        };
    },

    created() {
        this.contentKey = 'pages-titles';
        this.contentName = 'Pages Titles';
    }
};
</script>
