<template>
    <b-modal
        :id="modalId"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        @hide="hideModal"
    >
        <template slot="modal-title">
            Choose media
        </template>
        <div class="modal-text text-left">
            <ul
                :key="selectedMode.value"
                class="nav nav-pills navtab-bg nav-justified my-3"
            >
                <li
                    v-for="(mode, index) in modes"
                    :key="index"
                    class="nav-item"
                >
                    <a
                        href="#"
                        data-toggle="tab"
                        aria-expanded="false"
                        class="nav-link"
                        :class="{ active: selectedMode.value === mode.value }"
                        @click.prevent="selectedMode = mode"
                    >
                        {{ mode.text }}
                    </a>
                </li>
            </ul>
            <upload
                v-if="selectedMode.value === 'addMedia'"
                :type="type"
                @uploaded="onMediaUpload"
            />
            <preview
                v-else
                :value="value"
                :type="type"
                :multiple-mode="multipleMode"
                show-details
                @onSelectMedia="selectMedia"
                @forceSelect="submitHandler"
            />
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    v-if="selectedMode.value === 'chooseMedia'"
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="submitHandler"
                >
                    {{ buttonText }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import Upload from '@components/media/Upload';
import Preview from '@components/media/Preview';

export default {
    components: {
        Upload,
        Preview
    },

    props: {
        value: {
            type: [Array, Object, String],
            required: false,
            default: null
        },
        modalId: {
            type: String,
            required: false,
            default: 'mediaChooserModal'
        },
        type: {
            type: String,
            required: false,
            default: ''
        },
        multipleMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            buttonText: 'Choose',
            selectedMedia: [],
            selectedMode: {
                value: 'chooseMedia'
            },
            modes: [
                { text: 'Add media', value: 'addMedia' },
                { text: 'Choose media', value: 'chooseMedia' }
            ]
        };
    },

    methods: {
        async submitHandler() {
            if (!this.selectedMedia.length) {
                return;
            }

            this.$emit('update', this.selectedMedia);
            this.hideModal();
        },

        selectMedia(media) {
            this.selectedMedia = media;
        },

        hideModal() {
            this.selectedMedia = [];

            this.$bvModal.hide(this.modalId);
            this.$emit('hide');
        },

        onMediaUpload(uploaded) {
            this.$toaster('Files have been uploaded.');

            this.selectedMedia = this.multipleMode
                ? [...this.selectedMedia, ...uploaded]
                : [uploaded[0]];

            this.$emit('update', this.selectedMedia);

            this.selectedMode = { value: 'chooseMedia' };
        }
    }
};
</script>
