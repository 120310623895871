<template>
    <form v-if="content.value">
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <div class="form-group">
            <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="save"
            >
                <i class="fas fa-save mr-1" />
                Save updates
            </button>
        </div>

        <content-tabs v-model="activeTab" :tabs="tabs" />

        <div v-show="activeTab === 'general'" class="row">
            <h5 class="col-12 text-uppercase bg-light p-2">
                General
            </h5>
            <div class="form-group col-12">
                <label for="aboutTitle">Title</label>
                <textarea
                    id="aboutTitle"
                    v-model="content.value.title"
                    class="form-control"
                    rows="3"
                />
            </div>
            <media-chooser
                v-model="content.value.topImage"
                label="Image"
                add-buff
                class="col-12"
            />
            <div class="form-group col-12">
                <label for="bottomText">Bottom text</label>
                <textarea
                    id="bottomText"
                    v-model="content.value.bottomTitle"
                    class="form-control"
                    rows="3"
                />
            </div>
            <div class="form-group col-6">
                <label for="buttonText">Button Text</label>
                <input
                    id="buttonText"
                    v-model="content.value.button.text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-6">
                <label for="buttonLink">Button Link</label>
                <input
                    id="buttonLink"
                    v-model="content.value.button.link"
                    class="form-control"
                />
            </div>
        </div>

        <changes
            v-show="activeTab === 'changes'"
            v-model="content.value.changes"
        />
        <sections
            v-show="activeTab === 'sections'"
            v-model="content.value.sections"
        />
    </form>
</template>

<script>
import ContentMixin from './content.mixin.js';
import ContentTabs from '@components/contents/ContentTabs';
import MediaChooser from '@components/media/MediaChooser';
import Sections from './about/Sections';
import Changes from './about/Changes';

export default {
    components: {
        ContentTabs,
        MediaChooser,
        Sections,
        Changes
    },
    mixins: [ContentMixin],

    data() {
        return {
            tabs: [
                {
                    value: 'general',
                    text: 'General'
                },
                {
                    value: 'changes',
                    text: 'Changes'
                },
                {
                    value: 'sections',
                    text: 'Sections'
                }
            ],
            activeTab: 'general'
        };
    },

    created() {
        this.contentKey = 'about';
        this.contentName = 'About';
    }
};
</script>
