<template>
    <div :key="changes.items.length" class="row">
        <h5 class="col-12 text-uppercase bg-light p-2">
            Changes
        </h5>
        <div class="form-group col-12">
            <label for="changesTitle">Title</label>
            <input
                id="changesTitle"
                v-model="changes.title"
                class="form-control"
                type="text"
                @input="onChange"
            />
        </div>
        <div v-for="(item, index) in changes.items" :key="index" class="col-12">
            <h5
                class="col-12 d-flex align-items-center justify-content-between text-uppercase bg-light p-2"
            >
                <div> Change {{ index + 1 }} </div>
                <button
                    class="btn btn-danger waves-effect waves-light"
                    type="button"
                    @click="deleteItem(index)"
                >
                    <i class="far fa-trash-alt" />
                </button>
            </h5>
            <media-chooser
                v-model="item.image"
                add-buff
                class="col-12"
                @input="onChange"
            />
            <div class="form-group col-12">
                <label :for="`changeText${index}`">Text</label>
                <textarea
                    :id="`changeText${index}`"
                    v-model="item.text"
                    class="form-control"
                    rows="3"
                    @input="onChange"
                />
            </div>
        </div>

        <div class="col-12 mt-3">
            <button
                class="btn btn-info waves-effect waves-light"
                type="button"
                @click="addItem"
            >
                <i class="fas fa-plus" />
            </button>
        </div>
    </div>
</template>

<script>
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        MediaChooser
    },

    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },

    data() {
        return {
            changes: []
        };
    },

    created() {
        this.changes = { ...this.value };
    },

    methods: {
        onChange() {
            this.$emit('input', this.changes);
        },

        addItem() {
            if (this.changes.items.length) {
                const last = [...this.changes.items].pop();

                if (!last.text || !last.image.id) {
                    this.$toasterWarning(
                        'Please fill the previous section before adding a new one!'
                    );

                    return;
                }
            }

            this.changes.items = [
                ...this.changes.items,
                {
                    text: '',
                    image: {}
                }
            ];
        },

        deleteItem(index) {
            this.changes.items.splice(index, 1);
        }
    }
};
</script>
